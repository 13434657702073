import styled from 'styled-components'

export const StyledRatingFeedback = styled.div`
  width: 100%;
  padding: 12px;
  box-shadow: 0px 68px 19px 0px rgba(0, 0, 0, 0),
    0px 43px 17px 0px rgba(0, 0, 0, 0.01), 0px 24px 15px 0px rgba(0, 0, 0, 0.02),
    0px 11px 11px 0px rgba(0, 0, 0, 0.03), 0px 3px 6px 0px rgba(0, 0, 0, 0.04);
  @media (min-width: 768px) {
    padding: 20px;
  }
  border-radius: var(--dls-size-16);
  background-color: var(--brand-primary-white-color);
  text-align: center;
  position: relative;
  .label {
    color: var(--brand-light-secondary-text-color);
    display: flex;
    flex-direction: column;
  }

  .divider {
    height: var(--dls-size-8);
    @media (min-width: 768px) {
      height: var(--dls-size-16);
    }
  }
  .crossIcon {
    position: absolute;
    top: var(--dls-size-8);
    right: var(--dls-size-8);
  }
`
