import styled from 'styled-components'

export const ImageCarouselWrapper = styled.div<{aspectRatio: string}>`
  position: relative;
  width: 100%;
  aspect-ratio: ${({aspectRatio = '1'}) => {
    return aspectRatio
  }};
  margin-top: var(--dls-size-88);

  .image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    scroll-snap-align: start;

    .inner-image {
      object-fit: contain;
      width: 100%;
      height: auto;
      aspect-ratio: ${({aspectRatio = '1'}) => {
        return aspectRatio
      }};
    }
  }

  .image-container {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    white-space: nowrap;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .carousel-mobile-nav {
    outline: none;
    border: none;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    padding: 50px 10px;
    z-index: 4;
    background-color: transparent;
    box-shadow: none;
    &:hover,
    &:active {
      box-shadow: none !important;
    }

    span {
      height: 30px;
      width: 30px;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--brand-primary-white-color);
      border-radius: 50%;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.08);
    }
  }
  .left {
    left: 0;

    span {
      transform: rotate(-90deg);
    }
  }

  .right {
    right: 0;
    span {
      transform: rotate(90deg);
    }
  }
`
