import React from 'react'
import {MarqueeContainer} from './Marquee.styles'
import {Typography, TypographyVariants} from '@web-components'
import {BaseWidget} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'

const Marquee = (props: BaseWidget) => {
  const {widgetData, layout, type, id} = props

  const {items} = widgetData || {}

  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <MarqueeContainer>
        <div className="marquee">
          {items.map((item: string, index: number) => (
            <React.Fragment key={index}>
              <span className="marquee-item">
                <Typography variant={TypographyVariants.X_SMALL}>
                  {item}
                </Typography>
              </span>
              <span className="separator">•</span>
            </React.Fragment>
          ))}

          {items.map((item: string, index: number) => (
            <React.Fragment key={`duplicate-${index}`}>
              <span className="marquee-item">
                <Typography variant={TypographyVariants.X_SMALL}>
                  {item}
                </Typography>
              </span>
              <span className="separator">•</span>
            </React.Fragment>
          ))}
        </div>
      </MarqueeContainer>
    </BaseWidgetComponent>
  )
}

export default Marquee
