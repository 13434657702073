import React from 'react'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from 'src/mono/web-core/widgets/BaseWidget'
import {isEmpty} from 'lodash'
import UserComplianceProgress from './UserComplianceProgress'

function StepsWidget({
  widgetData,
  id,
  header,
  layout,
  type,
}: BaseWidgetProps<{lazy: boolean}>) {
  if (isEmpty(widgetData)) return null
  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <UserComplianceProgress state={widgetData} />
    </BaseWidgetComponent>
  )
}

export default StepsWidget
