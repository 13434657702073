import {useRouter} from 'next/router'
import React, {useCallback} from 'react'

function useNavigation() {
  const router = useRouter()

  const navigateTo = useCallback(
    (destination: string) => {
      let route = destination
      const regex = /^http/
      const isAbsolute = regex.test(destination)
      if (destination[0] !== '/' && !isAbsolute) {
        route = '/' + destination
      }

      try {
        if (isAbsolute) {
          window.location.href = route
        } else {
          router.push(route)
        }
      } catch (e) {
        window.location.href =
          destination !== '/' ? '/' + destination : destination
      }
    },
    [router]
  )

  return [{}, {navigateTo}] as const
}

export default useNavigation
