import styled from 'styled-components'

export const MediaWithPillsSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--dls-size-56);
  margin-top: var(--dls-size-64);
  margin-bottom: var(--dls-size-48);

  .bottom-cta {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
`
