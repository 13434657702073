import styled from 'styled-components'

export const ReelSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--dls-size-24);
  padding-top: var(--dls-size-88);

  .bottom-cta {
    margin: var(--dls-size-16) auto;
  }
`
