import * as React from 'react'

const PlusIcon = ({
  fill = 'var(--dls-brand-primary-color-500)',
  size = '16',
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4H7L7 7H4V9H7L7 12H9V9H12V7H9V4Z"
      fill={fill}
    />
  </svg>
)

export default PlusIcon
