import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import VideoRender from './VideoPlayer'
import {VideoPlayerProps} from './OptimizedVideo.interface'

const OptimizedVideo = ({state = {}}: VideoPlayerProps) => {
  const {autoplay = false} = state
  const videoRef = useRef<HTMLVideoElement>(null)
  const observerRef = useRef<IntersectionObserver | null>(null)

  const playPauseVideo = useCallback(() => {
    if (!videoRef.current) return

    const videoElement = videoRef.current

    try {
      // Cleanup previous observer
      if (observerRef.current) {
        observerRef.current.disconnect()
      }

      const playPromise = videoElement.play()
      if (playPromise !== undefined) {
        playPromise.then(() => {
          observerRef.current = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (!entry.isIntersecting && !videoElement.paused) {
                  videoElement.pause()
                } else if (
                  entry.isIntersecting &&
                  videoElement.paused &&
                  !videoElement.ended &&
                  autoplay
                ) {
                  videoElement.play()
                }
              })
            },
            {threshold: 0.2}
          )

          observerRef.current.observe(videoElement)
        })
      }
    } catch (error) {}
  }, [autoplay])

  useEffect(() => {
    if (document.readyState === 'complete' && autoplay) {
      playPauseVideo()
    } else if (autoplay) {
      window.addEventListener('load', playPauseVideo)
      return () => {
        window.removeEventListener('load', playPauseVideo)
        if (observerRef.current) {
          observerRef.current.disconnect()
        }
      }
    }
  }, [autoplay, playPauseVideo])

  const videoProps = useMemo(
    () => ({
      state: {
        ...state,
        videoRef,
      },
    }),
    [state]
  )

  return <VideoRender {...videoProps} />
}

export default OptimizedVideo
