import noop from 'lodash/noop'
import React, {useCallback, useEffect} from 'react'

import RatingsAndReviewTop from '../../../../Components/Screen/ProductDescriptionPage/RatingsAndReviews/Top'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import Modal from '../../../common/Modal/modal'
import Typography from '../../../common/Typography/Typography'
import Reviews from '../Review'
import StampedImageWidget from '../StampedImageWidget'
import {useProductSectionScrollContext} from '../TabNavigation/Context'
import WriteReview from '../WriteReview'
import {StyledWrapper} from './RatingsAndReviews.styles'
import VideoReviews from './VideoReviews'
import CustomerSay from './CustomerSay'
import {CONSTANTS} from 'src/constants/defaultConstants'

const RatingsAndReview = (props) => {
  const {
    stampedWidgetConfig = {},
    reviewsProvider = '',
    showActions = true,
  } = props
  const {handleScrollToSection = noop} = useProductSectionScrollContext()

  const {
    ratingOverview = {},
    themeFor = 'little_joys',
    topReviews = [],
    videos = [],
    headingLevel = 2,
    title,
    newReview,
    productId,
    showVotes = false,
    rating,
    sectionName = 'reviews',
    viewAllReviewsCTA = CONSTANTS.VIEW_ALL_REVIEWS_TEXT,
    writeReviewText = CONSTANTS.WRITE_A_REVIEW_TEXT,
    allReviewsUrl,
    customerSay = {},
  } = props.data || {}

  const {
    isLoading = false,
    totalReviews,
    avgRating,
    moreReviewsToLoad = false,
    moreImagesToLoad = false,
    loadedReviews = [],
    loadedReviewsMap = {},
    loadedPhotosPreview = [],
    searchTerm = '',
    totalImages,
    showWriteReview = false,
    votesCount = {},
    showWriteReviewCTA = true,
    showStampedImages = true,
  } = props.state || {}

  const {
    reviewActions,
    disableBodyScroll = noop,
    enableBodyScroll = noop,
    triggerWriteReviewClickEvent = noop,
    triggerReviewSubmittedEvent = noop,
    onLoadMoreReviewsClick = noop,
  } = props.actions || {}

  const {
    onUpVote = noop,
    onDownVote = noop,
    handleLoadMoreReviews = noop,
    handleTopQueryToggle = noop,
    handleWriteReview = noop,
    handleWriteReviewToggle = noop,
    handleLoadReviewsWithImages = noop,
    handleLoadMoreReviewsWithImages = noop,
  } = reviewActions || {}

  const {isEnabled: isCustomerSay = false} = customerSay

  const handleLoadMoreReviewsClicked = useCallback(
    (e) => {
      e.preventDefault()
      onLoadMoreReviewsClick(allReviewsUrl)
    },
    [onLoadMoreReviewsClick, allReviewsUrl]
  )

  const handleReviewSubmitted = useCallback(
    (reviewData) => {
      handleWriteReview(reviewData)
      triggerReviewSubmittedEvent({})
    },
    [handleWriteReview, triggerReviewSubmittedEvent]
  )

  useEffect(() => {
    const previousUrl = localStorage.getItem('previousUrl')
    if (previousUrl?.includes('all-reviews')) {
      setTimeout(() => handleScrollToSection('reviews'), 500)
      localStorage.setItem('previousUrl', null)
    }
  }, [])

  return (
    <StyledWrapper
      data-scrollSection="reviews"
      data-reviewssection="rnr"
      data-section={sectionName}
    >
      <div className="container">
        <Typography
          text={title}
          variant="heading-sm-bold"
          customClassName="rcl-section-title"
          headingLevel={headingLevel}
        />
        <VideoReviews videos={videos} />
        <RatingsAndReviewTop
          data={{
            ...ratingOverview,
            rating,
            reviewsProvider,
            totalNumberOfReviews: totalReviews,
            avgRating: avgRating,
            loadedPhotosPreview,
            loadedReviewsMap,
          }}
          state={{
            totalImages,
            moreImagesToLoad,
          }}
          actions={{
            handleLoadReviewsWithImages,
            handleLoadMoreReviewsWithImages,
            disableBodyScroll,
            enableBodyScroll,
          }}
          themeFor={themeFor}
        />
        {isCustomerSay && <CustomerSay {...customerSay} />}
        {reviewsProvider && showStampedImages && (
          <StampedImageWidget
            state={{productId, stampedWidgetConfig, reviewsProvider}}
          />
        )}
        <Reviews
          data={{
            reviews: topReviews,
            searchTerm,
            moreReviewsToLoad,
            loadedReviews,
            votesCount,
            showVotes,
          }}
          actions={{
            handleUpvote: onUpVote,
            handleDownvote: onDownVote,
            handleLoadMoreReviews: handleLoadMoreReviews,
            handleWriteAReview: handleTopQueryToggle,
            disableBodyScroll,
            enableBodyScroll,
          }}
        />
        {showActions && (
          <div className="button-wrap">
            <div className="ratings-and-reviews__button-group">
              <a
                href={allReviewsUrl}
                className="ratings-and-reviews__button"
                disabled={isLoading}
                onClick={handleLoadMoreReviewsClicked}
                data-loadmore={true}
              >
                <Typography text={viewAllReviewsCTA} variant="cta-label-sm" />
              </a>
              {showWriteReviewCTA && (
                <button
                  className="ratings-and-reviews__button category-Primary-btn"
                  onClick={() => {
                    handleWriteReviewToggle()
                    triggerWriteReviewClickEvent({})
                  }}
                  data-primary={true}
                >
                  <Typography text={writeReviewText} variant="cta-label-sm" />
                </button>
              )}
            </div>
          </div>
        )}
        <Modal
          onClose={handleWriteReviewToggle}
          showModal={showWriteReview}
          additionalContentClass="write-review-modal"
        >
          <WriteReview
            data={{...newReview, isLoading}}
            actions={{
              onSubmitReview: handleReviewSubmitted,
              onCancelReview: handleWriteReviewToggle,
            }}
          />
        </Modal>
      </div>
    </StyledWrapper>
  )
}

export default withCustomErrorBoundary(RatingsAndReview, 'RatingsAndReview')
