//import {ExternalNudgeWidget} from 'src/components/External/ExternalNudge'
import {StepsWidget} from 'src/containers/UserComplianceProgress'

export const CLIENT_SIDE_WIDGETS_TYPES = {
  //EXTERNAL_NUDGE: 'EXTERNAL_NUDGE',
  STEPS: 'STEPS',
}

type WidgetType =
  typeof CLIENT_SIDE_WIDGETS_TYPES[keyof typeof CLIENT_SIDE_WIDGETS_TYPES]
export const CLIENT_SIDE_WIDGETS: Record<
  WidgetType,
  React.ComponentType<any>
> = {
  //[CLIENT_SIDE_WIDGETS_TYPES.EXTERNAL_NUDGE]: ExternalNudgeWidget, // as giving error we are currently not using it
  [CLIENT_SIDE_WIDGETS_TYPES.STEPS]: StepsWidget,
}
