import React from 'react'
import {MediaWithPillsWrapper} from './MediaWithPills.styles'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import {BaseWidget} from '@mosaic-wellness/fe-types'

const MediaWithPills = (props: any) => {
  const {widgetData} = props

  const {mediaData = {}, pills = {}, title = ''} = widgetData || {}
  const {media = {}} = mediaData || {}

  return (
    <MediaWithPillsWrapper>
      <div className="media-pills-image-wrapper">
        <OptimizedImage
          source={media.source}
          customClassName="media-pills-image"
        />
      </div>
      <div className="media-pll-title ">
        <Typography variant={TypographyVariants.HEADING_MEDIUM_REGULAR}>
          {title}
        </Typography>
      </div>
      <div className="media-pills-wrapper">
        {pills.items.map((item: any, index: number) => (
          <div className="media-pill" key={index}>
            <Typography variant={TypographyVariants.X_SMALL}>
              {item.label}
            </Typography>
          </div>
        ))}
      </div>
    </MediaWithPillsWrapper>
  )
}

export default MediaWithPills
