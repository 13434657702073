import {useCallback} from 'react'
import {analytics} from 'src/analytics'

export const useExplicitATCTracking = () => {
  const trackAddToCart = useCallback(
    (product, source = '', otherOptions = {}) => {
      analytics.trigger('addToCartClick', {
        ...product,
        source,
        ...otherOptions,
      })
    },
    []
  )

  return {
    trackAddToCart,
  }
}
