import {CLIENT_ENV} from 'src/constants/clientEnv'

type ICountry = 'IN' | 'AE' | 'SA'
const country: ICountry = CLIENT_ENV.NEXT_PUBLIC_COUNTRY || 'IN'
const countryCode: Record<ICountry, String> = {
  IN: '+91',
  AE: '+971',
  SA: '+966',
}

export const COUNTRY_CODE = countryCode[country]
