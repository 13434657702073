import {
  useLoadingStore,
  useUserStore,
} from '@mosaic-wellness/redux-action-library'
import React, {useEffect} from 'react'
import {docCookies} from 'src/utils/cookie'

function SessionHandling() {
  const {
    user: {isLoggedIn, repeatUser},
  } = useUserStore()
  const {isUserHydration} = useLoadingStore()

  useEffect(() => {}, [])
  useEffect(() => {
    if (!isUserHydration) {
      docCookies.setItem({
        cookieName: 'isLoggedIn',
        cookieValue: isLoggedIn ? 1 : 0,
        expiry: 2595000,
        skipDomain: true,
        cookiePath: '/',
      })
      docCookies.setItem({
        cookieName: 'isRept',
        cookieValue: repeatUser ? 1 : 0,
        expiry: 2595000,
        skipDomain: true,
        cookiePath: '/',
      })
    }
  }, [isLoggedIn, isUserHydration, repeatUser])

  return <></>
}

export default SessionHandling
