import {Controls, IPlayerProps, Player} from '@lottiefiles/react-lottie-player'
import React from 'react'

interface ILottiePlayerProps {
  src: IPlayerProps['src']
  controlsVisible?: boolean
  controlsButtons?: boolean
  autoplay?: boolean
  loop?: boolean
  style?: React.CSSProperties
  renderer?: IPlayerProps['renderer']
  onEvent?: IPlayerProps['onEvent']
  onStateChange?: IPlayerProps['onStateChange']
  speed?: IPlayerProps['speed']
  className?: string
}

export default function LottiePlayer(props: ILottiePlayerProps) {
  const {autoplay, loop, ...rest} = props
  return (
    <Player
      {...rest}
      autoplay={autoplay ?? true}
      loop={loop ?? true}
      style={props.style || {height: '200px', width: '200px'}}
      data-testid="lottie-player"
    >
      <Controls
        visible={props.controlsVisible || false}
        buttons={props.controlsButtons ? ['pause', 'play', 'stop'] : undefined}
      />
    </Player>
  )
}
