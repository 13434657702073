import React, {useCallback, useMemo} from 'react'
import {ProductCardV2Container} from './CategoryProductCard.styles'
import {
  PlusIcon,
  OptimizedImage,
  MinusIcon,
  ReviewRatingStar,
  Spinner,
  Typography,
  TypographyVariants,
} from '@web-components'
// import {useCartLoadingContext} from 'src/components/shared/src/ComponentsV2/Context/CartUpdatingContext'
import noop from 'lodash/noop'
import {CategoryProductCardProps} from './CategoryProductCard.interface'
import {useCartStore} from '@mosaic-wellness/redux-action-library'
import {useUpdateItemToCart} from 'src/hooks/updateCart/useUpdateItemToCart'
import isEmpty from 'lodash/isEmpty'
// import {useUpdateItemToCart} from 'src/hooks/updateCart/useUpdateItemToCart'

const CategoryProductCard: React.FC<CategoryProductCardProps> = (props) => {
  const {
    product,
    productImageAspectRatio = 1,
    handleCardClick = noop,
    handleCtaClick = noop,
    handleUpdateCartItem = noop,
    enableQuantityIndicator = false,
    showImageBorder = true,
  } = props || {}
  const {
    id,
    alttext = '',
    discountedPrice = '',
    discountPriceLabel = '',
    name = '',
    price = '',
    priceLabel = '',
    rating = '',
    ctaLabel = 'Add to Cart',
    For = '',
    sku = '',
    image: imageToRender = '',
    slugUrl = '',
    outOfStock = false,
    overlayTag,
  } = product || {}

  const {
    label = '',
    labelColor = '',
    bgColor = '',
    borderColor = '',
  } = overlayTag || {}

  // TODO: remove cart loading context
  // const {isCartUpdating, itemBeingAddedToCart} = useCartLoadingContext()
  const {cart} = useCartStore()
  // const {addToCart, removeFromCart, updateItemToCart} = useUpdateItemToCart()
  const {checkoutInfo} = cart || {}
  const {items} = checkoutInfo || {}
  const quantity = useMemo(() => items?.[sku]?.qty || 0, [items, sku])
  const showQuantity = useMemo(
    () => quantity > 0 && enableQuantityIndicator,
    [quantity, enableQuantityIndicator]
  )

  const showLoader = useMemo(() => {
    return null
  }, [sku])

  const showDiscountedPrice = useMemo(() => {
    return discountedPrice && discountedPrice !== 0 && price > discountedPrice
  }, [discountedPrice, price])

  const handleReduceQuantity = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation()
      e.preventDefault()
      handleUpdateCartItem(id, sku, quantity - 1)
    },
    [handleUpdateCartItem, id, quantity, sku]
  )

  const handleIncreaseQuantity = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation()
      e.preventDefault()
      handleUpdateCartItem(id, sku, quantity + 1)
    },
    [handleUpdateCartItem, id, quantity, sku]
  )

  const onCardClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      handleCardClick(product)
    },
    [handleCardClick, product]
  )

  const onCtaClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      e.preventDefault()
      handleCtaClick(product)
    },
    [handleCtaClick, product]
  )

  const RenderOverLayTag = useCallback(() => {
    if (!isEmpty(overlayTag)) {
      return (
        <div className="overlay-container">
          <Typography
            customClassName="overlay-text"
            variant={TypographyVariants.X_SMALL}
          >
            {label}
          </Typography>
        </div>
      )
    } else if (outOfStock) {
      return (
        <div className="out-of-stock-container">
          <Typography variant={TypographyVariants.X_SMALL}>
            Out Of Stock
          </Typography>
        </div>
      )
    } else {
      return null
    }
  }, [overlayTag, label, outOfStock])

  return (
    <ProductCardV2Container
      href={slugUrl}
      onClick={onCardClick}
      outOfStock={outOfStock}
      showImageBorder={showImageBorder}
      productImageAspectRatio={productImageAspectRatio}
      labelColor={labelColor}
      bgColor={bgColor}
      borderColor={borderColor}
    >
      <div className="img-container">
        {Number(rating) > 0 && (
          <div className="rating-container">
            <ReviewRatingStar count={1} value={Number(rating)} size={12} />
            <div className="ratinginfo">
              {Number(rating) > 0 && (
                <Typography variant={TypographyVariants.X_SMALL}>
                  {rating}
                </Typography>
              )}
            </div>
          </div>
        )}
        <OptimizedImage
          source={imageToRender}
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          altText={alttext || name}
          customClassName="product-image"
        />
        <RenderOverLayTag />
      </div>

      <div className="product-info">
        {For && (
          <Typography
            variant={TypographyVariants.X_SMALL}
            customClassName="for-label"
          >
            {For}
          </Typography>
        )}
        <div className="product-name-container">
          <Typography
            variant={TypographyVariants.CTA_LABEL_SMALL}
            customClassName="product-name"
          >
            {name}
          </Typography>
        </div>
        <div className="pricing">
          {showDiscountedPrice ? (
            <div className="price-ctn">
              <div>
                <Typography
                  variant={TypographyVariants.BODY_BASE_BOLD}
                  customClassName="price"
                >
                  {discountPriceLabel}
                </Typography>
              </div>

              <div>
                <Typography
                  variant={TypographyVariants.X_SMALL}
                  customClassName="discounted-price"
                >
                  {priceLabel}
                </Typography>
              </div>
            </div>
          ) : (
            <div className="price-top-container">
              <Typography
                variant={TypographyVariants.BODY_BASE_BOLD}
                customClassName="price price-ctn"
              >
                {priceLabel}
              </Typography>
            </div>
          )}
        </div>
      </div>

      <div className="button-container">
        {showQuantity ? (
          <div className="qty-button-container">
            <button
              onClick={handleReduceQuantity}
              className="qty-btn remove-btn"
              data-isdisabled={showLoader}
              disabled={showLoader}
            >
              <MinusIcon
                height={20}
                width={20}
                fill={'var(--dls-brand-primary-color-500)'}
              />
            </button>
            <div className="qty-label-container">
              {showLoader ? (
                <Spinner
                  color={'var(--dls-brand-primary-color-500)'}
                  size={'20'}
                />
              ) : (
                <Typography
                  variant={TypographyVariants.CTA_LABEL_SMALL}
                  customClassName="qty-label"
                >
                  {quantity}
                </Typography>
              )}
            </div>
            <button
              onClick={handleIncreaseQuantity}
              className="qty-btn add-btn"
              data-isdisabled={showLoader}
              disabled={showLoader}
            >
              <PlusIcon
                height={20}
                width={20}
                fill={'var(--dls-brand-primary-color-500)'}
              />
            </button>
          </div>
        ) : (
          <button
            className="atc-btn"
            data-location="product-card"
            onClick={onCtaClick}
          >
            {showLoader ? (
              <Spinner
                color={'var(--dls-brand-primary-color-500)'}
                size={'20'}
              />
            ) : (
              <Typography
                variant={TypographyVariants.CTA_LABEL_SMALL}
                customClassName="atc-label"
              >
                {ctaLabel}
              </Typography>
            )}
          </button>
        )}
      </div>
    </ProductCardV2Container>
  )
}

export default CategoryProductCard
