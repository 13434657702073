import {CategoryListItemProps} from './types'
import {StyledCategoryListItem} from './styles'
import {OptimizedImage, Typography, TypographyVariants} from '../../../atomic'
import RadioButton from '../../../atomic/RadioButton'

const CategoryListItem = (props: CategoryListItemProps) => {
  const {
    label = '',
    icon = '',
    isSelected = false,
    onOptionSelect,
    radioButtonConfig = {},
    imageConfig = {},
  } = props

  return (
    <StyledCategoryListItem
      as="div"
      onClick={onOptionSelect}
      className={isSelected ? 'selected-category' : ''}
    >
      <div className="left-container">
        <OptimizedImage
          source={icon}
          customClassName="avatar"
          mobileViewWidth="SMALL"
          desktopViewWidth="TINY"
          {...imageConfig}
        />
        <Typography variant={TypographyVariants.SUBHEADING_REGULAR}>
          {label}
        </Typography>
      </div>
      <RadioButton {...radioButtonConfig} selected={isSelected} />
    </StyledCategoryListItem>
  )
}

export default CategoryListItem
