import {useCallback} from 'react'
import {useCartStore} from '@mosaic-wellness/redux-action-library'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {analytics} from 'src/analytics'

function useRxCartTracking() {
  const {cart} = useCartStore()

  const trackPrescriptionViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.VIEW_PRESCRIPTION_CLICKED_RX_CART, {})
  }, [])

  const trackPrescriptionDownload = useCallback(() => {
    analytics.trigger(EVENT_MAP.PRESCRIPTION_DOWNLOAD, [])
  }, [])

  const trackRxCartViewed = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.VIEW_RX_CART, data)
      analytics.trigger(EVENT_MAP.CART_VIEWED, {cart})
    },
    [cart]
  )

  const trackCheckoutClicked = useCallback((data) => {
    analytics.trigger(EVENT_MAP.CHECKOUT_CLICKED_ON_RX_CART, data)
  }, [])

  const trackProductRemoved = useCallback((data) => {
    analytics.trigger(EVENT_MAP.PRODUCT_REMOVED_FROM_RX_CART, data)
  }, [])

  const trackAddToCart = useCallback((data) => {
    analytics.trigger(EVENT_MAP.ADD_TO_CART_CLICKED_RX_CART, data)
  }, [])

  const trackDocCardClick = useCallback((data) => {
    analytics.trigger(EVENT_MAP.CTE_CLICKED, data)
  }, [])

  const trackProductInfoViewed = useCallback((data) => {
    analytics.trigger(EVENT_MAP.VIEW_PRODUCT_INFO_RX_CART, data)
  }, [])

  const trackPdpViewedFromRxCart = useCallback((data) => {
    analytics.trigger(EVENT_MAP.VIEW_PDP_FROM_RX_CART, data)
  }, [])

  return {
    trackAddToCart,
    trackCheckoutClicked,
    trackPdpViewedFromRxCart,
    trackPrescriptionDownload,
    trackPrescriptionViewed,
    trackProductInfoViewed,
    trackRxCartViewed,
    trackProductRemoved,
    trackDocCardClick,
  }
}

export {useRxCartTracking}
