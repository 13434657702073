import {useInitializeWebUtils} from 'src/hooks/boot/useInitializeWebUtils'
import {useAuthBoot} from 'src/hooks/useAuthorizedNav/useAuthBoot'
import useLogUsersLocation from 'src/hooks/useLogUsersLocation/useLogUsersLocation'
import {useUtmExperimentsBoot} from 'src/hooks/useUtmExperiments/useUtmExperiments'
import useOnboardingCompletion from '../OnBoarding/useOnboardingCompletion'
import {useInitializeClientSideExperiments} from 'src/hooks/useInitializeClientSideExperiments'

function Boot({isApp = false}) {
  useAuthBoot()
  useInitializeWebUtils({isApp})
  useUtmExperimentsBoot()
  useOnboardingCompletion()
  useLogUsersLocation()
  useInitializeClientSideExperiments()
  return null
}

export default Boot
