export const CONSTANTS = {
  VIEW_ALL_TEXT: 'View All',
  TOTAL_TEXT: 'Total',
  SAVED_TEXT: 'Saved',
  CHECKOUT_TEXT: 'Checkout',
  CART_VALUE_TEXT: 'Cart Value',
  CART_COUPON_TITLE: 'Apply Coupon',
  CART_COUPON_SUBTITLE: 'View Offers',
  PRODUCT_BUY_LABEL: 'Buy Now',
  PRODUCT_ADD_CART_LABEL: 'Add to Cart',
  PRODUCT_ADDED_TO_CART: 'Added',
  STEP_TEXT: 'Step',
  PRODUCT_MRP_DISCLAIMER_TEXT: 'MRP Inclusive of all Taxes',
  PRODUCT_REVIEWED_TEXT: ' Reviewed',
  PRODUCT_UNITS_TEXT: ' Units ',
  PRODUCT_SOLD_TEXT: 'sold',
  REVIEWS_TEXT: 'Reviews',
  ASK_A_QUESTION_TEXT: 'Ask a Question',
  VIEW_ALL_QUESTIONS_TEXT: 'View All Questions',
  WRITE_A_REVIEW_TEXT: 'Write A Review',
  VIEW_ALL_REVIEWS_TEXT: 'View All Reviews',
  ADDRESS_INVALID_STATE_DROPDOWN_ERROR:
    'Invalid input. Please select a state from the dropdown',
  SELECT_BANK_TEXT: 'Select Bank',
  SELECT_YOUR_BANK_TEXT: 'Select your bank',
  ALL_BANKS_TEXT: 'All Banks',
  GO_TO_CART: 'Go to Cart',
  ADDED_TO_CART: 'Added to cart',
  NOTIFY_ME: "You will be notified when it's back",
  ORDER_ID_TEXT: 'Order ID',
  ORDER_STATUS_TEXT: 'Order status',
  PRODUCT_LOAD_MORE_TEXT: 'Load More',
}
