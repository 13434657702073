import {useRouter} from 'next/router'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {analytics} from 'src/analytics'
import Callout from 'src/components/shared/src/ComponentsV2/common/Callout'
import {useGenericCta} from 'src/hooks/useGenericCta'

function PageCallout(props: any) {
  const {pageCallout, appConfig, isRept} = props
  const pageCalloutData = useMemo(() => {
    const userType = String(isRept) === '1' ? 'repeat' : 'new'
    let value = pageCallout?.[userType]
    if (!value) {
      value = appConfig?.pageCallout?.[userType]
    }
    return value
  }, [pageCallout, appConfig, isRept])
  const {pageRegEx = ''} = pageCalloutData || {}

  const {asPath} = useRouter()
  const {handleCta} = useGenericCta()
  const showCallOutOnRoute = useMemo(() => {
    if (!asPath || !pageRegEx) {
      return false
    }

    const regExForShowingCallout = new RegExp(pageRegEx)

    const [path] = asPath.split('?')
    return regExForShowingCallout.test(path)
  }, [asPath, pageRegEx])

  const [showCallout, setShowCallout] = useState(showCallOutOnRoute)

  const handleCloseCallout = useCallback(() => {
    setShowCallout(false)
  }, [])

  const handleClick = useCallback(
    (cta) => {
      analytics.trigger('calloutClick', cta)
      handleCta(cta)
    },
    [handleCta]
  )

  useEffect(() => {
    setShowCallout(showCallOutOnRoute)
  }, [asPath, showCallOutOnRoute])

  useEffect(() => {
    const navLinksNode = document.querySelector('.mobile-nav-links-container')

    if (navLinksNode) {
      if (showCallout) {
        navLinksNode.classList.add('extra-spacing')
      } else {
        navLinksNode.classList.remove('extra-spacing')
      }
    }
  }, [showCallout])
  if (!pageRegEx) {
    return null
  }
  return (
    <Callout
      showCallout={showCallout}
      onCloseCallout={handleCloseCallout}
      onClick={handleClick}
      {...pageCalloutData}
    />
  )
}

export default PageCallout
