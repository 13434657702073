import styled from 'styled-components'

export const StyledOrdersListingInSupportItem = styled.div`
  background-color: var(--dls-brand-secondary-color-500);
  border: var(--dls-size-2) solid var(--dls-white-color);
  border-radius: var(--dls-size-16);
  padding: var(--dls-size-16);
  position: relative;
  overflow: hidden;

  .content-wrapper {
    z-index: 1;
    position: relative;
  }

  .backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .details {
    color: var(--dls-secondary-text-colors);
  }

  .detailSeperator {
    height: var(--dls-size-2);
  }

  .cta {
    margin-top: var(--dls-size-16);
  }
`
