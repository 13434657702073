import {useCallback} from 'react'
import {useUserStore, useCartStore} from '@mosaic-wellness/redux-action-library'

import {logError} from 'src/utils/logger'
import {generateRandomString} from 'src/utils/generateRandomString'
import {ALGOLIA_CONSTANTS, ALGOLIA_EVENTS} from 'src/constants/searchEvents'
import {storage} from 'src/utils/browserStorage'

const sendEvent = async ({
  eventType,
  eventName,
  ids,
  position,
  location,
  userToken,
  queryId,
}: {
  eventType: string
  eventName: string
  ids?: string[]
  position?: number
  userToken: string
  location: string
  queryId?: string
}) => {
  fetch(ALGOLIA_CONSTANTS.EVENT_URL, {
    method: 'post',
    headers: {
      'X-Algolia-Api-Key': ALGOLIA_CONSTANTS.SEARCH_API_KEY,
      'X-Algolia-Application-Id': ALGOLIA_CONSTANTS.APPLICATION_ID,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      events: [
        {
          eventType: eventType,
          eventName: eventName,
          index: ALGOLIA_CONSTANTS.SEARCH_INDEX,
          userToken: userToken,
          objectIDs: ids,
          positions: position ? [position] : undefined,
          queryID: queryId,
        },
      ],
    }),
  }).catch((e) => {
    logError(e as Error, {
      location: location,
      source: `Algolia ${eventName}`,
    })
  })
}

export const useSendEventsToAlgolia = () => {
  const {
    user: {phoneNumber, isLoggedIn},
  } = useUserStore()
  const {
    cart: {products},
  } = useCartStore()

  const generatePayloadAndSendEvent = useCallback(
    async ({
      eventName,
      id,
      position,
      userToken,
      queryId,
    }: {
      eventName: keyof typeof ALGOLIA_EVENTS
      id?: string
      position?: number
      userToken: string
      queryId?: string
    }) => {
      switch (eventName) {
        case 'ADD_TO_CART':
        case 'PRODUCT_VIEWED': {
          if (!id) break
          const stringifiedObjectIDs = await storage.getItem(
            ALGOLIA_CONSTANTS.LOCAL_STORAGE_KEY_NAME
          )
          const objectIDs = stringifiedObjectIDs
            ? JSON.parse(stringifiedObjectIDs)
            : []
          const ifIdAlreadyInObjectIDs = objectIDs.some(
            (objectID: string) => objectID === id
          )

          if (queryId) storage.setItem(ALGOLIA_CONSTANTS.QUERY_ID, queryId)
          if (!ifIdAlreadyInObjectIDs) {
            const newObjectIds = [...objectIDs, id].slice(-20)
            storage.setItem(
              ALGOLIA_CONSTANTS.LOCAL_STORAGE_KEY_NAME,
              JSON.stringify(newObjectIds)
            )
          }

          sendEvent({
            ...ALGOLIA_EVENTS[eventName],
            ids: [id.toString()],
            position,
            userToken,
            queryId,
          })
          break
        }
        case 'PAY_CLICKED': {
          const stringifiedObjectIDs = await storage.getItem(
            ALGOLIA_CONSTANTS.LOCAL_STORAGE_KEY_NAME
          )
          const objectIDs = stringifiedObjectIDs
            ? JSON.parse(stringifiedObjectIDs)
            : []
          if (typeof window !== 'undefined') {
            window.localStorage.removeItem(
              ALGOLIA_CONSTANTS.LOCAL_STORAGE_KEY_NAME
            )
          }
          const searchedIdsInCart = objectIDs.filter((id: string | number) => {
            const foundProduct = products.find((product) => {
              const {item} = product || {}
              const {productURL} = item || {}
              return id === productURL
            })
            return Boolean(foundProduct)
          })
          if (searchedIdsInCart.length > 0) {
            const objectIDsForEvent = searchedIdsInCart.map(
              (id: string | number) => id.toString()
            )

            const queryIDFromLocal =
              (await localStorage.getItem(ALGOLIA_CONSTANTS.QUERY_ID)) || ''
            sendEvent({
              ...ALGOLIA_EVENTS.PAY_CLICKED,
              ids: objectIDsForEvent,
              position,
              userToken,
              queryId: queryIDFromLocal,
            })
          }
          break
        }
        default:
          break
      }
    },
    [storage, products]
  )

  const sendEventToAlgolia = useCallback(
    async ({
      eventName,
      id,
      position,
      queryId,
    }: {
      eventName: keyof typeof ALGOLIA_EVENTS
      id?: string
      position?: number
      queryId?: string
    }) => {
      try {
        if (isLoggedIn) {
          generatePayloadAndSendEvent({
            eventName,
            id,
            position,
            queryId,
            userToken: phoneNumber,
          })
        } else {
          let userToken =
            (await storage.getItem(ALGOLIA_CONSTANTS.USER_TOKEN)) || ''
          if (userToken) {
            generatePayloadAndSendEvent({
              eventName,
              id,
              position,
              userToken,
              queryId,
            })
          } else {
            userToken = generateRandomString(14)
            storage.setItem(ALGOLIA_CONSTANTS.USER_TOKEN, userToken)
            generatePayloadAndSendEvent({
              eventName,
              id,
              position,
              userToken,
              queryId,
            })
          }
        }
      } catch (e) {
        logError(e as Error, {
          source: `Algolia Event`,
        })
      }
    },
    [isLoggedIn, phoneNumber, storage, generatePayloadAndSendEvent]
  )

  return {
    sendEventToAlgolia,
  }
}
