import {GenericAction} from '@mosaic-wellness/fe-types'
import isEmpty from 'lodash/isEmpty'

export function interceptAndEnhanceAction(
  action: GenericAction,
  source: string
): GenericAction {
  const {params} = action || {}
  const {triggerAnalytics = false, analyticsData = {}} = params || {}
  const {eventData = {}} = analyticsData

  if (triggerAnalytics && !isEmpty(analyticsData) && !isEmpty(eventData)) {
    return {
      ...action,
      params: {
        ...params,
        analyticsData: {
          ...analyticsData,
          eventData: {
            ...eventData,
            source,
          },
        },
      },
    }
  }

  return action
}
