import {useCallback} from 'react'
import {useCartStore} from '@mosaic-wellness/redux-action-library'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {analytics} from 'src/analytics'

function useSATracking() {
  const {cart} = useCartStore()

  const onSelfAssessmentViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_VIEWED, {})
  }, [])

  const onSelfAssessmentStarted = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_STARTED, {})
  }, [])

  const onSelfAssessmentOpen = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_CHAT_OPEN, {})
  }, [])

  const onSelfAssessmentNameSubmitted = useCallback((name) => {
    analytics.trigger(EVENT_MAP.SA_NAME_SUBMITTED, {name})
  }, [])

  const onSelfAssessmentCategorySelectionViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_CATEGORY_VIEWED, {})
  }, [])

  const onSelfAssessmentCategorySelected = useCallback((category) => {
    analytics.trigger(EVENT_MAP.SA_CATEGORY_SUBMITTED, {category})
    analytics.push({
      form: {category},
    })
  }, [])

  const onSelfAssessmentQuestion = useCallback((data) => {
    analytics.trigger(EVENT_MAP.SA_QUESTION, data)
  }, [])

  const onSelfAssessmentBasicDetails = useCallback((data) => {
    analytics.trigger(EVENT_MAP.SA_BASIC_DETAILS, data)
  }, [])

  const onSelfAssessmentQuestionUndo = useCallback((data) => {
    analytics.trigger(EVENT_MAP.SA_QUESTION_UNDO, data)
  }, [])

  const onSelfAssessmentSubmit = useCallback((category) => {
    analytics.trigger(EVENT_MAP.SA_SUBMITTED, {category})
  }, [])

  const onSelfAssessmentReportViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_REPORT_SEEN, {})
  }, [])

  const onSelfAssessmentAppointmentViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_APPOINTMENT_VIEWED, {})
  }, [])

  const onSelfAssessmentAppointmentSubmitted = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_APPOINTMENT_SUBMITTED, {})
  }, [])

  const onSelfAssessmentAppointmentBooked = useCallback((data) => {
    analytics.trigger(EVENT_MAP.SA_APPOINTMENT_BOOKED, data)
  }, [])

  const onSelfAssessmentPlanClicked = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_PLAN_CLICKED, {})
  }, [])

  const onSelfAssessmentPlanViewed = useCallback(
    ({selectLanguage = false}) => {
      analytics.trigger(EVENT_MAP.SA_PLAN_VIEWED, {})
      analytics.trigger(EVENT_MAP.CART_VIEWED, {selectLanguage, cart})
    },
    [cart]
  )

  const trackProductRemoved = useCallback((data) => {
    analytics.trigger(EVENT_MAP.SA_PLAN_PRODUCT_REMOVE, data)
  }, [])

  const trackAddToCart = useCallback((data) => {
    analytics.trigger(EVENT_MAP.SA_PLAN_PRODUCT_ADD, data)
  }, [])

  const trackProductInfoViewed = useCallback((data) => {
    analytics.trigger(EVENT_MAP.SA_PLAN_PRODUCT_INFO_CARD_CLICKED, data)
  }, [])

  const trackPdpViewedFromRxCart = useCallback((data) => {
    analytics.trigger(EVENT_MAP.SA_PLAN_TO_PDP, data)
  }, [])

  const trackCheckoutClicked = useCallback((data) => {
    analytics.trigger(EVENT_MAP.SA_PLAN_ORDER_CLICKED, data)
  }, [])

  const trackAppointmentCardShown = useCallback((data) => {
    analytics.trigger(EVENT_MAP.SA_PLAN_APPOINTMENT_CARD_SHOWN, data)
  }, [])

  const trackPrescriptionViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.SA_PRESCRIPTION_VIEWED, {})
  }, [])

  const pushReportToDataLayer = useCallback((data) => {
    const dLayer = window?.customDataLayer
    const category = dLayer?.form?.category
    analytics.push({
      form: {
        category,
        score: data?.score,
        condition: data?.condition,
        products: data?.products,
        diagnosis: data?.diagnosis,
        diagnosisData: data?.diagnosisData,
        pricing: data?.pricing,
      },
    })
  }, [])

  const onSaApiError = useCallback((data) => {
    analytics.trigger(EVENT_MAP.SA_API_ERROR, data)
  }, [])

  return {
    onSelfAssessmentViewed,
    onSelfAssessmentStarted,
    onSelfAssessmentOpen,
    onSelfAssessmentNameSubmitted,
    onSelfAssessmentCategorySelectionViewed,
    onSelfAssessmentCategorySelected,
    onSelfAssessmentQuestion,
    onSelfAssessmentBasicDetails,
    onSelfAssessmentQuestionUndo,
    onSelfAssessmentSubmit,
    onSelfAssessmentReportViewed,
    onSelfAssessmentAppointmentViewed,
    onSelfAssessmentAppointmentSubmitted,
    onSelfAssessmentAppointmentBooked,
    trackProductRemoved,
    trackAddToCart,
    trackProductInfoViewed,
    trackPdpViewedFromRxCart,
    trackCheckoutClicked,
    trackAppointmentCardShown,
    pushReportToDataLayer,
    onSelfAssessmentPlanClicked,
    onSelfAssessmentPlanViewed,
    trackPrescriptionViewed,
    onSaApiError,
  }
}

export default useSATracking
