import {useCallback, useEffect} from 'react'
import {
  HttpClient,
  useLoadingStore,
  useUserStore,
} from '@mosaic-wellness/redux-action-library'

import {CLIENT_ENV} from 'src/constants/clientEnv'

const BRAND = CLIENT_ENV.NEXT_PUBLIC_BRAND || 'lj'
const MAGENTO_BASE = CLIENT_ENV.NEXT_PUBLIC_BW_MAGENTO || ''
const STORAGE_KEY_FOR_BW_PERSIST = 'persist:nextjs'

const fetchAndHandleSessionData: (
  refreshToken: string
) => Promise<string | void> = async (refreshToken) => {
  const axios = HttpClient.getAxiosInstance()
  try {
    const res = await axios.get(`${MAGENTO_BASE}Magento2/rest/V1/me`, {
      headers: {
        authorization: `Bearer ${refreshToken}`,
      },
    })
    if (res.data) {
      const dataForStorage = {
        userStore: JSON.stringify({
          customer: res.data,
          selectedAddress: res.data.addresses?.[0] || {},
          isLoggedIn: true,
          token: refreshToken,
        }),
        _persist: '{"version":-1,"rehydrated":true}',
      }

      try {
        window.localStorage.setItem(
          STORAGE_KEY_FOR_BW_PERSIST,
          JSON.stringify(dataForStorage)
        )
      } catch (error) {
        console.log(
          '🚀 ~ file: useSessionTransfer.ts ~ line 25 ~ )=>Promise<string|void>= ~ error',
          error
        )
      }
    }
  } catch (error) {
    console.log(
      '🚀 ~ file: useSessionTransfer.ts ~ line 9 ~ fetchTransferToken ~ error',
      error
    )
  }
}

const executeMmStateTransfer = async () => {
  const axiosInstance = HttpClient.getAxiosInstance()

  try {
    let headers = {}
    const {data} = await axiosInstance.get('user/setuserauth', {
      params: {
        getCookie: 'Y',
      },
    })

    if (data.result.mwrefreshtoken) {
      headers = {
        ...headers,
        mwrefreshtoken: data.result.mwrefreshtoken,
      }
    }

    if (data.result.mwquoteid) {
      headers = {
        ...headers,
        mwquoteid: data.result.mwquoteid,
      }
    }

    await axiosInstance.get(
      `${window.location.origin}/?wc-ajax=set_user_auth`,
      {
        headers,
      }
    )
  } catch (err) {
    console.log(
      '🚀 ~ file: useSessionTransfer.ts ~ line 82 ~ executeMmStateTransfer ~ err',
      err
    )
  }
}

const handleSetSessionDataInStorage = () => {}

export const useSessionTransfer = () => {
  const {
    user: {isLoggedIn, refreshToken},
  } = useUserStore()
  const {isUserHydration} = useLoadingStore()

  const handleSessionTransfer = useCallback(() => {
    if (refreshToken) {
      fetchAndHandleSessionData(refreshToken)
    }
  }, [refreshToken])

  // useEffect(() => {
  //   if (isLoggedIn && BRAND === 'bw') {
  //     handleSessionTransfer()
  //   }
  // }, [handleSessionTransfer, isLoggedIn])

  // useEffect(() => {
  //   if (!isUserHydration && BRAND === 'mm') {
  //     executeMmStateTransfer()
  //   }
  // }, [isLoggedIn, isUserHydration])
}
