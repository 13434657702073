import React from 'react'
import {ReviewWithImageWrapper} from './ReviewWithImage.styles'
import {IReviewWithImage} from './ReviewWithImage.types'
import {
  OptimizedImage,
  Typography,
  TypographyVariants,
  ReviewRatingStar,
} from '@web-components'

const ReviewWithImage = (props: IReviewWithImage) => {
  const {bgImage, content, quoteImage} = props || {}

  return (
    <ReviewWithImageWrapper bgImage={bgImage}>
      <div className="quotation-mark-holder">
        <OptimizedImage source={quoteImage} customClassName="quotation-mark" />
      </div>
      <div className="review-content-wrapper">
        <div className="review-content-info">
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {content.info}
          </Typography>
        </div>
        <div className="media-info-divider"></div>
        <div className="review-info-bottom">
          <div className="review-info-name-title">
            <Typography variant={TypographyVariants.TAG_REGULAR}>
              {content.bottomContent.name}
            </Typography>
            <Typography variant={TypographyVariants.TAG_REGULAR}>
              {content.bottomContent.designation}
            </Typography>
          </div>
          <div className="rating">
            <ReviewRatingStar
              count={5}
              value={5}
              size={22}
              className="ratingStyle"
            />
          </div>
        </div>
      </div>
    </ReviewWithImageWrapper>
  )
}

export default ReviewWithImage
