import {HttpClient} from '@mosaic-wellness/redux-action-library'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {logError} from 'src/utils/logger'

export const reportUtmParams = async (params: Record<string, any>) => {
  const axiosInstance = HttpClient.getAxiosInstance()

  try {
    await axiosInstance.post(API_END_POINTS.POST_ATTRIBUTION_DATA, params)
  } catch (error) {
    logError(error as Error, {
      location: 'useUTM.ts',
      source: 'Error while handling UTM reporting',
    })
  }
}
