import styled from 'styled-components'

export const ReviewImageSliderWrapper = styled.div`
  padding: var(--dls-size-48) 0;

  .header {
    display: flex;
    justify-content: space-between;
    padding: var(--dls-size-64) 0;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      padding-top: 0;
    }
  }

  .customer-rating-wrapper {
    padding: 0 var(--dls-size-16) var(--dls-size-24) var(--dls-size-16);
    display: flex;
    gap: var(--dls-size-16);
    align-items: center;
  }

  .avatar-wrapper {
    display: flex;
  }

  .avatar-container {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: -4.5px;
  }

  .avatar-image {
    width: 100%;
  }

  .rating-container {
    display: flex;
    gap: 5px;
  }
  .rating-number {
    background-color: var(--dls-brand-primary-color-500);
    padding: 1px var(--dls-size-8);
    border-radius: var(--dls-size-16);
    color: var(--dls-white-color);
  }

  .rating {
    display: flex;
  }
`
