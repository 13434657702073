import noop from 'lodash/noop'
import {IUseDaScheduleScreenProps} from './directAssessment.interface'
import {getMappedCategory} from './directAssessment.utils'
import {checkIsApp} from 'src/utils/checkIsApp'
import {useSwipeToBookData} from '../consult/useSwipeToBookData/useSwipeToBookData'
import {toast} from 'src/toast'
import {CLIENT_ENV} from 'src/constants/clientEnv'

const BRAND = CLIENT_ENV.NEXT_PUBLIC_BRAND || 'mm'

function useDaScheduleScreen({
  scheduleScreenData,
  category = '',
}: IUseDaScheduleScreenProps) {
  const isApp = checkIsApp()

  const {
    state: {
      isLoggedIn = false,
      firstName = '',
      lastName = '',
      phoneNumber = '',
      email = '',
      slotData = null,
      bookData = null,
      bookError = null,
      isLoading = false,
      isBookSlotInProgress = false,
    },
    actions: {
      setShowLoginModal = noop,
      getSlotsData = noop,
      bookSlotsData = noop,
    },
  } = useSwipeToBookData({isUsedInWebView: isApp})

  return [
    {
      scheduleScreenUIData: scheduleScreenData,
      slotData,
      bookData,
      bookError,
      firstName,
      isLoggedIn,
      lastName,
      phoneNumber,
      email,
      isLoading,
      category: getMappedCategory(category),
      brand: BRAND.toUpperCase(),
      isBookSlotInProgress,
    },
    {
      getSlotsData,
      bookSlotsData,
      setShowLoginModal,
      toast,
    },
  ]
}

export {useDaScheduleScreen}
