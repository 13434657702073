import React, {useCallback, useEffect, useRef, useState} from 'react'
import {DownloadAppCalloutContainer} from './DownloadAppCallout.styles'
import {IDownloadAppCalloutProps} from './DownloadAppCallout.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import {Typography, TypographyVariants} from '@web-components'
import {useGenericActions} from '../../hooks'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

function DownloadAppCallout(props: IDownloadAppCalloutProps) {
  const {id, type, widgetData, layout} = props
  const [showDownloadAppSection, setShowDownloadAppSection] = useState(false)
  const [countDownTimer, setCountDownTimer] = useState(
    widgetData.timerSeconds || 30
  )
  const [mobileNumber, setMobileNumber] = useState<string>()
  const [linkSent, setLinkSent] = useState(false)
  const timerInterval = useRef()

  const {genericAction} = useGenericActions()

  const {
    buttonLabel = '',
    calloutTitle = '',
    cashbackAmount = 50,
    cashbackText = '',
    cta,
    downloadText = '',
    timeRunningOutLabel = '',
    downloadCta,
  } = widgetData || {}

  const handleDownloadClicked = useCallback(() => {
    genericAction(interceptAndEnhanceAction(downloadCta, id))
    setShowDownloadAppSection((flag) => !flag)
  }, [downloadCta, genericAction, id])

  const handleSendLink = useCallback(() => {
    genericAction(
      interceptAndEnhanceAction(
        {
          ...cta,
          actionData: {
            ...cta.actionData,
            endpointBody: {
              ...cta.actionData.endpointBody,
              phoneNumber: mobileNumber,
            },
          },
        },
        id
      )
    )
    handleDownloadClicked()
    setLinkSent(true)
  }, [cta, genericAction, handleDownloadClicked, id, mobileNumber])

  useEffect(() => {
    if (showDownloadAppSection) {
      clearInterval(timerInterval.current)
      setCountDownTimer(() => 30)
      timerInterval.current = setInterval(() => {
        setCountDownTimer((timer) => (timer === 0 ? 0 : timer - 1))
      }, 1000)
    }

    return () => clearInterval(timerInterval.current)
  }, [showDownloadAppSection])

  if (linkSent) {
    return null
  }

  return (
    <BaseWidgetComponent widgetId={id} widgetType={type} layout={layout}>
      <DownloadAppCalloutContainer>
        {showDownloadAppSection ? (
          <div className="send-link-container">
            <div className="cashback-text-container">
              <Typography
                variant={TypographyVariants.BODY_BASE_REGULAR}
                customClassName="cashback-text"
              >
                {cashbackText}
              </Typography>
              <button className="close-button" onClick={handleDownloadClicked}>
                <Typography variant={TypographyVariants.SUBHEADING_BOLD}>
                  {'×'}
                </Typography>
              </button>
            </div>
            <div className="amount-container">
              <Typography
                customClassName="download-text"
                variant={TypographyVariants.SUBHEADING_REGULAR}
              >
                {downloadText}
              </Typography>
              <Typography
                customClassName="amount-text"
                variant={TypographyVariants.SUBHEADING_BOLD}
              >
                ₹{cashbackAmount}
              </Typography>
            </div>
            <div className="mobile-number-container">
              <input
                className="mobile-number-input"
                type="number"
                value={mobileNumber}
                placeholder="Enter Mobile Number"
                onChange={(e) =>
                  setMobileNumber((number) =>
                    e.target.value.length <= 10 ? e.target.value : number
                  )
                }
                maxLength={10}
              />
              <button
                className="send-link"
                disabled={
                  !mobileNumber
                    ? true
                    : mobileNumber?.length < 10
                    ? true
                    : false
                }
                onClick={handleSendLink}
              >
                Send Link
              </button>
            </div>
            <div className="time-container">
              <div className="timer-text">
                <span className="timer-icon">⏱</span>
                <Typography
                  variant={TypographyVariants.SUBHEADING_BOLD}
                  customClassName="timer"
                >
                  0:{countDownTimer}
                </Typography>
              </div>
              <Typography
                variant={TypographyVariants.BODY_BASE_REGULAR}
                customClassName="time-running-out"
              >
                {timeRunningOutLabel}
              </Typography>
            </div>
          </div>
        ) : (
          <div className="callout-container">
            <Typography
              variant={TypographyVariants.TAG_REGULAR}
              customClassName="callout-title"
            >
              {calloutTitle}
            </Typography>
            <button onClick={handleDownloadClicked} className="download-button">
              {buttonLabel}
            </button>
          </div>
        )}
      </DownloadAppCalloutContainer>
    </BaseWidgetComponent>
  )
}

export default DownloadAppCallout
