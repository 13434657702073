import {usePost} from '@mosaic-wellness/core-utils'
import {useCallback} from 'react'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {IGlobalResponse} from 'src/interfaces/globalResponse.interface'
import {
  IRescheduleAppointmentPayload,
  IRescheduleAppointmentResponse,
} from './useRescheduleAppointment.interface'

function useRescheduleAppointment() {
  const {isLoading, data, reset, mutate} = usePost<
    IGlobalResponse<IRescheduleAppointmentResponse>,
    any,
    IRescheduleAppointmentPayload
  >({
    url: API_END_POINTS.RESCHEDULE_APPOINTMENT,
    customConfig: {
      triggerAnalytics: true,
      analyticsEventName: 'DA Error',
      showErrorToast: true,
    },
  })

  const resetRescheduleAppointmentData = useCallback(() => {
    reset()
  }, [reset])

  const rescheduleAppointment = useCallback(
    (reschedulePayload: IRescheduleAppointmentPayload) => {
      mutate(reschedulePayload)
    },
    [mutate]
  )

  return [
    {isRescheduleInProgress: isLoading, rescheduleAppointmentData: data?.data},
    {rescheduleAppointment, resetRescheduleAppointmentData},
  ] as const
}

export {useRescheduleAppointment}
