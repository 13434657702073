import React from 'react'
import {ReelCard} from './ReelCard'
import {ReelSliderWrapper} from './ReelSlider.styles'
import {
  CtaWithAnimation,
  GenericCarousel,
  GenericSlider,
  useDeviceTypeContext,
} from '@web-components'
import {BaseWidget, GenericAction} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {useGenericActions} from '../../hooks'
import {TitleWithLabel} from '../TitleWithLabel'

const ReelSlider = (props: BaseWidget) => {
  const {widgetData, type, id, layout} = props
  const {items = [], title = '', label = '', bottomCta = {}} = widgetData || {}
  const {genericAction} = useGenericActions()
  const {isMobile = false} = useDeviceTypeContext()

  const handleBottomCtaClick = (action: GenericAction) => {
    genericAction(action)
  }
  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <ReelSliderWrapper>
        <TitleWithLabel label={label} title={title} color="black" />

        <GenericSlider
          noOfSlides={isMobile ? 1.05 : 5}
          aspectRatio={1}
          isShowArrows={true}
          arrowPositionY={200}
          arrowGap={1400}
        >
          {() =>
            items.map((item: any, index: number) => (
              <ReelCard widgetData={item} />
            ))
          }
        </GenericSlider>

        <div className="bottom-cta">
          <CtaWithAnimation
            onClick={() => handleBottomCtaClick(bottomCta.action)}
            variant="SECONDARY"
          >
            {bottomCta.label}
          </CtaWithAnimation>
        </div>
      </ReelSliderWrapper>
    </BaseWidgetComponent>
  )
}

export default ReelSlider
