import {IActionLibraryConfig} from '@mosaic-wellness/redux-action-library'
import {
  attributeUserForCleverTap,
  recordOnCT,
  trackForCleverTap,
} from './clevertap'
// } from './placeHolders'
import {trackForAffluence} from './affluence'
import {CLIENT_ENV} from 'src/constants/clientEnv'

const CUSTOM_ANALYTICS_ENABLED = CLIENT_ENV.NEXT_PUBLIC_CUSTOM_ANALYTICS || ''

type IAnalyticsTrigger = Parameters<
  IActionLibraryConfig['analytics']['trigger']
>
type IAnalyticsPush = Parameters<IActionLibraryConfig['analytics']['push']>

declare global {
  interface Window {
    dataLayer: any[]
    customDataLayer: Record<string, any>
  }
}

export const pushToDataLayer = (data: Record<string, any>) => {
  if (data.user) {
    attributeUserForCleverTap(data.user)
  }

  window.customDataLayer = {...window.customDataLayer, ...data}
}

export async function analyticsTrigger(...args: IAnalyticsTrigger) {
  const [event, data] = args

  if (!!CUSTOM_ANALYTICS_ENABLED) {
    pushToDataLayer({
      [event]: data,
    })
    trackForCleverTap(event, data, window.customDataLayer)
    trackForAffluence(event, data)
  }
  window.dataLayer?.push({event, [event]: data ?? {}})
}

export async function analyticsTriggerBypassProcessor(
  ...args: IAnalyticsTrigger
) {
  const [event, data] = args

  if (!!CUSTOM_ANALYTICS_ENABLED) {
    pushToDataLayer({
      [event]: data,
    })
    recordOnCT(event, data, window.customDataLayer)
  }
  window.dataLayer?.push({event, [event]: data ?? {}})
}

export function analyticsPush(...args: IAnalyticsPush) {
  const [data] = args
  if (!!CUSTOM_ANALYTICS_ENABLED) {
    pushToDataLayer(data)
  }
  window.dataLayer.push(data)
}

export const analytics = {
  trigger: analyticsTrigger,
  push: analyticsPush,
}
