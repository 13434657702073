import React, {useMemo} from 'react'

import Star from '../../../../../common/RatingStars/star'
import {RatingsCompositionWrapper} from './RatingComposition.styles'
import {IRatingCompositionProps} from './RatingComposition.types'
import {CONSTANTS} from 'src/constants/defaultConstants'

function RatingComposition(props: IRatingCompositionProps) {
  const {
    overAllRating = 0,
    totalNumberOfReviews = '',
    reviewsText = CONSTANTS.REVIEWS_TEXT,
    isMobile = false,
  } = props

  const ratingStarsSize = useMemo(() => {
    const size = isMobile ? 22 : 32

    return {
      height: size,
      width: size,
    }
  }, [isMobile])

  return (
    <RatingsCompositionWrapper>
      <div className="ratings_overview">
        <div className="rating_container">
          <span>{overAllRating}</span>
          <div className="star">
            <Star
              height={ratingStarsSize.height}
              width={ratingStarsSize.width}
              isMobile={isMobile}
            />
          </div>
        </div>
        <div className="total_reviews">
          <span>
            {totalNumberOfReviews} {reviewsText}
          </span>
        </div>
      </div>
    </RatingsCompositionWrapper>
  )
}

export default RatingComposition
