import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {StyledSupportMenuItems} from './SupportMenuItems.styles'
import {SupportMenuItemsWidgetData} from './SupportMenuItems.types'
import isEmpty from 'lodash/isEmpty'
import {
  Button,
  MenuRowItem,
  Typography,
  TypographyVariants,
} from '@web-components'
import {useGenericActions} from '../../hooks'
import {useCallback} from 'react'
import AccordionItem from '../Accordion/AccordionItem/AccordionItem'

const SupportMenuItemsWidget = ({
  widgetData,
  layout,
  header,
  type,
  id,
}: BaseWidgetProps<SupportMenuItemsWidgetData>) => {
  const {queries = []} = widgetData || {}
  const {handleGenericActions} = useGenericActions()

  const handlePress = useCallback(
    (cardActions?: GenericAction[]) => () => {
      if (cardActions?.length) {
        handleGenericActions(cardActions)
      }
    },
    [handleGenericActions]
  )

  if (isEmpty(queries)) {
    return null
  }
  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <StyledSupportMenuItems>
        {queries?.map((query, index: number) => {
          const {title, type, body, cardActions, ...restQueryProps} =
            query || {}
          const {text: accordionBodyText, footerCta} = body || {}
          const {
            label: accordionBodyCtaLabel,
            variant: accordionBodyCtaVariant,
            size: accordionBodyCtaSize,
            actions: accordionBodyCtaActions,
            supportLabel: accordionBodyCtaSupportLabel,
            ...restAccordionBodyCtaProps
          } = footerCta || {}

          return (
            <div key={'support_order_' + index} className="">
              {type === 'accordion' ? (
                <AccordionItem
                  title={title}
                  titleVariant={TypographyVariants.BODY_BASE_REGULAR}
                  {...restQueryProps}
                  customContainerClassName="accordion-container"
                  children={
                    <div className="accordion-content">
                      <Typography variant={TypographyVariants.TAG_REGULAR}>
                        {accordionBodyText}
                      </Typography>
                      {accordionBodyCtaLabel && (
                        <>
                          <Button
                            label={accordionBodyCtaLabel}
                            variant={accordionBodyCtaVariant || 'PRIMARY'}
                            size={accordionBodyCtaSize}
                            {...restAccordionBodyCtaProps}
                            customClassName="accordion-chat-cta"
                            onClick={handlePress(accordionBodyCtaActions)}
                          />
                          <Typography variant={TypographyVariants.TAG_REGULAR}>
                            {accordionBodyCtaSupportLabel}
                          </Typography>
                        </>
                      )}
                    </div>
                  }
                />
              ) : (
                <MenuRowItem
                  title={title}
                  icon=""
                  subTitle=""
                  titleVariant={TypographyVariants.BODY_BASE_REGULAR}
                  {...restQueryProps}
                  onClick={handlePress(cardActions)}
                />
              )}
              {index < queries.length - 1 && (
                <div className="menuItemSeperator" />
              )}
            </div>
          )
        })}
      </StyledSupportMenuItems>
    </BaseWidgetComponent>
  )
}

export default SupportMenuItemsWidget
