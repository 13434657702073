import {StyledInfoBannerWrapper} from './styles'
import {Typography, TypographyVariants} from '../../atomic'
import {InfoBannerProps} from '@mosaic-wellness/fe-types'

const InfoBanner = (props: InfoBannerProps) => {
  const {title = '', subTitle = '', variant = 'warning'} = props
  return (
    <StyledInfoBannerWrapper variant={variant}>
      {title && (
        <Typography
          variant={TypographyVariants.TAG_BOLD}
          customClassName="text"
        >
          <span className="text" dangerouslySetInnerHTML={{__html: title}} />
        </Typography>
      )}
      {subTitle && (
        <Typography
          variant={TypographyVariants.TAG_REGULAR}
          customClassName="text"
        >
          <span className="text" dangerouslySetInnerHTML={{__html: subTitle}} />
        </Typography>
      )}
    </StyledInfoBannerWrapper>
  )
}

export default InfoBanner
