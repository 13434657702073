import {
  IActionLibraryConfig,
  _C,
  _R,
} from '@mosaic-wellness/redux-action-library'
import {NextRouter} from 'next/router'
import {noop} from 'rxjs'
import {toast} from '../toast'
import {analyticsPush, analyticsTrigger} from '../analytics'
import {logError, logInfo} from 'src/utils/logger'
import {getMappedFlags} from 'src/utils/getMappedFlags'
import {LOCAL_STORAGE_CONSTANTS, localStorageMethods} from 'src/utils'

import {CLIENT_ENV} from 'src/constants/clientEnv'

function createConfiguration(
  router: NextRouter,
  checkoutConfig: any,
  isApp: boolean = false
): IActionLibraryConfig {
  const appPlatform =
    localStorageMethods.get(LOCAL_STORAGE_CONSTANTS.APP_PLATFORM) || ''
  return {
    flags: getMappedFlags({checkoutConfig}),
    environment: {
      //TODO: Provide baseURL as per the Node Environment for Dev and Prod
      // baseUrl: () => "http://localhost:1337/api/",
      baseUrl: () => {
        return (
          CLIENT_ENV.NEXT_PUBLIC_BACKEND ||
          'https://stg.api.ourlittlejoys.com/portal/'
        )
      },
      toast: toast,
    },
    platform: 'WEB',
    isWebView: isApp,
    webViewPlatform: appPlatform,
    router: {
      back: () => router.back(),
      push: (path: string) => {
        let route = path
        const regex = /^http/
        const isAbsolute = regex.test(path)
        if (path[0] !== '/' && !isAbsolute) {
          route = '/' + path
        }

        try {
          if (isAbsolute) {
            window.location.href = route
          } else {
            router.push(route)
          }
        } catch (e) {
          window.location.href = path !== '/' ? '/' + path : path
        }
      },
      currentPath: () => router.pathname,
    },
    storage: {
      getItem: (key: string) =>
        Promise.resolve(window?.localStorage.getItem(key)),
      setItem: (key: string, value: string) =>
        window?.localStorage.setItem(key, value),
    },
    analytics: {
      trigger: analyticsTrigger,
      triggerThankYouOrder: (orderDetails) => {
        null //TODO: Add relevant Thank you order analytics events
      },
      triggerCart: {
        discount: noop,
        hydration: noop,
        update: noop,
      },
      push: analyticsPush,
    },
    backCompatible: {
      key: 'persist:nextjs',
      mapFrom: () => {
        return new Promise((resolve) => {
          try {
            const oldStore = window.localStorage.getItem('persist:nextjs')
            const newStore = window.localStorage.getItem(_C.STORAGE_USER_KEY)

            if (oldStore) {
              if (JSON.parse(JSON.parse(oldStore).userStore).token) {
                const oldUser = JSON.parse(JSON.parse(oldStore).userStore)
                resolve({
                  isLoggedIn: true,
                  firstName: oldUser?.customer.firstname,
                  lastName: oldUser?.customer.lastname,
                  phoneNumber: oldUser?.customer.phone_number,
                  email: oldUser?.customer.email,
                  id: oldUser?.customer.id,
                })
              }
            }

            if (newStore) {
              if (JSON.parse(newStore).token) {
                resolve(JSON.parse(newStore))
              }
            }

            throw 'New User'
          } catch (e) {
            resolve({
              email: '',
              isLoggedIn: false,
              firstName: '',
              lastName: '',
              phoneNumber: '',
              id: '',
            })
          }
        })
      },
      mapTo: (user) => {
        try {
          const oldStore = window.localStorage.getItem('persist:nextjs')
          if (oldStore) {
            const oldParsedStore = JSON.parse(oldStore)
            oldParsedStore.userStore =
              '{"customer":{},"selectedAddress":{},"token":"","isLoggedIn":false,"authError":"","userExists":null,"usingMiddlelayer":""}'
            localStorage.setItem(
              'persist:nextjs',
              JSON.stringify(oldParsedStore)
            )
          }
        } catch (err) {
          console.error('Attempt to hydrate the old store was unsuccessful')
        }
      },
    },
    checkoutConfig,
    logger: {
      error: logError,
      info: logInfo,
    },
  }
}

export {createConfiguration}
