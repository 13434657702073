import {getQueryParamFromUrl} from './url'

const checkifArrayAndhasElements = (arr: any) =>
  Array.isArray(arr) && arr.length > 0

const getCartSource = () => {
  const intCmpValue = getQueryParamFromUrl('int_cmp')
  if (intCmpValue === 'buyNow') return 'Buy Now Checkout'
  else if (intCmpValue === 'ATCButton') return 'ATC Button'
  else if (intCmpValue === 'PDPNudge') return 'PDP Nudge'
  else if (intCmpValue === 'byok') return 'Build Your Own Kit Page'
  else return 'Cart Icon'
}

export const getCartDetailsForAnalytics = (
  cart: any,
  hideCod: boolean = false,
  user: any
) => {
  const {
    products = [],
    totalAmount = 0,
    appliedDiscount,
    rxStatus,
    billingSummary,
    analyticsData = {},
    cartUi = '',
    checkoutInfo = {},
    flags,
    byokProducts: {products: BYOKProducts = []} = {},
  } = cart || {}
  const {milestoneTier} = checkoutInfo || {}
  const {value} = milestoneTier || {}

  const {walletPoints = 0} = user || {}
  const source = getCartSource()

  const ifCartHasItems = checkifArrayAndhasElements(products)
  const ifcartHasByokItems = checkifArrayAndhasElements(BYOKProducts)

  const totalItems =
    ifCartHasItems || ifcartHasByokItems
      ? products.length + BYOKProducts.length
      : 0
  const combinedItems = ifCartHasItems
    ? ifcartHasByokItems
      ? [...products, ...BYOKProducts]
      : products
    : []

  const reducedData = {
    productIds: '',
    productNames: '',
    productSkus: '',
    productCategories: '',
  }

  combinedItems.forEach(({item}) => {
    if (item) {
      reducedData.productIds += `${item.productURL},`
      reducedData.productNames += `${item.name},`
      reducedData.productSkus += `${item.sku},`
      reducedData.productCategories += `${item.category},`
    }
  })

  const couponData = appliedDiscount?.discount?.coupon || {}

  const {isApplied: isCouponApplied = false, value: coupon = ''} = couponData
  const {hasPseudoRx = false, hasRx = false, hasInstantRx} = rxStatus || {}

  const {analyticsInfo} = billingSummary || {}
  const {
    discountAmount = 0,
    shippingAmount = 0,
    subtotal = 0,
  } = analyticsInfo || {}

  const isShippingFree = shippingAmount === 0

  let freeGiftsCount = 0

  if (ifCartHasItems) {
    products.forEach((product: any) => {
      const {isFree} = product || {}
      if (isFree) freeGiftsCount += 1
    })
  }

  const walletData = appliedDiscount?.discount?.wallet || {}
  const {isApplied: isWalletApplied = false} = walletData
  const URL = typeof window !== 'undefined' ? `${window.location.href}` : ''

  const getCartType = () => {
    if (hasInstantRx) {
      return 'Instant RX'
    }

    if (hasRx) {
      return 'RX'
    }

    if (hasPseudoRx) {
      return 'OPT_RX'
    }

    return 'OTC'
  }

  return {
    productIds: reducedData.productIds,
    productNames: reducedData.productNames,
    productSkus: reducedData.productSkus,
    productCategories: reducedData.productCategories,
    totalItems,
    totalAmount,
    isCouponApplied,
    coupon,
    codBlocked: hideCod,
    source,
    rxStatus: hasRx,
    cartType: getCartType(),
    isShippingFree,
    shippingValue: shippingAmount,
    freeGiftsCount,
    isWalletApplied,
    walletPoints,
    url: URL,
    discountValue: discountAmount,
    email: user?.email || '',
    phoneNumber: user?.phoneNumber || '',
    subTotal: subtotal,
    analyticsData,
    cartUi,
    milestoneTierValue: value ? value : undefined,
    flags,
  }
}
