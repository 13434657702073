import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {StyledSupportTicketsListing} from './SupportTicketsListing.styles'
import {SupportTicketsListingWidgetData} from './SupportTicketsListing.types'
import SupportTicketListingItem from './SupportTicketListingItem'
import isEmpty from 'lodash/isEmpty'
import useSupportTicketsListing from './useSupportTicketsListing'
import {Typography, TypographyVariants} from '@web-components'

const SupportTicketsListingWidget = ({
  widgetData,
  layout,
  header,
  type,
  id,
}: BaseWidgetProps<SupportTicketsListingWidgetData>) => {
  const {
    onFetchAllTicketsClick,
    tickets,
    showFetchAllTickets,
    handleTicketPress,
    handleRatingSelection,
  } = useSupportTicketsListing(widgetData)

  if (isEmpty(tickets)) {
    return null
  }
  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <StyledSupportTicketsListing>
        {tickets?.map((ticket, index: number) => {
          const {
            status,
            statusTag,
            actions,
            createdOn,
            title,
            icon,
            ratingData,
          } = ticket || {}
          return (
            <div key={'support_ticket_' + ticket?.id}>
              <SupportTicketListingItem
                status={status}
                statusTag={statusTag}
                createdOn={createdOn}
                title={title}
                icon={icon}
                ratingData={ratingData}
                actions={actions}
                handleTicketPress={handleTicketPress}
                handleRatingSelection={handleRatingSelection(index)}
              />
              {index < tickets.length - 1 && (
                <div className="ticketSeperator" />
              )}
            </div>
          )
        })}
        {showFetchAllTickets && (
          <div onClick={onFetchAllTicketsClick} className="footerCta">
            <Typography
              variant={TypographyVariants.BODY_BASE_BOLD}
              customClassName="footerCtaText"
            >
              View All
            </Typography>
          </div>
        )}
      </StyledSupportTicketsListing>
    </BaseWidgetComponent>
  )
}

export default SupportTicketsListingWidget
