import React from 'react'
import {ReelCardWrapper} from './ReelCard.styles'
import {Media} from '@web-components'
import {MediaProps} from 'src/mono/web-components/components/molecules/Media/Media.interface'
import {IReelCard} from './ReelCard.types'
import {useGenericActions} from '../../../hooks'
import {GenericAction} from '@mosaic-wellness/fe-types'

const ReelCard = (props: IReelCard) => {
  const {widgetData} = props

  const {mediaData, cta} = widgetData || {}
  const {media, mediaType} = mediaData || {}
  const {handleGenericActions} = useGenericActions()
  const onCardClick = (action: GenericAction[]) => {
    if (action) {
      handleGenericActions(action)
    }
  }

  return (
    <ReelCardWrapper onClick={() => onCardClick(cta.action)}>
      <Media
        media={media}
        mediaType={mediaType}
        customClassName="reel-content"
      />
    </ReelCardWrapper>
  )
}

export default ReelCard
