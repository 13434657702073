// SVG for white cross icon
const IconCross = ({fill = 'white'}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 6.586L11.293 3.293L12.707 4.707L9.414 8L12.707 11.293L11.293 12.707L8 9.414L4.707 12.707L3.293 11.293L6.586 8L3.293 4.707L4.707 3.293L8 6.586Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconCross
