import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import noop from 'lodash/noop'

import {useUserStore} from '@mosaic-wellness/redux-action-library'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {analyticsTrigger} from 'src/analytics'
import {useSwipeToBookData} from '../consult/useSwipeToBookData/useSwipeToBookData'
import {toast} from 'src/toast'

interface IUseOutOfStockProductReturnType {
  state: {
    oosOverlayVisible: boolean
    oosDeliveryToastText: string
  }
  actions: {
    handleNotifyMe: () => void
    handleNotifyMeWithProductDetails: (product: IProductInfoForOOS) => void
  }
}

interface IUseOutOfStockProductProps {
  productInfo?: IProductInfoForOOS
  outOfStockConfig: IOutOfStockConfig
  source?: string
  handleBackClicked?: () => void
}
interface IProductInfoForOOS {
  availableDays: number | undefined
  name: string
  urlKey: string
  category: string
  id: string
}

interface IOutOfStockConfig {
  outOfStockToastMessage: string
  outOfStockDeliveryToastText?: string
}

export function useOutOfStockProduct(
  props: IUseOutOfStockProductProps
): IUseOutOfStockProductReturnType {
  const {user} = useUserStore()
  const {isLoggedIn = false} = user
  const notifyMeIntent = useRef({intentShown: false, data: {}})
  const {
    actions: {setShowLoginModal = noop},
  } = useSwipeToBookData()
  const {outOfStockConfig, productInfo} = props
  const {outOfStockToastMessage = '', outOfStockDeliveryToastText = ''} =
    outOfStockConfig || {}
  const {availableDays = ''} = productInfo || {}

  const oosDeliveryToastText = useMemo(() => {
    if (availableDays)
      return outOfStockDeliveryToastText.replace(
        '++availableDays++',
        String(availableDays)
      )

    return outOfStockDeliveryToastText.replace('++availableDays++', 'few')
  }, [outOfStockConfig, productInfo])

  const [isOverlayVisible, setIsOverlayVisible] = useState(true)

  const handleNotifyMe = useCallback(() => {
    if (!isLoggedIn) {
      notifyMeIntent.current = {intentShown: true, data: {}}
      setShowLoginModal({isOpen: true, triggeredFrom: ''})
      analyticsTrigger(EVENT_MAP.NOTIFY_ME, {
        ...productInfo,
        source: props.source,
      })

      if (props.handleBackClicked) {
        props.handleBackClicked()
      }
      return
    }
    toast('success', outOfStockToastMessage)
    analyticsTrigger(EVENT_MAP.NOTIFY_ME_DONE, {
      ...productInfo,
      source: props.source,
    })
  }, [
    isLoggedIn,
    outOfStockToastMessage,
    setShowLoginModal,
    productInfo,
    props.source,
    props.handleBackClicked,
  ])

  const handleNotifyMeWithProductDetails = useCallback(
    (productInfo) => {
      if (!isLoggedIn) {
        notifyMeIntent.current = {intentShown: true, data: {}}
        setShowLoginModal({isOpen: true, triggeredFrom: ''})
        analyticsTrigger(EVENT_MAP.NOTIFY_ME, {
          ...productInfo,
        })

        if (props.handleBackClicked) {
          props.handleBackClicked()
        }
        return
      }
      toast('success', outOfStockToastMessage)
      analyticsTrigger(EVENT_MAP.NOTIFY_ME_DONE, {
        ...productInfo,
      })
    },
    [isLoggedIn, outOfStockToastMessage, props, setShowLoginModal]
  )

  useEffect(() => {
    if (isLoggedIn && notifyMeIntent.current.intentShown) {
      analyticsTrigger(EVENT_MAP.NOTIFY_ME_DONE, {
        ...productInfo,
        source: props.source,
      })
      setTimeout(() => toast('success', outOfStockToastMessage), 1500)
    }
  }, [isLoggedIn, outOfStockToastMessage, productInfo, props.source])

  useEffect(() => {
    const overlayTimer = setTimeout(() => setIsOverlayVisible(false), 1500)
    return () => {
      clearTimeout(overlayTimer)
    }
  }, [])

  return {
    state: {
      oosOverlayVisible: isOverlayVisible,
      oosDeliveryToastText,
    },
    actions: {
      handleNotifyMe,
      handleNotifyMeWithProductDetails,
    },
  }
}
