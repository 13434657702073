import {EVENT_MAP} from '../eventMap'
import {processAffluencePageViews} from './processor/pageViews'

const AFFLUENCE_API = process.env.NEXT_PUBLIC_AFFLUENCE_API || ''
export const reportToAffluence = (payload: Record<string, any>) => {
  if (!AFFLUENCE_API) {
    return
  }
  try {
    fetch(AFFLUENCE_API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
  } catch (error) {
    console.error('Error reporting to affluence', error)
  }
}

export const trackForAffluence = (event: string, data: any) => {
  let payload: any
  switch (event) {
    case EVENT_MAP.PAGE_VIEWED:
    case EVENT_MAP.PDP_VIEW:
      payload = processAffluencePageViews()
      break
    default:
      break
  }
  if (payload) {
    reportToAffluence(payload)
  }
}
