import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import OrdersListingInSupportItem from './OrdersListingInSupportItem'
import {StyledOrdersListingInSupport} from './OrdersListingInSupport.styles'
import {useGenericActions} from '../../hooks'
import {useCallback} from 'react'
import {OrdersListingInSupportWidgetData} from './OrdersListingInSupport.types'

const OrdersListingInSupportWidget = ({
  widgetData,
  layout,
  header,
  type,
  id,
}: BaseWidgetProps<OrdersListingInSupportWidgetData>) => {
  const {orders = []} = widgetData || {}
  const {handleGenericActions} = useGenericActions()
  const handleCtaPress = useCallback(
    (actions: any) => () => {
      if (!actions?.length) {
        return
      }
      handleGenericActions(actions)
    },
    [handleGenericActions]
  )

  if (!orders?.length) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      {orders?.map((order, index: number) => {
        return (
          <StyledOrdersListingInSupport key={'order_' + index}>
            <OrdersListingInSupportItem
              {...order}
              handleCtaPress={handleCtaPress}
            />
            {index < orders.length - 1 && <div className="orderSeperator" />}
          </StyledOrdersListingInSupport>
        )
      })}
    </BaseWidgetComponent>
  )
}

export default OrdersListingInSupportWidget
