import {useFetch} from '@mosaic-wellness/core-utils'
import {useCallback} from 'react'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {IGlobalResponse} from 'src/interfaces/globalResponse.interface'
import {IFutureAppointment} from './useFutureAppointment.interface'
import {analytics} from 'src/analytics'

function useFutureAppointments() {
  const {isLoading, data, refetch} = useFetch<
    IGlobalResponse<IFutureAppointment>
  >({
    queryKey: 'future-appointments',
    url: API_END_POINTS.FUTURE_APPOINTMENT_DETAILS,
    cacheTime: 0,
    disabled: true,
    showErrorToast: true,
    triggerAnalytics: true,
    analyticsEventName: 'DA Error',
    queryOptions: {
      retry: 0,
      onSuccess: (data) => {
        if (!!data.data?.appointmentId) {
          analytics.trigger('futureAppointment', data.data)
        }
      },
    },
  })

  const checkFutureAppointments = useCallback(async (): Promise<void> => {
    refetch()
  }, [refetch])

  return [
    {futureAppointmentData: data?.data, loadingFutureAppointments: isLoading},
    {checkFutureAppointments},
  ] as const
}

export {useFutureAppointments}
