import styled from 'styled-components'

export const HeaderWrapper = styled.header`
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.47) 40%,
    rgba(255, 255, 255, 0.25) 70%,
    rgba(255, 255, 255, 0.07) 100%
  );
  z-index: 4;
  position: absolute;
  top: 35px;
  width: 100%;

  color: var(--dls-brand-primary-color-500);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--dls-size-16) var(--dls-size-8);

  button {
    &:focus {
      outline: none !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      cursor: pointer;
    }
  }

  .desktop-nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 25px 100px;

    .desktop-logo {
      width: 76px;
      height: 46px;
      object-fit: cover;
    }
  }

  .desktop-nav nav {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: var(--dls-size-48);
    margin-left: 130px;
  }

  .nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
  }

  .desktop-nav-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--dls-size-24);
  }
  .nav-icon {
    height: var(--dls-size-24);
    width: var(--dls-size-24);
  }

  .cross-icon {
    height: var(--dls-size-16);
    width: var(--dls-size-16);
  }

  .desktop-cart-icon-holder {
    display: flex;
    align-items: center;
    gap: var(--dls-size-16);
    cursor: pointer;
  }

  .desktop-shop-btn {
    position: relative;
    display: inline-block;
    overflow: hidden;
    border: 2px solid var(--dls-brand-primary-color-500);
    padding: var(--dls-size-8) var(--dls-size-16);
    border-radius: var(--dls-size-24);
    cursor: pointer;
    color: var(--dls-brand-primary-color-500);
    background-color: transparent;
    transition: color 0.3s ease;
  }

  .desktop-shop-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--dls-brand-primary-color-500);
    z-index: 0;
    transition: left 0.2s ease;
    clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
  }

  .desktop-shop-btn:hover::before {
    left: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .desktop-shop-btn:hover {
    color: white;
  }

  .desktop-shop-btn > * {
    position: relative;
    z-index: 1;
  }

  nav button {
    color: var(--dls-color-white);
    background: none;
    text-decoration: none;
    border: none;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-16);
    width: 100%;
    padding: 0 var(--dls-size-8);
  }

  .nav-btn {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--dls-brand-primary-color-500);
    visibility: hidden;
    opacity: 0;
  }

  @media only screen and (max-width: 1024px) {
    .nav-btn {
      visibility: visible;
      opacity: 1;
    }

    nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 14px;
      background-color: var(--dls-backgound-color-500);

      transform: translateY(-100vh);
    }

    .responsive_nav {
      transform: none;
      transition: ease-in-out 0.5s;
      z-index: 999;
      border-radius: 0 0 25px 25px;
      padding-bottom: 12px;
    }

    .brand-icon {
      width: 60px;
    }

    .mobile-nav-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: var(--dls-size-16) var(--dls-size-16);
    }

    .mobile-nav-menu-shopnow {
      background-color: var(--dls-brand-primary-color-500);
      color: var(--dls-white-color);
      padding: var(--dls-size-8);
      border-radius: 25px;
    }
  }
`
