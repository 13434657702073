import React, {useMemo} from 'react'
import RatingComposition from './RatingComposition'
import RatingBars from './RatingBars'
import {RatingsContainerWrapper} from './RatingsContainer.styles'
import {IRatingsContainerProps} from './RatingsContainer.types'
import {CONSTANTS} from 'src/constants/defaultConstants'

function RatingsContainer(props: IRatingsContainerProps) {
  const {
    overAllRating = 0,
    totalNumberOfReviews = '',
    reviewsText = CONSTANTS.REVIEWS_TEXT,
    ratingsStats = [],
    isMobile = false,
  } = props
  const ratingCompositionArgs = useMemo(() => {
    return {
      overAllRating,
      totalNumberOfReviews,
      isMobile,
      reviewsText,
    }
  }, [overAllRating, totalNumberOfReviews, isMobile, reviewsText])
  const ratingBarsArgs = useMemo(() => {
    return {
      ratingsStats,
      isMobile,
    }
  }, [ratingsStats, isMobile])
  if (!Math.round(overAllRating)) return null

  return (
    <RatingsContainerWrapper data-testid="ratings-container">
      <RatingComposition {...ratingCompositionArgs} />
      <RatingBars {...ratingBarsArgs} />
    </RatingsContainerWrapper>
  )
}

export default RatingsContainer
