import styled from 'styled-components'
import {ICtaWithAnimationStyleProps} from './types'

export const BtnWrapper = styled.button<ICtaWithAnimationStyleProps>`
  background-color: ${({bgColor}) => bgColor};
  padding: 4px 4px 4px 0;
  padding-left: 30px;
  border-radius: 50px;
  border: 1px solid ${({borderColor}) => borderColor};
  color: ${({color}) => color};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  &:focus {
    outline: none !important;
    border: 1px solid ${({borderColor}) => borderColor} !important;
    outline: none !important;
    box-shadow: none !important;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    padding-left: 26px;
  }

  .btn-icon-wrapper {
    height: var(--dls-size-48);
    width: var(--dls-size-48);
    background-color: ${({arrowBgColor}) => arrowBgColor};
    border-radius: 100%;
    padding: var(--dls-size-8);
    position: relative;
    overflow: hidden;

    .arrow-icon-bottom {
      position: absolute;
      top: 42px;
      right: 42px;
    }

    svg {
      position: absolute;
      top: 10px;

      right: 10px;
      transform: translate(0, 0);
      transition: transform 0.4s ease;
    }

    &:hover {
      svg {
        transform: translate(52px, -52px);
      }

      .arrow-icon-bottom {
        transform: translate(-10px, 10px);
        opacity: 1;
      }
    }
  }
`
