import React, {useCallback, useEffect, useMemo} from 'react'
import {useAuth, useUserStore} from '@mosaic-wellness/redux-action-library'
import {
  onMobileNumberSubmit,
  onOtpSubmit,
  onVerifyOTPWithDetailsSubmit,
  onEmailSubmit,
  onVerifyPassword,
} from 'src/utils/authentication'
import {useSessionTransfer} from 'src/hooks/useSessionTransfer'

import dynamic from 'next/dynamic'
import {useGenericCta, useSetPrimaryUtmSource} from 'src/hooks'
import {analyticsTrigger} from 'src/analytics'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {useSocialLogin} from 'src/hooks/useGoogleSocialLogin'
import {CTA_ACTION} from 'src/hooks/useGenericCta'
import useNavigation from 'src/hooks/useNavigation/useNavigation'

const Login = dynamic(
  () =>
    import(
      /* webpackChunkName: "auth" */ '../../components/shared/src/ComponentsV2/Screens/Lj-Login'
    ),
  {
    ssr: false,
  }
)

const LoginContainer = ({loginData}: any) => {
  const {
    loginUser: {
      currentStatus,
      sendOtp,
      verifyOTP,
      verifyOTPWithDetails,
      resendOTP,
      updateUserStatus,
      accountExist,
      verifyPassword,
      forgetPassword,
    },
    user: {isLoggedIn},
  } = useUserStore()

  const {handleCta} = useGenericCta()
  const {
    state: {showLoginModal, status},
    actions: {setStatus, toggleShow},
  } = useAuth()
  const {facebookAppId, handleFacebookCallbackResponse} = useSocialLogin({
    toggleShow,
  })
  const [, {navigateTo}] = useNavigation()

  useSessionTransfer()

  const triggerLoginSeen = useCallback(() => {
    analyticsTrigger(EVENT_MAP.LOGIN, {})
  }, [])

  const triggerSignUpSeen = useCallback(() => {
    analyticsTrigger(EVENT_MAP.SIGN_UP, {})
  }, [])

  const triggerSOLoginSeen = useCallback(() => {
    analyticsTrigger(EVENT_MAP.LOGIN_BOTTOM_SHEET_VIEWED, {})
  }, [])

  const isUserLoggingIn = status === 'login'
  const isUserSigningUp = status === 'signup'

  const isTriggeredFromSO = useMemo(() => {
    const {triggeredFrom = ''} = showLoginModal
    return triggeredFrom === 'SO_LOGIN' || triggeredFrom === 'SO_DOCTOR_LOGIN'
  }, [showLoginModal])

  useEffect(() => {
    if (isUserLoggingIn) {
      triggerLoginSeen()
      if (isTriggeredFromSO) {
        triggerSOLoginSeen()
      }
    }
  }, [triggerLoginSeen, isUserLoggingIn, isTriggeredFromSO, triggerSOLoginSeen])

  useEffect(() => {
    if (isUserSigningUp) triggerSignUpSeen()
  }, [triggerSignUpSeen, isUserSigningUp])

  const handleLogin = useCallback(() => {
    const {redirectTo = ''} = showLoginModal || {}

    if (redirectTo && redirectTo !== '#') {
      handleCta({
        action: CTA_ACTION.APP_NAV,
        link: redirectTo,
      })
    }
  }, [showLoginModal])

  return (
    <Login
      state={{
        showLogin: showLoginModal,
        status,
        currentStatus,
        facebookAppId,
        loginData,
        isLoggedIn,
      }}
      actions={{
        onVerifyOTPWithDetailsSubmit,
        setStatus: setStatus,
        toggleLogin: toggleShow,
        handleLogin: handleLogin,
        getMobileDetails: sendOtp,
        verifyOtp: verifyOTP,
        onOtpResend: resendOTP,
        onMobileNumberSubmit: onMobileNumberSubmit,
        onOtpSubmit: onOtpSubmit,
        navigateTo: navigateTo,
        verifyOTPWithDetails: verifyOTPWithDetails,
        updateUserStatus: updateUserStatus,
        accountExist,
        onEmailSubmit,
        verifyPassword,
        onVerifyPassword,
        forgetPassword,
        handleFacebookCallbackResponse,
      }}
    />
  )
}

export default LoginContainer
