import {useUserStore} from '@mosaic-wellness/redux-action-library'
import {useCallback, useEffect} from 'react'
import {logError} from 'src/utils/logger'

import {create} from 'zustand'
import {
  extractLastReadUtmParamsFromStorage,
  extractLastTrackedUtmParamsFromStorage,
  resetLastReadUtmParamsInStorage,
  setLastReadUtmParamsInStorage,
  storeLastTrackedUtmParamsInStorage,
} from './utils'
import {reportUtmParams} from './reportUtmParams'
import isEmpty from 'lodash/isEmpty'
import {reportWishlinkPixel} from './reportWishlinkPixel'
import {pushToDataLayer} from 'src/analytics'

type UtmParamsStore = {
  paramsFromUrl: Record<string, any>
  paramsHydrated: boolean
}

const useUtmParamsStore = create<UtmParamsStore>()((set) => ({
  paramsHydrated: false,
  paramsFromUrl: {},
}))

const setParamsFromUrl = (params: Record<string, any>) => {
  useUtmParamsStore.setState({paramsFromUrl: params, paramsHydrated: true})
}

const resetParamsFromUrl = () => {
  useUtmParamsStore.setState({paramsFromUrl: {}})
}

/**
 * ? WHY all of this?
 * we would want to attribute user interaction to the traffic source even if user abandons a session and,
 * returns to the site later with in a pre-specified time period - currently 7 Days
 */
export const useUTM = () => {
  const {
    user: {isLoggedIn},
  } = useUserStore()
  const paramsHydrated = useUtmParamsStore((store) => store.paramsHydrated)

  const handleReadParamsFromUrl = useCallback(async () => {
    try {
      let paramsToUser = extractLastReadUtmParamsFromStorage()

      if (!paramsToUser || isEmpty(paramsToUser)) {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const utmSource = urlParams.get('utm_source')
        const utmCampaign = urlParams.get('utm_campaign')
        const utmMedium = urlParams.get('utm_medium')
        const utmTerm = urlParams.get('utm_term')
        const atgSessionId = urlParams.get('atgSessionId')
        const fbclid = urlParams.get('fbclid')
        const gclid = urlParams.get('gclid')
        const creatorId = urlParams.get('creator_id')
        const linkId = urlParams.get('link_id')
        const siteName = urlParams.get('site_name')
        const influencerId = urlParams.get('influencer_id')
        const dmId = urlParams.get('dm_id')
        const ref = urlParams.get('ref')

        paramsToUser = {
          ...(utmSource ? {utmSource} : {}),
          ...(utmCampaign ? {utmCampaign} : {}),
          ...(utmMedium ? {utmMedium} : {}),
          ...(utmTerm ? {utmTerm} : {}),
          ...(atgSessionId ? {atgSessionId} : {}),
          ...(fbclid ? {fbclid} : {}),
          ...(gclid ? {gclid} : {}),
          ...(creatorId ? {creatorId} : {}),
          ...(linkId ? {linkId} : {}),
          ...(siteName ? {siteName} : {}),
          ...(influencerId ? {influencerId} : {}),
          ...(dmId ? {dmId} : {}),
          ...(ref ? {ref} : {}),
        }
        if (paramsToUser) {
          setLastReadUtmParamsInStorage(paramsToUser)
        }
      }

      setParamsFromUrl(paramsToUser)

      pushToDataLayer({utmParams: paramsToUser})
    } catch (error) {
      logError(error as Error, {
        location: 'useUTM.ts',
        source: 'Error while reading UTM params',
      })
    }
  }, [])

  useEffect(() => {
    handleReadParamsFromUrl()
  }, [handleReadParamsFromUrl])

  const getParamsToBeTracked = useCallback(() => {
    const paramsReadFromUrl = useUtmParamsStore.getState().paramsFromUrl
    const lastTrackedParams = extractLastTrackedUtmParamsFromStorage()

    if (lastTrackedParams) {
      // * If lastTrackedParams exists that implies that user is still in the attribution window.
      // * In this case there is nothing to be tracked.
      return null
    } else {
      const {
        creatorId,
        utmSource,
        utmCampaign,
        utmMedium,
        siteName,
        linkId,
        dmId,
        influencerId,
        ...restOfParamsReadFromUrl
      } = paramsReadFromUrl

      const utmSourceExists = !!utmSource

      const isTrackier = utmSource === 'trackier'

      if (utmSourceExists) {
        // case of some utm_source
        const finalParamsToBeRecorded = {
          ...restOfParamsReadFromUrl,
          utm_source: utmSource,
          utm_campaign: utmCampaign,
          utm_medium: utmMedium,
          ...(dmId && {dm_id: dmId}),
          ...(influencerId && {influencer_id: influencerId}),
          ...(isTrackier &&
            paramsReadFromUrl.clickID && {
              clickId: paramsReadFromUrl.clickID,
            }),
          ...(siteName === 'hypd'
            ? {hypd_creator_id: creatorId}
            : {creator_id: creatorId}),
          ...(siteName === 'hypd' ? {hypd_link_id: linkId} : {link_id: linkId}),
        }

        return finalParamsToBeRecorded
      } else {
        // case of organic
        return {}
      }
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn && paramsHydrated) {
      const finalParamsToBeRecorded = getParamsToBeTracked()

      if (!finalParamsToBeRecorded) {
        return
      } else {
        reportUtmParams(finalParamsToBeRecorded)
        resetLastReadUtmParamsInStorage()
        resetParamsFromUrl()
        storeLastTrackedUtmParamsInStorage(finalParamsToBeRecorded)
      }
    }
  }, [getParamsToBeTracked, isLoggedIn, paramsHydrated])

  useEffect(() => {
    if (!paramsHydrated) {
      const paramsBeingTracked = useUtmParamsStore.getState().paramsFromUrl
      if (paramsBeingTracked && paramsBeingTracked.utmSource === 'wishlink') {
        reportWishlinkPixel(paramsBeingTracked)
      }
    }
  })
}
