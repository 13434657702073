import {
  BaseWidget,
  BaseWidgetProps,
  GenericAction,
} from '@mosaic-wellness/fe-types'
import React from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {BrickSectionWrapper} from './BrickSection.styles'
import {
  OptimizedImage,
  Typography,
  TypographyVariants,
  CtaWithAnimation,
  Media,
} from '@web-components'
import {useGenericActions} from '../../hooks'
import {BrickSectionProps} from './BrickSection.types'

const brickVariant = {
  PRIMARY: {
    backGroundColor: 'var(--dls-brand-primary-color-500)',
    textColor: 'var(--dls-white-color)',
    ctaVariant: 'TERTIARY',
  },
  SECONDARY: {
    backGroundColor: 'transparent',
    textColor: 'var(--dls-brand-primary-color-500)',
    ctaVariant: 'PRIMARY',
  },
}

const BrickSection = (props: BaseWidgetProps<BrickSectionProps>) => {
  const {id, layout, widgetData, type} = props
  const {
    title = '',
    mediaData = {
      mediaType: 'image',
      media: {source: '', altText: ''},
      aspectRatio: 1,
    },
    cta = {label: '', action: [] as GenericAction[]},
    heading = '',
    items = [],
    variant = 'PRIMARY',
  } = widgetData || {}

  const {
    mediaType = 'image',
    media = {source: '', altText: ''},
    aspectRatio = 1,
  } = mediaData || {}

  const styles = brickVariant[variant] || brickVariant.PRIMARY

  const {handleGenericActions} = useGenericActions()

  const handleCtaClick = (action: GenericAction[]) => {
    if (action) {
      handleGenericActions(action)
    }
  }

  return (
    <BaseWidgetComponent widgetType={type} widgetId={id} layout={layout}>
      <BrickSectionWrapper
        bgColor={styles.backGroundColor}
        color={styles.textColor}
      >
        <div className="brick-container">
          <div className="content">
            <Typography
              variant={TypographyVariants.TAG_REGULAR}
              customClassName="title"
            >
              {title}
            </Typography>
            <Typography variant={TypographyVariants.HEADING_LARGE_REGULAR}>
              <span dangerouslySetInnerHTML={{__html: heading}} />
            </Typography>
            <div className="items-wrapper">
              {items.map((item: any, index: number) => (
                <div key={index} className="item">
                  <OptimizedImage
                    source={item.icon?.source}
                    altText={item.icon?.altText}
                    customClassName="tick-icon"
                  />
                  <Typography
                    variant={TypographyVariants.TAG_REGULAR}
                    customClassName="item-text"
                  >
                    {item.label}
                  </Typography>
                </div>
              ))}
            </div>
            <CtaWithAnimation
              onClick={() => handleCtaClick(cta.action)}
              variant={styles.ctaVariant}
            >
              {cta.label}
            </CtaWithAnimation>
          </div>
          <div className="media">
            <Media
              media={media}
              mediaType={mediaType}
              customClassName="media-content"
              aspectRatio={aspectRatio}
            />
          </div>
        </div>
      </BrickSectionWrapper>
    </BaseWidgetComponent>
  )
}

export default BrickSection
