import {useUserStore} from '@mosaic-wellness/redux-action-library'
import {useCallback, useEffect} from 'react'
import {IUseSocialLogin} from './useSocialLogin.interface'
import {SocialPlatform} from 'src/utils/SocialPlatform'
import {CLIENT_ENV} from 'src/constants/clientEnv'

const GOOGLE_CLIENT_ID = CLIENT_ENV.NEXT_PUBLIC_GS_ID || ''
const FACEBOOK_CLIENT_ID = CLIENT_ENV.NEXT_PUBLIC_FB_ID

export const useSocialLogin = ({toggleShow}: IUseSocialLogin) => {
  const {
    loginUser: {socialLogin},
  } = useUserStore()

  const handleCallbackResponse = useCallback(
    async (response: any) => {
      const socialToken = response.credential
      const socialPlatform = SocialPlatform['google']
      const res = await socialLogin(socialToken, socialPlatform)
      const emailValid = res?.data?.isEmailValid
      if (emailValid) {
        toggleShow()
      }
    },
    [socialLogin, toggleShow]
  )

  const handleFacebookCallbackResponse = useCallback(
    async (response: any) => {
      const socialToken = response?.accessToken
      const socialPlatform = SocialPlatform['facebook']
      const res = await socialLogin(socialToken, socialPlatform)
      const emailValid = res?.data?.isEmailValid
      if (emailValid) {
        toggleShow()
      }
    },
    [socialLogin, toggleShow]
  )

  useEffect(() => {
    /* global google */
    if (GOOGLE_CLIENT_ID) {
      window?.google?.accounts?.id?.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleCallbackResponse,
      })
      window?.google?.accounts?.id?.renderButton(
        document.getElementById('google-login-div'),
        {theme: 'outline', size: 'large'}
      )
    }
  }, [handleCallbackResponse])

  return {
    facebookAppId: FACEBOOK_CLIENT_ID,
    handleFacebookCallbackResponse,
  }
}
