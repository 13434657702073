import isEmpty from 'lodash/isEmpty'
import {ISlotData} from './useGetSlotsData.interface'
import {logError} from 'src/utils/logger'

export const mapSlots = (slotData: ISlotData, isLsd = false) => {
  const dateData: Record<string, any> = {}
  const indexToMap = isLsd ? 0 : 1

  try {
    if (!isEmpty(slotData)) {
      const {selectDoctor} = slotData || {}
      const {data} = selectDoctor || {}

      if (data && !isEmpty(data[indexToMap])) {
        const {selectAvailable} = data[indexToMap] || {}

        const {data: availableData} = selectAvailable || []

        if (availableData && !isEmpty(availableData[1])) {
          const {data: dataToProcess = []} = availableData[1] || {}

          dataToProcess.forEach((item) => {
            const timeData: Record<string, any> = {}
            const {times = [], id = ''} = item || {}

            times.forEach((item) => {
              const {time = '', data} = item || {}
              const {slots = []} = data || {}

              if (time) {
                timeData[item.time] = slots
              }
            })

            if (id) {
              dateData[item.id] = timeData
            }
          })

          return dateData
        }
      }
    }

    return {}
  } catch (err) {
    logError(err as Error)
  }

  return dateData
}
