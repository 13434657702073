import {CLIENT_ENV} from 'src/constants/clientEnv'
import mixpanel from 'mixpanel-browser'

const MIX_PANEL_TOKEN = CLIENT_ENV.NEXT_PUBLIC_MIX_PANEL_TOKEN || ''

/* Explicitly set a user property on mixpanel */
export const updateUserForMixPanel = ({key = '', value = ''}) => {
  if (MIX_PANEL_TOKEN) {
    mixpanel.people.set({[key]: value})
  }
}
