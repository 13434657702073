import React from 'react'
import {OptimizedImage, OptimizedVideo, LottiePlayer} from '../../atomic'
import {MediaWrapper} from './Media.styles'
import {MediaProps, IImage, IVideo, ILottie} from './Media.interface'
import isEmpty from 'lodash/isEmpty'

const DEFAULT_IMAGE_WIDTH = 360

const Media = React.memo(
  ({mediaType, media, aspectRatio = 1, customClassName = ''}: MediaProps) => {
    if (!mediaType && isEmpty(media)) return null

    return (
      <MediaWrapper
        aspectRatio={aspectRatio}
        className="media-wrapper"
        data-testid="media-wrapper"
      >
        {mediaType === 'image' && (
          <OptimizedImage
            source={(media as IImage).source}
            aspectWidth={DEFAULT_IMAGE_WIDTH}
            aspectHeight={DEFAULT_IMAGE_WIDTH / aspectRatio}
            desktopViewWidth={(media as IImage).desktopViewWidth ?? 'FULL'}
            mobileViewWidth={(media as IImage).mobileViewWidth ?? 'FULL'}
            loading={(media as IImage).loading ?? 'lazy'}
            altText={(media as IImage).altText}
            customClassName={customClassName}
          />
        )}
        {mediaType === 'video' && (
          <OptimizedVideo
            state={{
              source: (media as IVideo).source,
              autoplay: (media as IVideo).autoplay ?? true,
              loop: (media as IVideo).loop ?? true,
              muted: (media as IVideo).muted ?? true,
              controls: (media as IVideo).controls ?? false,
              posterImage: (media as IVideo).posterImage,
              customClassName,
            }}
          />
        )}
        {mediaType === 'lottie' && (
          <LottiePlayer
            src={(media as ILottie).source}
            autoplay={(media as ILottie).autoplay ?? true}
            loop={(media as ILottie).loop ?? true}
            style={{width: '100%', height: 'auto'}}
            className={customClassName}
          />
        )}
      </MediaWrapper>
    )
  }
)

export default Media
