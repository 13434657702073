import styled from 'styled-components'

export const ProductSwitchContainer = styled.div`
  width: 100%;
  border: 1px solid var(--dls-divider-light-color);
  border-radius: 12px;
  padding: var(--dls-size-16);

  .subheading-bold {
    text-align: center;
  }

  .title {
    color: var(--dls-category-primary-color-500);
  }

  .cta-grid-container {
    max-width: min-content;
    margin: auto;
  }

  .cta-grid {
    display: flex;
    gap: var(--dls-size-16);
    align-items: flex-start;
    margin-top: var(--dls-size-16);
    overflow-x: auto;
    padding-bottom: var(--dls-size-8);
    justify-content: flex-start;

    -webkit-overflow-scrolling: touch; /* Needed to work on iOS Safari */
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .cta-grid-item {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-8);
    align-items: center;
    flex-shrink: 0;
    max-width: min-content;

    &[data-isselected='true'] {
      .image-container {
        background-color: var(--dls-category-primary-color-100);
        border: 1px solid var(--dls-category-primary-color-500);
      }
    }
  }

  .image-container {
    display: flex;
    border-radius: var(--dls-size-8);
    overflow: hidden;
    padding: 10px;
    background-color: var(--dls-backgound-color-100);
  }

  &[data-itemstyle='EXPANDED'] {
    .image-container {
      border: 1px solid var(--dls-divider-light-color);
      padding: 0px;
    }
  }

  .cta-image {
    object-fit: cover;
  }

  .cta-label {
    flex-shrink: 0;
  }
`
