import styled from 'styled-components'

export const MediaWrapper = styled.div<{aspectRatio?: number}>`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  ${({aspectRatio}) =>
    aspectRatio &&
    `
    aspect-ratio: ${aspectRatio};
  `}
  img {
    width: 100%;
    height: auto;
    ${({aspectRatio}) =>
      aspectRatio &&
      `
    aspect-ratio: ${aspectRatio};
  `}
    object-fit: contain;
  }
  video {
    width: 100%;
    height: auto;
    ${({aspectRatio}) =>
      aspectRatio &&
      `
    aspect-ratio: ${aspectRatio};
  `}
    object-fit: cover;
  }
`
