import React from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {BaseWidget, GenericAction} from '@mosaic-wellness/fe-types'
import {MediaWithProgressSliderWrapper} from './MediaWithProgressSlider.styles'
import {MediaWithProgress} from './MediaWithProgress'
import {
  CtaWithAnimation,
  GenericCarousel,
  useDeviceTypeContext,
} from '@web-components'
import {useGenericActions} from '../../hooks'
import {TitleWithLabel} from '../TitleWithLabel'

const MediaWithProgressSlider = (props: BaseWidget) => {
  const {widgetData, type, id, layout} = props
  const {title = '', label = '', items = [], bottomCta = {}} = widgetData || {}
  const {isMobile = false} = useDeviceTypeContext()
  const {handleGenericActions} = useGenericActions()

  const handleCtaClick = (action: GenericAction[]) => {
    if (action) {
      handleGenericActions(action)
    }
  }

  return (
    <BaseWidgetComponent widgetType={type} widgetId={id} layout={layout}>
      <MediaWithProgressSliderWrapper>
        <TitleWithLabel title={title} label={label} color="white" />
        <GenericCarousel
          Component={MediaWithProgress}
          cardData={items}
          slidesToShow={isMobile ? 1 : 2}
          showDots={false}
          isAutoSlide={true}
          autoSlideInterval={4000}
        />
        <div className="bottom-cta">
          <CtaWithAnimation
            onClick={() => handleCtaClick(bottomCta.action)}
            variant="TERTIARY"
          >
            {bottomCta.label}
          </CtaWithAnimation>
        </div>
      </MediaWithProgressSliderWrapper>
    </BaseWidgetComponent>
  )
}

export default MediaWithProgressSlider
