import {usePost} from '@mosaic-wellness/core-utils'
import {useUserStore} from '@mosaic-wellness/redux-action-library'
import {useCallback, useMemo} from 'react'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {IGlobalResponse} from 'src/interfaces/globalResponse.interface'
import {ICancelAppointmentPayload} from './useCancelAppointment.interface'
import {toast} from 'src/toast'

function useCancelAppointment() {
  const {
    user: {id},
  } = useUserStore()

  const {isLoading, mutate, data, reset} = usePost<
    IGlobalResponse<any>,
    any,
    ICancelAppointmentPayload
  >({
    url: API_END_POINTS.CANCEL_APPOINTMENT,
  })

  const payloadStaticData = useMemo(() => {
    return {
      patientId: id,
    }
  }, [id])

  const cancelAppointment = useCallback(
    (cancelAppointmentPayload: ICancelAppointmentPayload) => {
      mutate(
        {...cancelAppointmentPayload, ...payloadStaticData},
        {
          onError: () => {
            toast(
              'warning',
              'Oops! Some Server issue please try again after some time'
            )
          },
        }
      )
    },
    [mutate, payloadStaticData]
  )

  const resetCancelAppointmentData = useCallback(() => {
    reset()
  }, [reset])

  return [
    {
      isCancelled: data?.data?.status ? true : null,
      isLoading,
    },
    {
      cancelAppointment,
      resetCancelAppointmentData,
    },
  ] as const
}

export {useCancelAppointment}
