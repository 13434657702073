import {HttpClient, useConfigStore} from '@mosaic-wellness/redux-action-library'
import {useCallback, useState} from 'react'
import {logError} from 'src/utils/logger'

export const CouponQueryParamsKeys = {
  utmCampaign: 'utm_campaign',
  utmSource: 'utm_source',
  utmMedium: 'utm_medium',
}

let GET_ALL_COUPONS = 'offers/cart'
export async function getQueryParamsValue(storage: any, key: string) {
  try {
    const source = await storage.getItem(key)
    if (source) {
      const sourceObj = JSON.parse(source)
      const sourceValue = sourceObj.value
      return sourceValue
    }
  } catch (error) {
    logError(error as Error, {
      location: 'Apply Coupons',
    })
  }
}

export const useGetAvailableCoupons = ({showWalletCouponsOnTop = true}) => {
  const [couponDataRes, setCouponDataRes] = useState({})
  const {storage} = useConfigStore()
  const axiosInstance = HttpClient.getAxiosInstance()
  const getData = useCallback(
    async function getData() {
      try {
        const response = await axiosInstance.get(GET_ALL_COUPONS, {
          params: {showWalletCouponsOnTop},
        })
        const {data} = response?.data || {}
        if (data) {
          setCouponDataRes(data)
        }
      } catch (error) {
        setCouponDataRes({})
      }
    },
    [axiosInstance, showWalletCouponsOnTop]
  )

  return {couponDataRes, getData}
}
