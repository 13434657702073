import {create} from 'zustand'

interface experiment {
  key: string
  value: boolean | string
}

interface clientSideExperimentsStore {
  experimentsList: Record<string, boolean | string> | null
  updateEnableExperimentsList: (experiments: experiment[]) => void
}

export const useClientSideExperimentsStore =
  create<clientSideExperimentsStore>()((set) => ({
    experimentsList: null,
    updateEnableExperimentsList: (updateExperiment: experiment[]) =>
      set((state) => ({
        experimentsList: {
          ...(state?.experimentsList ?? {}),
          ...Object.fromEntries(
            updateExperiment.map((experiment) => [
              experiment?.key || '',
              experiment?.value || '',
            ])
          ),
        },
      })),
  }))
