import {BaseWidget} from '@mosaic-wellness/fe-types'
import React from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {MediaInfoSliderWrapper} from './MediaWithInfoSlider.styles'
import {GenericCarousel} from '@web-components'
import MediaWithInfo from './MediaWithInfo/MediaWithInfo'
import {TitleWithLabel} from '../TitleWithLabel'

const MediaWithInfoSlider = (props: BaseWidget) => {
  const {type, id, layout, widgetData} = props

  const {title = '', label = ' ', items = []} = widgetData || {}
  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <MediaInfoSliderWrapper>
        <TitleWithLabel
          title={title}
          label={label}
          color="var(--dls-white-color)"
        />
        <GenericCarousel
          cardData={items}
          Component={MediaWithInfo}
          showDots={false}
          isAutoSlide={true}
        />
      </MediaInfoSliderWrapper>
    </BaseWidgetComponent>
  )
}

export default MediaWithInfoSlider
