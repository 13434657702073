import {HttpClient} from '@mosaic-wellness/redux-action-library'
import {IGlobalResponse} from '@mosaic-wellness/redux-action-library/build/interfaces/root'
import isEmpty from 'lodash/isEmpty'
import {useCallback, useEffect} from 'react'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {logError} from 'src/utils/logger'
import {IGetRegionDataResponse} from './useLogUsersLocation.interface'
import {LOCAL_STORAGE_CONSTANTS, localStorageMethods} from 'src/utils'

import {updateUserForMixPanel} from 'src/analytics/mixPanel'

function useLogUsersLocation() {
  const logUsersLocation = useCallback(async () => {
    const axiosInstance = HttpClient.getAxiosInstance()
    try {
      const {data} = await axiosInstance.get<
        IGlobalResponse<IGetRegionDataResponse>
      >(API_END_POINTS.GET_REGION_DATA)

      if (!isEmpty(data.data)) {
        const {userPincode = '', regionData} = data.data
        const {city = '', state = ''} = regionData || {}

        const usersLocation = await localStorageMethods.get(
          LOCAL_STORAGE_CONSTANTS.USERS_LOCATION
        )

        const hasCityOrStateChanged =
          usersLocation?.city?.toLowerCase() !== city.toLowerCase()

        if (hasCityOrStateChanged) {
          const locationData = {
            pincode: userPincode,
            city,
            state,
          }
          /* Commenting for now as property not reflecting on CT, will debug later */
          // updateUserForClevertap({key: 'user_city', value: locationData.city})
          // updateUserForClevertap({key: 'user_state', value: locationData.state})
          // updateUserForClevertap({
          //   key: 'user_pincode',
          //   value: locationData.pincode,
          // })
          updateUserForMixPanel({key: 'user_city', value: locationData.city})
          updateUserForMixPanel({key: 'user_state', value: locationData.state})
          updateUserForMixPanel({
            key: 'user_pincode',
            value: locationData.pincode,
          })

          localStorageMethods.set({
            keyName: LOCAL_STORAGE_CONSTANTS.USERS_LOCATION,
            value: locationData,
          })
        }
      }
    } catch (error) {
      logError(error as Error)
    }
  }, [])

  useEffect(() => {
    logUsersLocation()
  }, [logUsersLocation])
}

export default useLogUsersLocation
