import {useEffect, useMemo} from 'react'
import {useFetch} from 'src/hooks/api/useFetch'

import {CLIENT_ENV} from 'src/constants/clientEnv'
import {IMiniProductPageBottomSheetProps} from './MiniProductPageBottomSheet.interface'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {analytics} from 'src/analytics'

export const useMiniProductPageBottomSheet = ({
  state,
  actions,
}: IMiniProductPageBottomSheetProps) => {
  const {urlKey = '', cta, source = '', name = ''} = state || {}
  const {handleCloseModal = () => {}} = actions || {}
  const {isLoading, data} = useFetch({
    queryKey: `product-data-${urlKey}`,
    url: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}page/mwsc/product/${urlKey}?theme=rcl`,
    disabled: !Boolean(urlKey),
    cacheTime: 10000,
  })

  const {data: pageData} = data?.data || {}
  const {
    productInfo,
    sections,
    description = {},
    imageGallery = [],
  } = pageData || {}

  const {isDiscontinuedProduct = false, outOfStock = false} = productInfo || {}
  const {howItWorks = {}, ingredients = {}, weGotAnswers = {}} = sections || {}

  const ratingAndReviewsData = useMemo(
    () => ({
      ...sections?.reviews,
      rating: pageData?.rating,
      productId: productInfo?.id,
      viewAllReviewsCTA: () => {},
    }),
    [pageData, productInfo, sections]
  )

  useEffect(() => {
    analytics.trigger(EVENT_MAP.PRODUCT_INFO_CLICKED, {
      'Product Id': urlKey,
      source,
      'Product Name': name,
    })
  }, [])

  return {
    urlKey,
    showModal: state?.showModal || false,
    isLoading,
    handleCloseModal,
    imageGallery,
    productInfo,
    isDiscontinuedProduct,
    outOfStock,
    description,
    howItWorks,
    ingredients,
    weGotAnswers,
    ratingAndReviewsData,
    cta,
  }
}
