import React, {useCallback, useState} from 'react'
import {AccordionItemContainer} from './AccordionItem.styles'
import {IAccordionItemProps} from './AccordionItem.interface'
import {
  ChevronDown,
  ChevronUp,
  Typography,
  TypographyVariants,
  PlusIcon,
  MinusIcon,
  OptimizedImage,
} from '@web-components'

function AccordionItem(props: IAccordionItemProps) {
  const {
    content = '',
    title = '',
    initiallyOpen = false,
    image = '',
    isPlusIcon,
    titleFontWeight,
    customContainerClassName = '',
    titleVariant = TypographyVariants.BODY_BASE_BOLD,
    children,
  } = props || {}

  const [isOpen, setIsOpen] = useState(initiallyOpen)
  const [isAnimating, setIsAnimating] = useState(false)

  const handleAccordionClick = useCallback(() => {
    setIsAnimating(true)
    setTimeout(() => {
      setIsOpen((flag) => !flag)
      setIsAnimating(false)
    }, 300)
  }, [])

  if (!title) {
    return null
  }

  return (
    <AccordionItemContainer
      className={`accordion-item ${customContainerClassName}`}
      data-isopen={isOpen}
      data-isanimating={isAnimating}
    >
      <div onClick={handleAccordionClick}>
        <div className="title-container">
          {image && (
            <div className="title-image">
              <OptimizedImage source={image} />
            </div>
          )}

          <Typography
            variant={
              titleFontWeight === 'light'
                ? TypographyVariants.BODY_BASE_LIGHT
                : titleVariant
            }
            customClassName="title-text"
          >
            {title}
          </Typography>
          <div className="chevron-icon">
            {isPlusIcon ? (
              <div
                className={`icon-container ${isAnimating ? 'animating' : ''}`}
              >
                {isOpen ? <MinusIcon size="36" /> : <PlusIcon size="36" />}
              </div>
            ) : isOpen ? (
              <ChevronUp />
            ) : (
              <ChevronDown />
            )}
          </div>
        </div>

        {content && (
          <Typography
            variant={TypographyVariants.BODY_BASE_REGULAR}
            customClassName="content-text"
          >
            {content}
          </Typography>
        )}
        {children && isOpen && <>{children}</>}
      </div>
    </AccordionItemContainer>
  )
}

export default AccordionItem
