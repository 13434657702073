import styled from 'styled-components'

export const ReviewWithImageWrapper = styled.div<{bgImage: string}>`
  display: flex;
  flex-direction: column;
  padding: var(--dls-size-24);
  padding-top: var(--dls-size-64);
  background-image: url(${({bgImage}) => bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--dls-white-color);
  height: 650px;
  border-radius: var(--dls-size-24);
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 350px;
    height: 550px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    border-radius: var(--dls-size-24);
  }

  .quotation-mark {
    width: 20%;
    position: relative;
    z-index: 2;
  }
  .review-content-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--dls-size-24);
    z-index: 2;
  }
  .review-content-info {
    padding: var(--dls-size-16) 0;
  }
  .media-info-divider {
    height: 1px;
    background-color: white;
    width: 100%;
  }
  .review-info-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: var(--dls-size-72);
    padding: var(--dls-size-8) 0;
  }
  .review-info-name-title {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-8);
    flex-wrap: nowrap;
  }

  .rating {
    display: flex;
    align-items: end;
  }
`
