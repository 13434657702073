import styled from 'styled-components'

export const MediaWithProgressSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--dls-brand-primary-color-500);
  color: var(--dls-white-color);
  gap: var(--dls-size-48);
  padding: var(--dls-size-32) 0;

  .bottom-cta {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
`
