import isEmpty from 'lodash/isEmpty'

export const getShouldHideNextButtonFlag = (
  currentQuestion: Record<string, any>
) => {
  if (isEmpty(currentQuestion)) {
    return false
  }

  return (
    currentQuestion?.input === 'radio' ||
    currentQuestion?.input === 'text' ||
    currentQuestion?.uiType === 'START_ASSESSMENT' ||
    currentQuestion?.uiType === 'IMAGE_UPLOAD'
  )
}
