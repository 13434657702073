import styled from 'styled-components'

export const CircularMediaSliderWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
