import {docCookies} from 'src/utils/cookie'

export const extractLastTrackedUtmParamsFromStorage = () => {
  const lastTrackedParams = docCookies.getItem({
    cookieName: 'lastTrackedUtmParams',
    skipDomain: false,
  })
  if (lastTrackedParams) {
    return JSON.parse(lastTrackedParams)
  }

  const utmSource = docCookies.getItem({
    cookieName: 'utm_source',
    skipDomain: false,
  })
  const utmCampaign = docCookies.getItem({
    cookieName: 'utm_campaign',
    skipDomain: false,
  })
  const utmMedium = docCookies.getItem({
    cookieName: 'utm_medium',
    skipDomain: false,
  })
  const clickId = docCookies.getItem({
    cookieName: 'click_id',
    skipDomain: false,
  })

  if (utmSource) {
    return {
      ...(utmSource && {utmSource}),
      ...(utmCampaign && {utmCampaign}),
      ...(utmMedium && {utmMedium}),
      ...(clickId && {clickId}),
    }
  }
  return null
}
export const extractLastReadUtmParamsFromStorage = () => {
  const lastReadParams = docCookies.getItem({
    cookieName: 'lastReadUtmParams',
    skipDomain: false,
  })
  if (lastReadParams) {
    return JSON.parse(lastReadParams)
  }
  return null
}

export const setLastReadUtmParamsInStorage = (params: Record<string, any>) => {
  docCookies.setItem({
    cookieName: 'lastReadUtmParams',
    cookieValue: JSON.stringify(params ? params : '{}') ?? '{}',
    expiry: 7 * 24 * 60 * 60,
    skipDomain: false,
    cookiePath: '/',
  })
}
export const resetLastReadUtmParamsInStorage = () => {
  docCookies.removeItem({
    cookieName: 'lastReadUtmParams',
    skipDomain: false,
  })
}

export const storeLastTrackedUtmParamsInStorage = (
  params: Record<string, any>
) => {
  docCookies.setItem({
    cookieName: 'lastTrackedUtmParams',
    cookieValue: JSON.stringify(params ? params : '{}') ?? '{}',
    expiry: 7 * 24 * 60 * 60,
    skipDomain: false,
    cookiePath: '/',
  })
  docCookies.setItem({
    cookieName: 'utm_source',
    cookieValue: params.utmSource ?? '',
    expiry: 7 * 24 * 60 * 60,
    skipDomain: false,
    cookiePath: '/',
  })
  docCookies.setItem({
    cookieName: 'utm_campaign',
    cookieValue: params.utmCampaign ?? '',
    expiry: 7 * 24 * 60 * 60,
    skipDomain: false,
    cookiePath: '/',
  })
  docCookies.setItem({
    cookieName: 'utm_medium',
    cookieValue: params.utmMedium ?? '',
    expiry: 7 * 24 * 60 * 60,
    skipDomain: false,
    cookiePath: '/',
  })
  docCookies.setItem({
    cookieName: 'click_id',
    cookieValue: params.clickId ?? '',
    expiry: 7 * 24 * 60 * 60,
    skipDomain: false,
    cookiePath: '/',
  })
  docCookies.setItem({
    cookieName: 'influencer_id',
    cookieValue: params.influencerId ?? '',
    expiry: 7 * 24 * 60 * 60,
    skipDomain: false,
    cookiePath: '/',
  })
  docCookies.setItem({
    cookieName: 'dm_id',
    cookieValue: params.dmId ?? '',
    expiry: 7 * 24 * 60 * 60,
    skipDomain: false,
    cookiePath: '/',
  })
  docCookies.setItem({
    cookieName: 'ref',
    cookieValue: params.ref ?? '',
    expiry: 7 * 24 * 60 * 60,
    skipDomain: false,
    cookiePath: '/',
  })
}

export const getIsLoggedInFromCookie = () => {
  const isLoggedIn = docCookies.getItem({
    cookieName: 'isLoggedIn',
    skipDomain: false,
  })

  return isLoggedIn === '1'
}
