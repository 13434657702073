import {useUserStore} from '@mosaic-wellness/redux-action-library'
import isEmpty from 'lodash/isEmpty'
import {useCallback, useEffect, useRef} from 'react'
import {pushToDataLayer} from 'src/analytics'
import {useClientSideExperimentsStore} from 'src/stores/clientSideExperimentsStore/useClientSideExperimentsStore.store'
import {
  getRandomIdFromCookie,
  validateExperimentalId,
} from 'src/utils/getOrSetCookie'

const useInitializeClientSideExperiments = () => {
  const updateEnableExperimentsList = useClientSideExperimentsStore(
    (state) => state.updateEnableExperimentsList
  )

  const {
    user: {isLoggedIn},
  } = useUserStore()

  const isCookieBeingFetched = useRef(false)

  const updateDataLayerAndStore = useCallback(
    async (experiments: Array<string>, value: boolean | string) => {
      await experiments?.map(async (experimentKey) => {
        if (typeof experimentKey === 'string') {
          await pushToDataLayer({
            clientSideExperiments: {
              ...(window.customDataLayer?.clientSideExperiments ?? {}),
              [experimentKey]: value,
            },
          })
          updateEnableExperimentsList([
            {
              key: experimentKey,
              value: value,
            },
          ])
        }
      })
    },
    [updateEnableExperimentsList]
  )

  const initializeClientSideExperiments = useCallback(
    async (cookieName: string, experiments: Array<string>) => {
      if (!isCookieBeingFetched.current) {
        isCookieBeingFetched.current = true
        const randomId = await getRandomIdFromCookie(cookieName)
        if (!isEmpty(randomId)) {
          const enabledExperiment = await validateExperimentalId(randomId)
          await updateDataLayerAndStore(experiments, enabledExperiment)
          isCookieBeingFetched.current = false
          return enabledExperiment
        }
        isCookieBeingFetched.current = false
      }
      return false
    },
    [updateDataLayerAndStore]
  )

  useEffect(() => {
    !isLoggedIn &&
      initializeClientSideExperiments('random_experiment_id', [
        'poweredByGokwikShown',
      ])
  }, [isLoggedIn])
}

export default useInitializeClientSideExperiments
