import {useCallback, useState} from 'react'

import {
  IImageUploadDataRequestBody,
  IUploadImages,
} from './useUploadImages.interface'
import {IGlobalResponse} from 'src/interfaces/globalResponse.interface'
import {postResource} from '@mosaic-wellness/core-utils'
import {API_END_POINTS} from 'src/constants/apiEndPoints'

function useUploadImages() {
  const [uploaded, setUploaded] = useState(null)
  const [uploadError, setUploadError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const imageUpload = useCallback(
    async (
      args: IImageUploadDataRequestBody
    ): Promise<IGlobalResponse<IUploadImages>> => {
      setIsLoading(true)
      const {formData, scheduleToken = '', mwIDToken = ''} = args || {}
      const params = new URLSearchParams(window.location.search)
      const paramsObject = Object.fromEntries(params.entries())
      try {
        const data = await postResource(
          API_END_POINTS.UPLOAD_IMAGES,
          formData,
          {
            params: {
              scheduleToken,
              mwuid: mwIDToken,
              ...paramsObject,
            },
            maxBodyLength: Infinity,
            maxContentLength: Infinity,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        if (data) {
          setUploaded(data.data)
          setUploadError(false)
          setIsLoading(false)
        }
        return data
      } catch (err: any) {
        setUploadError(err)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  return [
    {
      uploaded,
      uploadError,
      isLoading,
    },
    {
      imageUpload,
    },
  ] as const
}

export {useUploadImages}
