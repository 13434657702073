import React from 'react'
import {
  BaseWidget,
  BaseWidgetProps,
  GenericAction,
} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {
  RLProductSliderWrapper,
  RLProductSliderContainer,
} from './CatalogCardSlider.styles'
import {CatalogCard} from './CatalogCard'
import {ICatalogCard} from './CatalogCard/CatalogCard.types'
import {CtaWithAnimation, GenericSlider} from '@web-components'
import {useGenericActions} from '../../hooks'
import {ICatalogCardSlider} from './CatalogCardSlider.types'
import {TitleWithLabel} from '../TitleWithLabel'

const CatalogCardSlider = (props: BaseWidgetProps<ICatalogCardSlider>) => {
  const {widgetData, type, layout, id} = props
  const {
    title = '',
    label = '',
    color = '',
    items = [],
    bottomCta = {label: '', action: [] as GenericAction[]},
  } = widgetData || {}
  const {handleGenericActions} = useGenericActions()
  const handleBottomCtaClick = (action: GenericAction[]) => {
    if (action) {
      handleGenericActions(action)
    }
  }
  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <RLProductSliderContainer>
        <TitleWithLabel label={label} title={title} color={color} />
        <RLProductSliderWrapper>
          <GenericSlider
            noOfSlides={1.05}
            aspectRatio={1}
            isShowArrows={true}
            arrowPositionY={200}
            arrowGap={1400}
          >
            {() =>
              items.map((item: ICatalogCard, index: number) => (
                <CatalogCard key={index} {...item} />
              ))
            }
          </GenericSlider>
        </RLProductSliderWrapper>
        <div className="bottom-cta">
          <CtaWithAnimation
            onClick={() => handleBottomCtaClick(bottomCta.action)}
            variant="SECONDARY"
          >
            {bottomCta.label}
          </CtaWithAnimation>
        </div>
      </RLProductSliderContainer>
    </BaseWidgetComponent>
  )
}

export default CatalogCardSlider
