import React, {memo} from 'react'
import {
  GenericSlider,
  OptimizedImage,
  OptimizedVideo,
  Typography,
  TypographyVariants,
} from '@web-components'
import {MediaWithCta, MobileMediaSliderProps} from '../MediaSlider.interface'
import {noop} from 'lodash'
import {MobileMediaSliderWrapper} from '../MediaSlider.styles'

const MobileMediaSlider = (props: MobileMediaSliderProps) => {
  const {actions, widgetData} = props || {}
  const {
    slidesToShow = 1.1,
    images = [],
    aspectRatio = 2,
    startFromSlide = 0,
  } = widgetData || {}
  const {onSlideChange = noop, onImageClick = noop} = actions || {}

  return (
    <MobileMediaSliderWrapper>
      <GenericSlider
        noOfSlides={slidesToShow}
        aspectRatio={aspectRatio}
        startFromSlide={startFromSlide}
        onSlideChange={onSlideChange}
      >
        {({imageDimensions}: {imageDimensions: number[]}) =>
          images.map((imageData: MediaWithCta, index: number) => {
            const {
              image = '',
              type = '',
              video = '',
              ctaLabel = '',
              cta,
            } = imageData || {}
            const {
              params: {link = '#'} = {link: '#'}, // Provide default for params
            } = cta || {}
            // Ensure link is always a string
            const hrefLink = String(link)

            if (type === 'video') {
              return (
                <div className="media-container">
                  <OptimizedVideo
                    state={{
                      source: video,
                      customClassName: 'slider-video',
                      autoplay: true,
                      playsInline: true,
                      loop: true,
                      muted: true,
                    }}
                  />
                  {ctaLabel && (
                    <div className="cta-overlay">
                      <a href={hrefLink}>
                        <button
                          className="cta-button"
                          onClick={(e) => {
                            e.preventDefault()
                            onImageClick(cta)
                          }}
                        >
                          <Typography variant={TypographyVariants.TAG_BOLD}>
                            {ctaLabel}
                          </Typography>
                        </button>
                      </a>
                    </div>
                  )}
                </div>
              )
            }

            return (
              <a
                href={hrefLink}
                onClick={(e) => {
                  e.preventDefault()
                  onImageClick(cta)
                }}
              >
                <div className="media-container">
                  <OptimizedImage
                    source={image}
                    aspectWidth={imageDimensions[0]}
                    aspectHeight={imageDimensions[1]}
                    desktopViewWidth="FULL"
                    mobileViewWidth="FULL"
                    altText="oops"
                    loading={
                      index === 0 || (startFromSlide && index === 1)
                        ? 'eager'
                        : 'lazy'
                    }
                    customClassName="slider-image"
                    key={index}
                  />
                  {ctaLabel && (
                    <div className="cta-overlay">
                      <div className="cta-button">
                        <Typography variant={TypographyVariants.TAG_BOLD}>
                          {ctaLabel}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              </a>
            )
          })
        }
      </GenericSlider>
    </MobileMediaSliderWrapper>
  )
}

export default memo(MobileMediaSlider)
