import styled from 'styled-components'

export const MediaInfoSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--dls-brand-primary-color-500);
  padding: var(--dls-size-48) 0;
  padding-bottom: var(--dls-size-64);
  color: var(--dls-white-color);
  margin: 63px auto;

  @media (max-width: 768px) {
    padding: var(--dls-size-40) 0;
  }
`
