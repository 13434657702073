import styled from 'styled-components'

export const HomePageBannerWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 var(--dls-size-48);

  @media (max-width: 768px) {
    padding: 0;
  }

  .banner {
    border-radius: 0px 0px 30px 30px;
    width: 100%;
    object-fit: cover;
    @media (max-width: 768px) {
      height: 125vh;
    }
  }

  .banner-content {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-24);
    text-align: center;
    width: 100%;
    i {
      color: var(--dls-brand-primary-color-500);
    }
  }

  .banner-btn-wrapper {
    margin: 0 auto;
  }
`
