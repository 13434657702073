import React, {useCallback} from 'react'
import {ProductCardWrapper} from './CatalogCard.styles'
import {BaseWidget, GenericAction} from '@mosaic-wellness/fe-types'
import {
  Media,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '@web-components'
import {useGenericActions} from '../../../hooks'
import {ICatalogCard} from './CatalogCard.types'

const CatalogCard = (props: ICatalogCard) => {
  const {
    mediaData = {
      mediaType: 'image',
      media: {source: '', altText: ''},
      aspectRatio: 1,
    },
    title = '',
    id = '',
    cta = {action: null, icon: null},
    cardClickCta = {action: null},
    priceLabel = '',
    discountedPriceLabel = '',
  } = props || {}

  console.log('mediaData>>>>', mediaData)

  const {
    mediaType = 'image',
    media = {source: '', altText: ''},
    aspectRatio = 1,
  } = mediaData || {}

  const {handleGenericActions} = useGenericActions()

  const handleAddToCart = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement>,
      action: GenericAction[] | null
    ) => {
      event.stopPropagation()
      if (action) {
        handleGenericActions(action)
      }
    },
    []
  )

  const handleCardClick = useCallback((action: GenericAction[] | null) => {
    if (action) {
      handleGenericActions(action)
    }
  }, [])

  return (
    <ProductCardWrapper
      onClick={() => {
        handleCardClick(cardClickCta.action)
      }}
      key={id}
    >
      {media && (
        <Media media={media} mediaType={mediaType} aspectRatio={aspectRatio} />
      )}

      <div className="quick-add-holder">
        <button
          className="quick-add-button"
          onClick={(event) => {
            handleAddToCart(event, cta.action)
          }}
        >
          {cta.icon && <OptimizedImage source={cta.icon} />}
        </button>
      </div>
      <div className="item-info">
        <div className="item-title">
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {title}
          </Typography>
        </div>
        <div className="item-price">
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {discountedPriceLabel}
          </Typography>
          <Typography
            variant={TypographyVariants.BODY_BASE_LIGHT}
            customClassName="strikethrough "
          >
            {priceLabel}
          </Typography>
        </div>
      </div>
    </ProductCardWrapper>
  )
}

export default CatalogCard
