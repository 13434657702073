import React, {useCallback, useState} from 'react'
import {HeaderWrapper} from './RLNavigation.styles'
import {useRef} from 'react'
import {
  OptimizedImage,
  Typography,
  TypographyVariants,
  useDeviceTypeContext,
} from '@web-components'
import {BaseWidget, GenericAction} from '@mosaic-wellness/fe-types'
import {useGenericActions} from '../../hooks'

const RLNavigation = (props: BaseWidget) => {
  const {widgetData} = props

  const {
    hamburgerIcon = '',
    logo = {},
    cart = {},
    items = [],
    crossIcon = '',
    shopNowCta = {},
  } = widgetData || {}
  const {isMobile = false} = useDeviceTypeContext()
  const {genericAction} = useGenericActions()
  const [isNavVisible, setIsNavVisible] = useState(false)

  const toggleNavbar = useCallback(() => {
    setIsNavVisible((prev) => !prev)
  }, [])

  const navClick = useCallback((action: GenericAction) => {
    genericAction(action)
  }, [])

  return (
    <HeaderWrapper>
      {isMobile ? (
        <>
          <button className="nav-btn" onClick={toggleNavbar}>
            <OptimizedImage source={hamburgerIcon} customClassName="nav-icon" />
          </button>
          <button className="nav-btn" onClick={() => navClick(logo.action)}>
            <OptimizedImage source={logo.image} customClassName="brand-icon" />
          </button>
          <button className="nav-btn" onClick={() => navClick(cart.cta)}>
            <OptimizedImage source={cart.image} customClassName="nav-icon" />
          </button>
          {isNavVisible && (
            <div className="nav-overlay" onClick={toggleNavbar} />
          )}
          <nav className={isNavVisible ? 'responsive_nav' : ''}>
            <div className="mobile-nav-header">
              <button className="nav-btn " onClick={toggleNavbar}>
                <OptimizedImage
                  source={crossIcon}
                  customClassName="cross-icon"
                />
              </button>
              <button className="nav-btn">
                <OptimizedImage
                  source={logo.image}
                  customClassName="brand-icon"
                  onImageClick={() => {
                    navClick(logo.action)
                  }}
                />
              </button>
              <button className="nav-btn" onClick={() => navClick(cart.cta)}>
                <OptimizedImage
                  source={cart.image}
                  customClassName="nav-icon"
                />
              </button>
            </div>
            <div className="nav-menu">
              {items.map((item: any, index: number) => (
                <button onClick={() => navClick(item.action)} key={index}>
                  <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                    {item.label}
                  </Typography>
                </button>
              ))}
              <button
                onClick={() => navClick(shopNowCta.action)}
                className="mobile-nav-menu-shopnow"
              >
                <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                  {shopNowCta.label}
                </Typography>
              </button>
            </div>
          </nav>
        </>
      ) : (
        <div className="desktop-nav">
          <nav>
            {items
              .slice(0, items.length / 2)
              .map((item: any, index: number) => (
                <button onClick={() => navClick(item.action)} key={index}>
                  <Typography variant={TypographyVariants.TAG_REGULAR}>
                    {item.label}
                  </Typography>
                </button>
              ))}
            <OptimizedImage
              source={logo.image}
              onImageClick={() => {
                navClick(logo.action)
              }}
              customClassName="desktop-logo"
            />
            {items
              .slice(items.length / 2, items.length)
              .map((item: any, index: number) => (
                <button onClick={() => navClick(item.action)} key={index}>
                  <Typography variant={TypographyVariants.TAG_REGULAR}>
                    {item.label}
                  </Typography>
                </button>
              ))}
          </nav>
          <div className="desktop-nav-icon-wrapper">
            <div
              className="desktop-cart-icon-holder"
              onClick={() => navClick(cart.cta)}
            >
              <OptimizedImage source={cart.image} customClassName="nav-icon" />
              <Typography variant={TypographyVariants.TAG_REGULAR}>
                {cart.cta.label}
              </Typography>
            </div>
            <div
              className="desktop-shop-btn"
              onClick={() => navClick(shopNowCta.cta)}
            >
              <Typography variant={TypographyVariants.TAG_REGULAR}>
                {shopNowCta.label}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </HeaderWrapper>
  )
}

export default RLNavigation
