import React from 'react'
import {HeaderWrapper} from './TitleWithLabel.styles'
import {
  Typography,
  TypographyVariants,
  useDeviceTypeContext,
} from '@web-components'
import {ITitleWithLabel} from './TitleWithLabel.types'

const TitleWithLabel = (props: ITitleWithLabel) => {
  const {title = '', label = '', color = ''} = props || {}
  const {isMobile = false} = useDeviceTypeContext()

  return (
    <HeaderWrapper color={color}>
      <Typography
        variant={TypographyVariants.TAG_REGULAR}
        customClassName="title"
      >
        {label}
      </Typography>

      <Typography
        variant={
          isMobile
            ? TypographyVariants.HEADING_LARGE_REGULAR
            : TypographyVariants.HEADING_2XLARGE_REGULAR
        }
      >
        {title}
      </Typography>
    </HeaderWrapper>
  )
}

export default TitleWithLabel
