import {MenuRowItemWrapper} from './styles'
import {
  AccordionRight,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '../../atomic'
import noop from 'lodash/noop'
import {MenuRowItemProps} from './types'

const MenuRowItem = (props: MenuRowItemProps) => {
  const {
    icon = '',
    title = '',
    subTitle = '',
    hideRightArrow = false,
    onClick = noop,
    titleVariant = TypographyVariants.BODY_BASE_BOLD,
    subTitleVariant = TypographyVariants.TAG_REGULAR,
  } = props

  return (
    <MenuRowItemWrapper onClick={onClick} hideRightArrow={hideRightArrow}>
      {icon && (
        <OptimizedImage
          customClassName="icon"
          source={icon}
          data-testid="action-card-icon"
          aspectHeight={40}
          aspectWidth={40}
        />
      )}
      <div className="details">
        {title && <Typography variant={titleVariant}>{title}</Typography>}
        {subTitle && (
          <Typography variant={subTitleVariant}>{subTitle}</Typography>
        )}
      </div>
      {hideRightArrow ? null : <AccordionRight />}
    </MenuRowItemWrapper>
  )
}

export default MenuRowItem
