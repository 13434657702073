import styled from 'styled-components'

export const CircularImageWrapper = styled.div`
  flex-shrink: 0;
  padding: 6px;
  .circular-image {
    border-radius: 50%;
    border: 1px solid var(--dls-brand-primary-color-500);
    padding: 4px;
    margin-bottom: 22px;
    height: 90px;
    width: 90px;
    object-fit: cover;
    flex-shrink: 0;
    border: 2px solid var(-category-primary-bg2-color);
  }
`
