import {useAuth, useUserStore} from '@mosaic-wellness/redux-action-library'
import {useCallback, useMemo} from 'react'
import {useGetSlotsData} from '../useGetSlotsData/useGetSlotsData'
import {useBookSlotsData} from '../useBookSlotData/useBookSlotData'
import {analytics} from 'src/analytics'

function useSwipeToBookData(props: any = {}) {
  const {isUsedInWebView = false} = props

  const {
    actions: {setShowLoginModal},
  } = useAuth()
  const {
    user: {isLoggedIn, firstName, lastName, phoneNumber, email},
  } = useUserStore()
  const [{slotData, isLoading}, {getSlotsData}] = useGetSlotsData()
  const [{bookData, bookError, isBookSlotInProgress}, {bookSlotsData}] =
    useBookSlotsData({isUsedInWebView})

  const handleSwipeToBook = useCallback(() => {
    analytics.trigger('swipeToBook')
  }, [])

  const handleBookAppointmentShown = useCallback(({isIAAvailable}: any) => {
    analytics.push({
      bookAppointmentShownPD: {
        isIAAvailable,
      },
    })
  }, [])

  const handleAppointmentTypeSelected = useCallback(({tab}: any) => {
    analytics.trigger('bookAppointmentTypeChanged', {
      type: tab,
    })
  }, [])

  const actions = useMemo(() => {
    return {
      setShowLoginModal,
      getSlotsData,
      bookSlotsData,
      onSwipeToBook: handleSwipeToBook,
      onBookAppointmentShown: handleBookAppointmentShown,
      onAppointmentTypeSelected: handleAppointmentTypeSelected,
    }
  }, [
    setShowLoginModal,
    getSlotsData,
    bookSlotsData,
    handleSwipeToBook,
    handleAppointmentTypeSelected,
    handleBookAppointmentShown,
  ])

  const state = useMemo(() => {
    return {
      isLoggedIn,
      firstName,
      lastName,
      phoneNumber,
      email,
      slotData,
      bookData,
      bookError,
      isLoading,
      isBookSlotInProgress,
    }
  }, [
    isLoggedIn,
    firstName,
    lastName,
    phoneNumber,
    email,
    slotData,
    bookData,
    bookError,
    isLoading,
    isBookSlotInProgress,
  ])

  return {
    actions,
    state,
  }
}

export {useSwipeToBookData}
