import {useUserStore} from '@mosaic-wellness/redux-action-library'
import {useCallback, useMemo} from 'react'
import {
  IBookSlotsDataPayload,
  IUseBookSlotsDataProps,
} from './useBookSlotData.interface'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {usePost} from '@mosaic-wellness/core-utils'
import {IGlobalResponse} from 'src/interfaces/globalResponse.interface'
import {analytics} from 'src/analytics'

function useBookSlotsData(props: IUseBookSlotsDataProps = {}) {
  const {isUsedInWebView = false} = props
  const {
    user: {email, phoneNumber, firstName, lastName, id},
  } = useUserStore()

  const {mutate, isLoading, data, isError} = usePost<
    IGlobalResponse<any>,
    any,
    IBookSlotsDataPayload
  >({
    url: API_END_POINTS.BOOK_SLOT,
    customConfig: {
      triggerAnalytics: true,
      analyticsEventName: 'DA Error',
      showErrorToast: true,
    },
  })

  const payloadStaticData = useMemo(() => {
    return {
      mode: 'bw_call',
      patientName: `${firstName} ${lastName}`,
      source_id: 'website',
      user_id: id,
      primary_source: '',
      secondary_source: '',
      doctor_id: '',
      FU_ques: {
        category: '',
        hairCondition: '',
        anything_else: '',
        age: '',
        weight: '',
        height: '',
      },
      recommended_products: [],
      source: 'DA',
      email,
      phone: phoneNumber,
      app: isUsedInWebView ? 1 : undefined,
    }
  }, [phoneNumber, email, firstName, lastName, id, isUsedInWebView])

  const bookSlotsData = useCallback(
    async (bookPayload: IBookSlotsDataPayload): Promise<void> => {
      analytics.trigger('appointmentSelected', bookPayload)

      mutate(
        {
          ...payloadStaticData,
          ...bookPayload,
        },
        {
          onSuccess: (data) => {
            if (data.data) {
              analytics.trigger('appointmentBooked', {
                bookPayload,
              })
            }
          },
        }
      )
    },
    [mutate, payloadStaticData]
  )

  return [
    {
      bookData: data?.data,
      bookError: isError,
      isBookSlotInProgress: isLoading,
    },
    {
      bookSlotsData,
    },
  ] as const
}

export {useBookSlotsData}
