import {
  BaseWidget,
  BaseWidgetProps,
  GenericAction,
} from '@mosaic-wellness/fe-types'
import React, {useCallback} from 'react'
import {
  Media,
  useDeviceTypeContext,
  Typography,
  TypographyVariants,
  CtaWithAnimation,
} from '@web-components'

import {BaseWidgetComponent} from '../BaseWidget'
import {HomePageBannerWrapper} from './HomepageBanner.styles'
import {useGenericActions} from '../../hooks'
import {IHomePageBannerProps} from './HomepageBanner.types'

const HomepageBanner = (props: BaseWidgetProps<IHomePageBannerProps>) => {
  const {widgetData, type, layout, id} = props || {}

  const {
    title = '',
    mediaData = {
      mediaType: 'image',
      media: {source: '', altText: ''},
      aspectRatio: 1,
    },
    cta = {label: '', action: [] as GenericAction[]},
  } = widgetData || {}
  const {
    mediaType = 'image',
    media = {source: '', altText: ''},
    aspectRatio = 1,
  } = mediaData || {}

  const {isMobile = false} = useDeviceTypeContext()
  const {handleGenericActions} = useGenericActions()
  const handleCtaClick = useCallback((action: GenericAction[]) => {
    if (action) {
      handleGenericActions(action)
    }
  }, [])

  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <HomePageBannerWrapper>
        <Media
          mediaType={mediaType}
          media={media}
          aspectRatio={aspectRatio}
          customClassName="banner"
        />
        <div className="banner-content">
          <Typography
            variant={
              isMobile
                ? TypographyVariants.HEADING_LARGE_REGULAR
                : TypographyVariants.HEADING_2XLARGE_REGULAR
            }
          >
            <span dangerouslySetInnerHTML={{__html: title}} />
          </Typography>
          <div className="banner-btn-wrapper">
            <CtaWithAnimation
              onClick={() => {
                handleCtaClick(cta.action)
              }}
              variant="PRIMARY"
            >
              {cta.label}
            </CtaWithAnimation>
          </div>
        </div>
      </HomePageBannerWrapper>
    </BaseWidgetComponent>
  )
}

export default HomepageBanner
