import {useEffect, useRef, useState} from 'react'

const useInnerHeight = () => {
  const [innerHeight, setInnerHeight] = useState<number | null>(null)

  useEffect(() => {
    setInnerHeight(window.innerHeight)
    const handleResize = () => {
      setInnerHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    innerHeight,
  }
}

export {useInnerHeight}
