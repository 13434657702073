import {Wrapper} from './AlertMessage.styles'

import {Typography} from '../../../../../components/shared/src/ComponentsV2/common/TypographyV2'
import {AlertMessageProps} from './AlertMessageInterface'
import {OptimizedImage} from '../../atomic'

function AlertMessage(props: AlertMessageProps) {
  const {message} = props || {}
  const {title = '', subtitle = '', icon = ''} = message || {}

  return (
    <Wrapper>
      <div className="alert-message">
        {icon && (
          <div className="alert-message-icon-layout">
            <OptimizedImage className="alert-icon" source={icon} />
          </div>
        )}
        <div className="alert-message-title">
          {title && (
            <div>
              <Typography variant="body-base-bold" customClassName="title">
                {title}
              </Typography>
            </div>
          )}
          {subtitle && (
            <div>
              <Typography
                variant="body-base-regular"
                customClassName="subtitle"
              >
                {subtitle}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default AlertMessage
