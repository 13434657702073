import {useEffect} from 'react'
import HomePageSEO from 'src/components/SEO/HomePage'
import {WidgetRenderer} from '@web-core'
import {CLIENT_SIDE_WIDGETS} from './ExternalWidget.Map'
import {useAppConfigContext} from 'src/context/AppConfigContext'
import useScreenName from 'src/hooks/useScreenName/useScreenName'

function Home(props: any) {
  const {data: homepageProps} = props || {}

  const {
    appConfig,
    meta,
    organisationData,
    websiteSchemaData,
    widgets = [],
  } = homepageProps || {}

  const [, appConfigContextActions] = useAppConfigContext()
  const {resetUpdateConfig, updateAppConfig} = appConfigContextActions
  const {screenName} = useScreenName()

  useEffect(() => {
    updateAppConfig(appConfig ?? {})
    return () => resetUpdateConfig()
  }, [appConfig, resetUpdateConfig, updateAppConfig])

  return (
    <>
      <HomePageSEO
        meta={meta}
        organisationData={organisationData}
        websiteSchemaData={websiteSchemaData}
      />
      <WidgetRenderer
        widgets={widgets}
        CLIENT_SIDE_WIDGETS={CLIENT_SIDE_WIDGETS}
        pageName={screenName}
      />
    </>
  )
}

export default Home
