import React, {useMemo} from 'react'
import {Typography, TypographyVariants, RightTiltedArrow} from '@web-components'
import {BtnWrapper} from './styles'
import {CtaWithAnimation, IVariants, Variant} from './types'
import noop from 'lodash/noop'

const variantStyles: Record<Variant, IVariants> = {
  PRIMARY: {
    arrowColor: 'var(--dls-brand-primary-color-500)',
    color: 'var(--dls-white-color)',
    bgColor: 'var(--dls-brand-primary-color-500)',
    arrowBgColor: 'var(--dls-white-color)',
    borderColor: 'var(--dls-brand-primary-color-500)',
  },
  SECONDARY: {
    arrowColor: 'var(--dls-white-color)',
    color: 'var(--dls-brand-primary-color-500)',
    bgColor: 'transparent',
    arrowBgColor: 'var(--dls-brand-primary-color-500)',
    borderColor: 'var(--dls-brand-primary-color-500)',
  },
  TERTIARY: {
    arrowColor: 'var(--dls-white-color)',
    color: 'var(--dls-brand-primary-color-500)',
    bgColor: 'var(--dls-white-color)',
    arrowBgColor: 'var(--dls-brand-primary-color-500)',
    borderColor: 'var(--dls-white-color)',
  },
}

const index = (props: CtaWithAnimation) => {
  const {children, onClick = noop, disabled, variant = 'TERTIARY'} = props

  const styles = variantStyles[variant] || variantStyles.PRIMARY

  return (
    <BtnWrapper
      onClick={onClick}
      disabled={disabled}
      color={styles.color}
      bgColor={styles.bgColor}
      arrowBgColor={styles.arrowBgColor}
      borderColor={styles.borderColor}
    >
      <Typography variant={TypographyVariants.BODY_BASE_LIGHT}>
        {children}
      </Typography>
      <div className="btn-icon-wrapper">
        <RightTiltedArrow color={styles.arrowColor} />
        <div className="arrow-icon-bottom">
          <RightTiltedArrow color={styles.arrowColor} />
        </div>
      </div>
    </BtnWrapper>
  )
}

export default index
