import React from 'react'
import {VideoPlayerProps} from './OptimizedVideo.interface'

const VideoPlayer = ({state}: VideoPlayerProps) => {
  const {
    autoplay = false,
    muted = true,
    loop = false,
    playsInline = false,
    controls = false,
    preload = 'metadata',
    source = '',
    type = 'video/mp4',
    posterImage = '',
    disablePictureInPicture = false,
    videoRef,
    customClassName,
  } = state

  return (
    <video
      ref={videoRef}
      autoPlay={autoplay}
      muted={muted}
      loop={loop}
      playsInline={playsInline}
      controls={controls}
      preload={preload}
      poster={posterImage}
      disablePictureInPicture={disablePictureInPicture}
      data-testid="video-player"
      className={customClassName}
    >
      {source && <source src={source} type={type} data-testid="video-source" />}
      Your browser does not support the video tag.
    </video>
  )
}

export default VideoPlayer
