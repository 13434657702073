import styled from 'styled-components'

export const ReelCardWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  border-radius: var(--dls-size-8);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  .reel-content {
    height: 508px;
    object-fit: cover;
    border-radius: var(--dls-size-8);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px 0px,
      rgba(0, 0, 0, 0.12) 0px 0px 0px 1px;
  }
`
