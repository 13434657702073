import {useCallback} from 'react'
import {ProductSwitchContainer} from './ProductSwitch.styles'
import {IProductSwitchProps} from './ProductSwitch.interface'

import {BaseWidgetComponent} from '../BaseWidget'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import {useGenericActions} from '../../hooks'
import isEmpty from 'lodash/isEmpty'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

function ProductSwitch(props: IProductSwitchProps) {
  const {id, type, widgetData, layout} = props
  const {ctas, title = '', itemStyle = 'COMPACT'} = widgetData || {}

  const {genericAction} = useGenericActions()

  const handleClick = useCallback(
    (cta: any) => {
      if (cta.isSelected) {
        return
      }
      genericAction(interceptAndEnhanceAction(cta.cta, id))
    },
    [genericAction, id]
  )

  if (isEmpty(ctas)) {
    return null
  }

  return (
    <BaseWidgetComponent widgetType={type} widgetId={id} layout={layout}>
      <ProductSwitchContainer data-itemstyle={itemStyle}>
        <Typography
          variant={TypographyVariants.SUBHEADING_BOLD}
          customClassName="title"
        >
          {title}
        </Typography>
        <div className="cta-grid-container">
          <div className="cta-grid">
            {ctas.map((cta) => (
              <div
                className="cta-grid-item"
                key={cta.label}
                data-isselected={cta.isSelected}
                onClick={() => handleClick(cta)}
              >
                <div className="image-container">
                  <OptimizedImage
                    source={cta.imageUrl}
                    aspectHeight={itemStyle === 'EXPANDED' ? '66' : '36'}
                    aspectWidth={itemStyle === 'EXPANDED' ? '66' : '36'}
                    customClassName="cta-image"
                  />
                </div>
                <Typography
                  variant={TypographyVariants.TAG_REGULAR}
                  customClassName="cta-label"
                >
                  <span dangerouslySetInnerHTML={{__html: cta.label}}></span>
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </ProductSwitchContainer>
    </BaseWidgetComponent>
  )
}

export default ProductSwitch
