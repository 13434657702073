import {useCallback} from 'react'
import {analytics} from 'src/analytics'
import {EVENT_MAP} from 'src/analytics/eventMap'

function useCommsBasicDetails() {
  const onBasicDetailsViewed = useCallback(() => {
    analytics.trigger(EVENT_MAP.BASIC_DETAILS_VIEWED)
  }, [])

  const onBasicDetailQuestionAnswered = useCallback((data) => {
    analytics.trigger(EVENT_MAP.BASIC_DETAILS_QUESTION_ANSWERED, data)
  }, [])

  const onBasicDetailSubmitted = useCallback(() => {
    analytics.trigger(EVENT_MAP.BASIC_DETAILS_DETAIL_SUBMITTED)
  }, [])

  return {
    onBasicDetailsViewed,
    onBasicDetailQuestionAnswered,
    onBasicDetailSubmitted,
  }
}

export {useCommsBasicDetails}
