import styled from 'styled-components'

export const BrickSectionWrapper = styled.div<{
  bgColor: string
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  background-color: ${({bgColor}) => bgColor};
  color: ${({color}) => color};

  .brick-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--dls-size-40) 0;

    @media (max-width: 768px) {
      gap: var(--dls-size-24);
      padding: var(--dls-size-40) 20px;
      flex-direction: column;
      margin: 0;
      width: 100%;

      .title {
        margin-bottom: 0px !important;
      }

      .content {
        margin: 0;
        width: 100%;
      }
    }
  }

  .content {
    width: 50%;
    margin: 0 150px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    padding: 50px 50px 50px 0;

    .title {
      margin-bottom: 5px;
    }

    .tick-icon {
      height: 30px;
      width: 30px;
    }

    .item {
      display: flex;
      align-items: center;
      gap: var(--dls-size-8);
    }

    i {
      color: var(--dls-brand-primary-color-500);
    }

    .items-wrapper {
      margin-bottom: var(--dls-size-8);
    }
  }

  .media {
    width: 50%;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .media-content {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`
