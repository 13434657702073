import React, {useCallback} from 'react'
import {
  OptimizedImage,
  TypographyVariants,
  Typography,
  ChevronRight,
} from '@web-components'
import {DesktopCategoryPaletteContainer} from './DesktopCategoryPaletteCard.styles'
import {DesktopCategoryPaletteCardProps} from './DesktopCategoryPaletteCard.interface'

function DesktopCategoryPaletteCard(props: DesktopCategoryPaletteCardProps) {
  const {cardData, handleAction} = props || {}

  const {image, categoryName, description, cta} = cardData || {}

  const handleClick = useCallback(
    (e) => {
      e.preventDefault()
      handleAction(cta)
    },
    [handleAction, cta]
  )

  return (
    <DesktopCategoryPaletteContainer
      href={cta?.params?.link || '#'}
      onClick={handleClick}
    >
      <div className="image-container">
        <OptimizedImage
          source={image}
          aspectWidth={200}
          aspectHeight={200}
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          altText="celeb-image"
          loading="lazy"
          customClassName="card-image"
        />
      </div>
      <div className="content-container">
        <div className="content-top">
          <Typography
            variant={TypographyVariants.BODY_BASE_BOLD}
            customClassName="categoryName"
          >
            {categoryName}
          </Typography>
          <ChevronRight />
        </div>
        <div className="content-bottom">
          <Typography
            variant={TypographyVariants.BODY_BASE_REGULAR}
            customClassName="description"
          >
            {description}
          </Typography>
        </div>
      </div>
    </DesktopCategoryPaletteContainer>
  )
}

export default DesktopCategoryPaletteCard
