import {useCallback, useEffect, useState} from 'react'
import {
  useAuth,
  useUserStore,
  HttpClient,
  useCommsActiveRoute,
} from '@mosaic-wellness/redux-action-library'
import noop from 'lodash/noop'

import {
  IFormConfig,
  IUseSaChatBoxProps,
  IUseSaChatBoxReturnType,
} from './selfAssessment.interface'
import {useGenericCta} from '../useGenericCta'
import {SELF_ASSESSMENT_STEPS} from 'src/constants/SelfAssessment'
import {EVENT_MAP} from 'src/analytics/eventMap'

import {CLIENT_ENV} from 'src/constants/clientEnv'
import {useSetSecondaryUtmSource} from '../useSetSecondaryUtmSource'
import {checkIsApp} from 'src/utils/checkIsApp'
import {useCommsBasicDetails} from '../tracking/useCommsBasicDetails'
import {useRedirection} from '..'
import {isNativeConsultVersion} from 'src/utils/isNativeConsultVersion'
import isEmpty from 'lodash/isEmpty'
import {getQueryParamFromUrl} from 'src/utils/url'
import {analytics} from 'src/analytics'
import {toast} from 'src/toast'
import {useFormQuestions} from '../consult/useFormQuestions/useFormQuestions'

const BRAND = CLIENT_ENV.NEXT_PUBLIC_BRAND || 'lj'

function useSaChatBox(props: IUseSaChatBoxProps): IUseSaChatBoxReturnType {
  const {
    updateStep = noop,
    chatSource = 'form',
    shouldClaimForm = true,
    waCategory = '',
    version = '',
    reportVersion = 'v3',
    flags,
    mw_source = '',
    formStaticConfig = {},
  } = props

  const consultRedirect = getQueryParamFromUrl('consultRedirect')

  const [
    {
      chatData,
      isLoading,
      formComplete,
      currentQuestion,
      shouldHideNextButtonAndAutoSubmit,
      formQuestionsResponse,
      formId,
    },
    {
      getInitialFormQuestionData,
      getFormQuestionData,
      updateRequestBody,
      setAnswerToCurrentQuestion,
      resetPreviousAnswer,
      claimForm,
      resetFormState,
      getInitialFormQuestionDataWithExistingFormId,
    },
  ] = useFormQuestions({
    source: chatSource,
    rawDetails: {
      category: waCategory,
      mw_source,
      consultRedirect,
    },
    version,
    reportVersion,
    consultRedirect,
  })
  const {
    actions: {setShowLoginModal},
  } = useAuth()
  const {
    user: {isLoggedIn},
  } = useUserStore()
  const {handleCta} = useGenericCta()
  const [{data}] = useCommsActiveRoute()
  /* callbacks for tracking */
  const {onBasicDetailSubmitted} = useCommsBasicDetails()
  /* Set utm_source in session storage */
  useSetSecondaryUtmSource()
  const {enabledBrandsForNewAssessment = false} = flags || {}

  const [{currentConsultVersion = ''}, {handleRedirection = noop}] =
    useRedirection()

  const [formConfig, setFormConfig] = useState<IFormConfig>()

  const isApp = checkIsApp()
  const {
    basicDetailsToastMessage = 'Great! You have updated your basic details',
  } = formConfig || {}

  const onCrossButtonClicked = useCallback(() => {
    resetFormState()
    if (formStaticConfig?.crossButtonAction) {
      handleCta(formStaticConfig?.crossButtonAction)
      return
    }
    if (chatSource === 'updateprofile') {
      handleCta({action: 'SPA_LINK', link: '/experts'})
      return
    }
    if (isApp) {
      handleCta({action: 'SPA_LINK', link: data?.link})
      return
    }
    updateStep(SELF_ASSESSMENT_STEPS.HOME)
  }, [
    resetFormState,
    chatSource,
    isApp,
    updateStep,
    handleCta,
    data?.link,
    formStaticConfig?.crossButtonAction,
  ])

  const handleLogin = useCallback(
    () => setShowLoginModal({isOpen: true, triggeredFrom: ''}),
    [setShowLoginModal]
  )

  const onBasicDetailComplete = useCallback(() => {
    let completed = false

    if (typeof window !== 'undefined') {
      completed = window.customDataLayer?.lastFormIdData?.completed
    }

    /* Only trigger submitted event in case of the form not already completed by user from before */
    if (!completed) {
      toast('success', basicDetailsToastMessage)
      onBasicDetailSubmitted()
    }

    if (currentQuestion?.actionsOnComplete) {
      currentQuestion?.actionsOnComplete?.forEach((action: any) => {
        handleCta(action)
      })
      return
    }

    if (formStaticConfig?.completionAction) {
      handleCta(formStaticConfig?.completionAction)
      return
    }

    if (isNativeConsultVersion({currentConsultVersion}) && isApp) {
      handleRedirection({
        action: 'APP_NAV',
        link: {
          appNavigationData: {
            path: 'Consult',
          },
        },
        version: currentConsultVersion,
      })
      return
    }
    if (data?.link) {
      handleRedirection({action: 'ROUTER_REPLACE', link: data.link})
      return
    }

    if (consultRedirect === 'y') {
      handleRedirection({action: 'ROUTER_REPLACE', link: 'consult-allot'})
      return
    }

    handleRedirection({action: 'ROUTER_REPLACE', link: 'experts'})
  }, [
    basicDetailsToastMessage,
    currentConsultVersion,
    data.link,
    handleRedirection,
    isApp,
    onBasicDetailSubmitted,
    toast,
    consultRedirect,
    currentQuestion?.actionsOnComplete,
    handleCta,
    formStaticConfig?.completionAction,
  ])

  useEffect(() => {
    async function handleFormCompleteNavigation() {
      if (formComplete && shouldClaimForm) {
        if (isLoggedIn) {
          await claimForm()

          const isRedirectUrl = !isEmpty(formQuestionsResponse?.redirectUrl)

          let link = `/wellness-assessment-report?formId=${formId}&consultVersion=${currentConsultVersion}`

          if (enabledBrandsForNewAssessment) {
            link = `/self-assess?formId=${formId}&consultVersion=${currentConsultVersion}`
          }

          const finalUrl = isRedirectUrl
            ? formQuestionsResponse?.redirectUrl
            : link

          analytics.trigger(EVENT_MAP.UPDATE_CLEVER_TAP_USER, {
            key: 'Report',
            value: finalUrl,
          })
          handleCta({
            action: 'SPA_LINK',
            link: finalUrl,
          })

          return
        }

        toast('success', 'Assessment complete! Please login to view report')
        setShowLoginModal({isOpen: true, triggeredFrom: ''})
      }
    }

    handleFormCompleteNavigation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formComplete, isLoggedIn])

  useEffect(() => {
    async function fetchFormConfig() {
      const axiosInstance = HttpClient.getAxiosInstance()
      const {data} = await axiosInstance.get(
        CLIENT_ENV.NEXT_PUBLIC_BACKEND + 'page/mwsc/static/form-config'
      )

      if (data?.data) {
        setFormConfig(data?.data)
      }
    }
    fetchFormConfig()
  }, [])

  return [
    {
      chatData,
      isLoading,
      formComplete,
      currentQuestion,
      shouldHideNextButtonAndAutoSubmit,
      formQuestionsResponse,
      isLoggedIn,
      formConfig: formConfig as IFormConfig,
      waCategory,
      hideCloseIcon: consultRedirect === 'y',
    },
    {
      getInitialFormQuestionData,
      getFormQuestionData,
      updateRequestBody,
      setAnswerToCurrentQuestion,
      resetPreviousAnswer,
      onCrossButtonClicked,
      handleLogin,
      getInitialFormQuestionDataWithExistingFormId,
      onBasicDetailComplete,
    },
  ]
}

export {useSaChatBox}
