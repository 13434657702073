import {docCookies} from '../cookie'

function generateRandomId() {
  // Helper function to generate a random 3-digit number
  function getRandomThreeDigitNumber() {
    return Math.floor(100 + Math.random() * 900)
  }

  // Generate four random 3-digit numbers
  const part1 = getRandomThreeDigitNumber()
  const part2 = getRandomThreeDigitNumber()
  const part3 = getRandomThreeDigitNumber()
  const part4 = getRandomThreeDigitNumber()

  // Join the four parts to form a 12-digit number
  const id = `${part1}${part2}${part3}${part4}`

  return id
}

async function getRandomIdFromCookie(keyName: string) {
  let randomId: any = ''
  if (keyName) {
    randomId = await docCookies.getItem({
      cookieName: keyName,
      skipDomain: false,
    })
    if (!randomId) {
      const newRandomId = await generateRandomId()
      await docCookies.setItem({
        cookieName: String(keyName),
        cookieValue: String(newRandomId),
        expiry: 1209600,
        skipDomain: false,
        cookiePath: '/',
      })
      randomId = newRandomId
    }
  }
  return randomId
}

function validateExperimentalId(value: string) {
  if (value && value !== undefined && Number(value)) {
    if (Number(value) % 2 === 0) {
      return true
    }
  }
  return false
}

export {generateRandomId, getRandomIdFromCookie, validateExperimentalId}
