const BRAND = process.env.NEXT_PUBLIC_BRAND || 'UNKNOWN'
export const processAffluencePageViews = () => {
  const urlParams = new URLSearchParams(window?.location?.search)

  const utmSource = urlParams.get('utm_source')
  const utmMedium = urlParams.get('utm_medium')
  const utmCampaign = urlParams.get('utm_campaign')
  const ref = urlParams.get('ref')
  const dmId = urlParams.get('dm_id')
  const influencerId = urlParams.get('influencer_id')

  if (utmSource && utmSource.match(/^affluence/)) {
    return {
      ...(utmSource ? {utmSource} : {}),
      ...(utmMedium ? {utmMedium} : {}),
      ...(utmCampaign ? {utmCampaign} : {}),
      ...(influencerId ? {influencerId} : {}),
      ...(dmId ? {dmId} : {}),
      ...(ref ? {ref} : {}),
      brand: BRAND,
      eventName: 'Page Viewed',
      data: {
        url: window.location.href,
      },
    }
  }
}
