import React from 'react'
import {ReviewImageSliderWrapper} from './ReviewImageSlider.styles'
import {BaseWidget} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {ReviewWithImage} from './ReviewWithImage'
import {IReviewWithImage} from './ReviewWithImage/ReviewWithImage.types'
import {
  GenericSlider,
  OptimizedImage,
  ReviewRatingStar,
  Typography,
  TypographyVariants,
  useDeviceTypeContext,
} from '@web-components'
import {AvatarItem} from './ReviewImageSlider.types'
import {TitleWithLabel} from '../TitleWithLabel'

const ReviewImageSlider = (props: BaseWidget) => {
  const {widgetData, type, layout, id} = props || {}

  const {title = '', items = [], customerRating = {}} = widgetData || {}
  const {
    avatarItems = [],
    ratingStarCount = 5,
    ratingStarValue = 5,
    ratingNumber = '5.0',
    bottomText = '',
  } = customerRating || {}

  const {isMobile = false} = useDeviceTypeContext()

  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <ReviewImageSliderWrapper>
        <div className="header">
          <TitleWithLabel
            title={title}
            label=""
            color="var(--dls-black-color)"
          />
          <div className="customer-rating-wrapper">
            <div className="avatar-wrapper">
              {avatarItems.map((item: AvatarItem, index: number) => (
                <div className="avatar-container" key={index}>
                  <OptimizedImage
                    source={item.mediaData.media.source}
                    customClassName="avatar-image"
                  />
                </div>
              ))}
            </div>
            <div className="rating-wrapper">
              <div className="rating-container">
                <span className="rating-number">
                  <Typography variant={TypographyVariants.TAG_REGULAR}>
                    {ratingNumber}
                  </Typography>
                </span>
                <ReviewRatingStar
                  count={ratingStarCount}
                  value={ratingStarValue}
                  size={22}
                  className="ratingStyle"
                />
              </div>
              <div className="customer-count">
                <Typography variant={TypographyVariants.TAG_REGULAR}>
                  {bottomText}
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <GenericSlider noOfSlides={isMobile ? 1.05 : 3} aspectRatio={1}>
          {() =>
            items.map((item: IReviewWithImage, index: number) => (
              <ReviewWithImage {...item} key={index} />
            ))
          }
        </GenericSlider>
      </ReviewImageSliderWrapper>
    </BaseWidgetComponent>
  )
}

export default ReviewImageSlider
