import noop from 'lodash/noop'
import {useMemo} from 'react'
import {getOptimizedImageKitLink} from './getOptimizedImageLink'
import {useDeviceTypeContext} from '../../../contexts/DeviceType'

interface IOptimizedImageProps {
  source: string
  aspectWidth?: string | number
  aspectHeight?: string | number
  desktopViewWidth?: string
  mobileViewWidth?: string
  altText?: string
  loading?: 'lazy' | 'eager'
  customClassName?: string
  onImageClick?: () => void
  screenWidth?: string
}

function OptimizedImage(props: IOptimizedImageProps) {
  const {
    source = '',
    aspectWidth = '',
    aspectHeight = '',
    desktopViewWidth = 'FULL',
    mobileViewWidth = 'FULL',
    altText = '',
    loading = 'lazy',
    customClassName = '',
    onImageClick = noop,
    screenWidth,
    ...rest
  } = props

  const {isMobile} = useDeviceTypeContext()

  const modifiedSrc = useMemo(() => {
    return getOptimizedImageKitLink(
      source,
      desktopViewWidth,
      mobileViewWidth,
      isMobile
    )
  }, [source, desktopViewWidth, mobileViewWidth, isMobile])

  if (!modifiedSrc) return null

  return (
    <img
      src={modifiedSrc}
      className={customClassName}
      height={aspectHeight}
      width={aspectWidth}
      loading={loading}
      alt={altText}
      onClick={onImageClick}
      decoding="async"
      data-testid="optimized-image"
      {...rest}
    />
  )
}

export default OptimizedImage
