import styled from 'styled-components'

export const MediaInfoWrapper = styled.div`
  .media-info {
    display: flex;
    gap: var(--dls-size-80);
    align-items: center;
    width: 68%;
    margin: 0 auto;
    margin-top: var(--dls-size-48);

    @media (max-width: 768px) {
      flex-direction: column;
      gap: var(--dls-size-8);
      width: 100%;
      padding: 30px 20px;
      margin-top: 0;
    }
  }

  .media-info-image {
    width: 500px;
    height: 500px;
    border-radius: var(--dls-size-32);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .media-info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .media-info-description {
    padding: 30px 0;
    display: flex;
    @media (max-width: 768px) {
      padding-top: 20px;
    }
  }

  .media-info-divider {
    height: 1px;
    background-color: white;
    width: 100%;
  }

  .media-info-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: var(--dls-size-24);
    width: 100%;
  }

  .media-info-name {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-16);
    width: 100%;
    align-items: start;
  }

  .media-info-number {
    display: flex;
    align-items: self-end;
  }
`
