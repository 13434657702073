import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'

import {useGenericActions} from '../../hooks'
import {ProductsGridContainer} from './CategoryProductCardGrid.styles'
import {CategoryProductCard} from './CategoryProductCard'
import {useCallback, useMemo} from 'react'
import NoProductsFound from './NoProductsFound'
import {BaseWidget} from '@mosaic-wellness/fe-types'
import {Product} from './CategoryProductCard/CategoryProductCard.interface'
import {useUpdateItemToCart} from 'src/hooks/updateCart/useUpdateItemToCart'
import {useGenericCta} from 'src/hooks'

function CategoryProductCardGrid(props: BaseWidget) {
  const {type, widgetData, header, layout, id} = props || {}
  const {
    products = [],
    showEmptyState = true,
    emptyState,
    enableQuantityIndicator = false,
    source: widgetDataSource = '',
    showMobileViewOnDesktop = false,
    productImageAspectRatio = 1,
    shouldOpenMiniPdp = false,
    miniPdpCta = {
      showCta: true,
      label: 'Add to Cart',
      variant: 'PRIMARY',
      size: 'LARGE',
      isSticky: true,
      isGenericAction: true,
    },
  } = widgetData || {}
  const {genericAction} = useGenericActions()
  const source = useMemo(() => widgetDataSource || id, [id, widgetDataSource])

  const {handleCta} = useGenericCta()
  // TODO: remove hook
  const {updateItemToCart} = useUpdateItemToCart()

  const showNoProductsEmptyState = useMemo(
    () => isEmpty(products) && showEmptyState,
    [products, showEmptyState]
  )
  const handleCtaClick = useCallback(
    (product: Product) => {
      const {action = '', actionData = {}} = product?.cta || {}
      if (!action) return
      genericAction({
        actionName: action,
        params: {...product, ...(actionData || {})},
      })
    },
    [genericAction]
  )

  const handleCardClick = useCallback(
    (product: Product) => {
      const {action = '', actionData = {}} = product?.cta || {}
      if (shouldOpenMiniPdp) {
        handleCta({
          action: 'OPEN_MINI_PRODUCT_PAGE_BOTTOM_SHEET',
          actionData: {
            urlKey: product?.urlKey,
            name: product?.name,
            source: 'shop reorder',
            cta: {
              ...miniPdpCta,
              label: product?.ctaLabel,
              clickAction: {
                action: action,
                data: {...product, ...(actionData || {})},
              },
            },
          },
        })
      } else {
        genericAction({actionName: 'OPEN_PDP', params: product})
      }
    },
    [genericAction, shouldOpenMiniPdp, handleCta, miniPdpCta]
  )

  const handleUpdateCartItem = useCallback(
    (id: number, sku: string, quantity: number) => {
      updateItemToCart({
        sku,
        quantity,
        isMiniCart: true,
      })
    },
    [updateItemToCart]
  )

  if (isEmpty(products) && !showEmptyState) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <ProductsGridContainer showMobileViewOnDesktop={showMobileViewOnDesktop}>
        {!showNoProductsEmptyState &&
          products.map((product: any, index: number) => (
            <div
              className="product-card-container"
              key={product.urlKey + index}
            >
              <CategoryProductCard
                product={{...product, source}}
                productImageAspectRatio={productImageAspectRatio}
                handleCardClick={handleCardClick}
                handleCtaClick={handleCtaClick}
                handleUpdateCartItem={handleUpdateCartItem}
                enableQuantityIndicator={enableQuantityIndicator}
              />
            </div>
          ))}
      </ProductsGridContainer>
      {showNoProductsEmptyState && <NoProductsFound emptyState={emptyState} />}
    </BaseWidgetComponent>
  )
}

export default CategoryProductCardGrid
