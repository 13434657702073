import {useCallback} from 'react'
import {analytics} from 'src/analytics'
import {EVENT_MAP} from 'src/analytics/eventMap'

function useConsultTracking({entryPoint = ''}) {
  const onAppointmentConfirmSecondaryClicked = useCallback(
    (conformationData) => {
      analytics.trigger(EVENT_MAP.APPOINTMENT_CONFIRM_IMAGE_UPLOAD, {
        appointmentId: conformationData?.appointmentDetails?.appointmentId,
        category: conformationData?.appointmentDetails?.category,
      })
    },
    []
  )

  const onAppointmentConfirmPrimaryClicked = useCallback((conformationData) => {
    analytics.trigger(EVENT_MAP.APPOINTMENT_CONFIRM_CONTINUE_SHOPPING, {
      appointmentId: conformationData?.appointmentDetails?.appointmentId,
      category: conformationData?.appointmentDetails?.category,
    })
  }, [])

  const onAppointmentConfirmedViewed = useCallback((conformationData) => {
    analytics.trigger(EVENT_MAP.APPOINTMENT_CONFIRM_VIEWED, {
      appointmentId: conformationData?.appointmentDetails?.appointmentId,
      category: conformationData?.appointmentDetails?.category,
    })
  }, [])

  const pushFutureAppointmentDataToDataLayer = useCallback(
    (futureAppointmentData) => {
      analytics.push({futureAppointmentData})
    },
    []
  )
  const onSwipeAppointmentSubmitted = useCallback(
    ({productName, productId, category, iaBooked, slotSelected}) => {
      analytics.trigger(EVENT_MAP.SWIPE_APPOINTMENT_SUBMIT, {
        entryPoint,
        productName,
        productId,
        category,
        iaBooked,
        slotSelected,
      })
    },
    [entryPoint]
  )
  const onSwipeAppointmentBooked = useCallback(
    ({
      productName,
      productId,
      category,
      iaBooked,
      slotSelected,
      appointmentID,
    }) => {
      analytics.trigger(EVENT_MAP.SWIPE_APPOINTMENT_BOOKED, {
        entryPoint,
        productName,
        productId,
        category,
        iaBooked,
        slotSelected,
        appointmentID,
      })
    },
    [entryPoint]
  )
  const onSwipeAppointmentError = useCallback(
    ({
      productName,
      productId,
      category,
      iaBooked,
      slotSelected,
      errorResponse,
    }) => {
      analytics.trigger(EVENT_MAP.SWIPE_APPOINTMENT_BOOKING_ERROR, {
        entryPoint,
        productName,
        productId,
        category,
        iaBooked,
        slotSelected,
        errorResponse,
      })
    },
    [entryPoint]
  )
  const onOpenSwipeToBook = useCallback(
    ({productName, productId, category, iaAvailable}) => {
      analytics.trigger(EVENT_MAP.SWIPE_TO_BOOK, {
        entryPoint,
        productName,
        productId,
        category,
        iaAvailable,
      })
    },
    [entryPoint]
  )

  return {
    pushFutureAppointmentDataToDataLayer,
    onAppointmentConfirmSecondaryClicked,
    onAppointmentConfirmPrimaryClicked,
    onAppointmentConfirmedViewed,
    onSwipeAppointmentSubmitted,
    onSwipeAppointmentBooked,
    onSwipeAppointmentError,
    onOpenSwipeToBook,
  }
}

export {useConsultTracking}
