import React from 'react'
import {CircularImageWrapper} from './CircularMediaCard.styles'
import {ICircularMediaCard} from './CircularMediaCard.types'
import {Media} from '@web-components'

const CircularMediaCard = (props: ICircularMediaCard) => {
  const {mediaData} = props
  const {media, mediaType} = mediaData || {}
  return (
    <CircularImageWrapper>
      <Media
        mediaType={mediaType}
        media={media}
        customClassName="circular-image"
      />
    </CircularImageWrapper>
  )
}

export default CircularMediaCard
