import React from 'react'

import {OrdersListingInSupportItemProps} from './OrdersListingInSupportItem.types'
import {StyledOrdersListingInSupportItem} from './OrdersListingInSupportItem.styles'
import {
  Button,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '@web-components'

const OrdersListingInSupportItem = (props: OrdersListingInSupportItemProps) => {
  const {backgroundImg = '', infoItems = [], handleCtaPress, cta} = props || {}
  const {label, variant, size, actions, ...rest} = cta

  return (
    <StyledOrdersListingInSupportItem>
      {backgroundImg && (
        <OptimizedImage
          source={backgroundImg}
          customClassName="backgroundImage"
        />
      )}
      <div className="content-wrapper">
        {infoItems.map((item, index) => (
          <div key={`support_order_detail_item_${index}`}>
            <Typography
              variant={TypographyVariants.TAG_REGULAR}
              customClassName="details"
            >
              <span dangerouslySetInnerHTML={{__html: item?.label}} />
            </Typography>
            {index < infoItems.length - 1 && (
              <div className="detailSeperator" />
            )}
          </div>
        ))}
        {label && actions?.length > 0 && (
          <Button
            fullWidth={false}
            label={label}
            variant={variant}
            size={size}
            {...rest}
            customClassName="cta"
            onClick={handleCtaPress(actions)}
          />
        )}
      </div>
    </StyledOrdersListingInSupportItem>
  )
}

export default OrdersListingInSupportItem
