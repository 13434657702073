import {CLIENT_ENV} from 'src/constants/clientEnv'
export const isMMBrand = () => {
  const BRAND = CLIENT_ENV.NEXT_PUBLIC_BRAND || 'lj'
  return BRAND === 'mm' || BRAND == 'mm-co'
}
export const generateSlugURL = (_id: string, _slug?: string) => {
  if (!_slug) {
    return isMMBrand() ? `/dp/${_id}` : `/product/${_id}`
  }

  if (isMMBrand()) return `/dp/${_slug}/${_id}`

  return `/product/${_id}`
}
