import styled from 'styled-components'

export const SearchInputWithRecommendationContainer = styled.div<{
  isSearchResultEmpty: boolean
}>`
  .sticky-shadow {
    box-shadow: rgba(0, 0, 0, 0.333) 0px -20px 20px 9px;
    width: 100%;
  }
  .search-bar-proxy-wrapper {
    margin-top: 87px;
  }
  .content-container {
    max-width: 928px;
    width: 100%;
    display: flex;
    margin: auto;
    justify-content: center;
    background: var(--dls-white-color);
  }
  .search-bar-wrapper {
    width: 100%;
    padding: var(--dls-size-16);
    background: var(--dls-white-color);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  .search-result-wrapper {
    display: flex;
    border-radius: var(--dls-size-12);
    justify-content: center;
    background-color: var(--dls-backgound-color-100);
    padding: ${({isSearchResultEmpty}) =>
      isSearchResultEmpty
        ? 'var(--dls-size-8) 0px var(--dls-size-8) 0px'
        : 'var(--dls-size-16)'};
  }
  .pill-section-wrapper {
    width: 100%;
    padding: var(--dls-size-8) 0px 0px 0px;
  }

  @media (min-width: 768px) {
    .search-bar-wrapper {
      padding: var(--dls-size-16) var(--dls-size-32) var(--dls-size-16)
        var(--dls-size-32);
    }
    .search-result-wrapper {
      padding: ${({isSearchResultEmpty}) =>
        isSearchResultEmpty ? 0 : 'var(--dls-size-16)'};
      margin: ${({isSearchResultEmpty}) =>
        isSearchResultEmpty ? 0 : '0px var(--dls-size-32)'};
    }
  }
`

export const SearchBarProxyWrapper = styled.div`
  margin-top: 87px;
`
