import {BaseWidgetProps, InfoBannerProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {InfoBanner} from '@web-components'

const FeedbackBannerWidget = ({
  widgetData,
  layout,
  header,
  type,
  id,
}: BaseWidgetProps<InfoBannerProps>) => {
  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <InfoBanner {...widgetData} />
    </BaseWidgetComponent>
  )
}

export default FeedbackBannerWidget
