import {IDataLayer} from 'src/analytics/types'
import {logError} from 'src/utils/logger'

export enum HYPD_EVENTS {
  PRODUCT_VIEW = 'product_page',
  ADD_TO_CART = 'add_to_cart',
}

export const reportToHypd = async (
  {
    event,
    utmParams,
    value = {},
    type = 'other',
  }: {
    event: HYPD_EVENTS
    utmParams: Record<string, any>
    value: Record<string, any>
    type: 'product' | 'other'
  },
  dLayer: IDataLayer
) => {
  let eventValue = {}

  if (type === 'product') {
    const {
      'Product Name': product_name,
      'Product ID': product_id,
      Price,
      MRP,
      'Product Image': product_image,
    } = value
    eventValue = {
      event_name: 'product_page',
      product_id,
      product_name,
      product_base_price: Price ?? MRP,
      product_image,
      //event_time is  'UTC time value in string',
      event_time: new Date().toUTCString(),
    }
  }

  // return
  try {
    if (!event) {
      return
    }

    const {creatorId = '', linkId = '', siteName} = utmParams || {}

    if (siteName === 'hypd') {
      const url = new URL('https://catalog2.hypd.store/affiliate/tracking')
      // append params to url
      url.searchParams.append('merchant', 'MOSAIC_WELLNESS')
      url.searchParams.append('event', event)
      url.searchParams.append('value', JSON.stringify(eventValue))
      url.searchParams.append('site_name', 'hypd')
      url.searchParams.append('creator_id', creatorId)
      url.searchParams.append('link_id', linkId)
      url.searchParams.append(
        'cookie_values',
        `site_name=hypd&creator_id=${creatorId ?? ''}&link_id=${linkId ?? ''}`
      )
      fetch(url.toString())
    }
  } catch (error) {
    logError(error as Error, {
      location: 'reportToHypd.ts',
      source: 'Error while reporting to Hypd',
    })
  }
}
