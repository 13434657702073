import React from 'react'
import {CircularMediaSliderWrapper} from './CircularMediaSlider.styles'
import {BaseWidget} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {CircularMediaCard} from './CircularMediaCard'

const CircularMediaSlider = (props: BaseWidget) => {
  const {widgetData, type, id, layout} = props
  const {items = []} = widgetData || {}

  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <CircularMediaSliderWrapper>
        {items.map((item: any, index: number) => (
          <CircularMediaCard {...item} key={index} />
        ))}
      </CircularMediaSliderWrapper>
    </BaseWidgetComponent>
  )
}

export default CircularMediaSlider
