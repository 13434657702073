import {StyledRatingFeedback} from './styles'
import {Typography, TypographyVariants} from '../../atomic'
import {RatingFeedbackProps} from '@mosaic-wellness/fe-types'
import RatingStarSelector from '../RatingStarSelector'
import IconCross from 'src/components/shared/src/ComponentsV2/common/Icons/IconClose'
import noop from 'lodash/noop'

function RatingFeedback(props: RatingFeedbackProps) {
  const {
    title = '',
    subTitle = '',
    showCross = false,
    handleCrossClick = noop,
  } = props || {}

  return (
    <StyledRatingFeedback>
      {showCross && (
        <div className="crossIcon" onClick={handleCrossClick}>
          <IconCross fill="var(--dls-disabled-text-colors)" />
        </div>
      )}
      <Typography
        variant={TypographyVariants.TAG_REGULAR}
        customClassName="label"
      >
        {title}
      </Typography>
      <Typography variant={TypographyVariants.BODY_BASE_BOLD}>
        {subTitle}
      </Typography>
      <div className="divider" />
      <RatingStarSelector {...props} />
    </StyledRatingFeedback>
  )
}

export default RatingFeedback
