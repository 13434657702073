import {useUserStore} from '@mosaic-wellness/redux-action-library'
import {useCallback, useMemo} from 'react'
import {mapSlots} from './useGetSlots.utils'
import {IGetSlotsDataPayload} from './useGetSlotsData.interface'
import {usePost} from '@mosaic-wellness/core-utils'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {IGlobalResponse} from 'src/interfaces/globalResponse.interface'

function useGetSlotsData() {
  const {
    user: {email, phoneNumber},
  } = useUserStore()

  const {mutate, isLoading, data} = usePost<IGlobalResponse<any>, any, any>({
    url: API_END_POINTS.GET_SLOTS_DATA,
    customConfig: {
      triggerAnalytics: true,
      analyticsEventName: 'DA Error',
      showErrorToast: true,
    },
  })

  const payloadStaticData = useMemo(() => {
    return {
      grouping_length: 30,
      source: 'DA',
      email,
      phone: phoneNumber,
    }
  }, [email, phoneNumber])

  const slotData = useMemo(() => {
    return data?.data || null
  }, [data?.data])

  const normalizedSlots = useMemo(() => {
    if (slotData) {
      return mapSlots(slotData, false)
    }

    return {}
  }, [slotData])

  const normalizedSlotsLsd = useMemo(() => {
    if (slotData) {
      return mapSlots(slotData, true)
    }

    return {}
  }, [slotData])

  const getSlotsData = useCallback(
    async (slotsPayload: IGetSlotsDataPayload) => {
      mutate({
        ...payloadStaticData,
        ...slotsPayload,
      })
    },
    [mutate, payloadStaticData]
  )

  return [
    {
      slotData,
      isLoading,
      normalizedSlots,
      normalizedSlotsLsd,
    },
    {
      getSlotsData,
    },
  ] as const
}

export {useGetSlotsData}
