import styled from 'styled-components'

export const MediaWithAnimationWrapper = styled.div<{filled: number}>`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 90%;
  margin: 0 auto;
  color: var(--dls-white-color);
  background-color: var(--dls-brand-primary-color-500);

  @media (max-width: 768px) {
    width: 100%;
  }

  .image-wrapper {
    width: 100%;
    height: 775px;
    border-radius: var(--dls-size-16);
    display: flex;
    justify-content: center;
    overflow: hidden;

    @media (max-width: 768px) {
      height: 375px;
    }
  }
  .image {
    width: 100%;
    object-fit: cover;
  }

  .progress-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .animation-container {
    position: relative;
    width: 100%;
    height: 1px;
    margin-top: var(--dls-size-24);
  }

  .animation-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e8ddd3;
    opacity: 0.2;
    z-index: 1;
  }

  .animation-border {
    position: relative;
    z-index: 2;
    height: 1px;
    background-color: var(--dls-white-color);
    width: ${({filled}) => filled}%;
    transition: width 0.2s ease-in-out;
  }

  .animation-text {
    font-style: italic;
    display: flex;
    justify-content: start;
  }

  .meta-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: var(--dls-size-16);
    gap: var(--dls-size-8);
  }
`
