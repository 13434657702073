import {BaseWidget} from '@mosaic-wellness/fe-types'
import React from 'react'
import {MediaInfoWrapper} from './MediaWithInfo.styles'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'

const MediaWithInfo = (props: any) => {
  const {widgetData} = props

  const {image = '', content = {}} = widgetData || {}
  return (
    <MediaInfoWrapper>
      <div className="media-info">
        <div className="media-info-image">
          <OptimizedImage
            source={image.source}
            customClassName="image"
            altText={image.altText}
          />
        </div>
        <div className="media-info-content">
          <div className="media-info-description">
            <Typography variant={TypographyVariants.HEADING_MEDIUM_REGULAR}>
              {content.info}
            </Typography>
          </div>
          <div className="media-info-divider"></div>
          <div className="media-info-bottom">
            <div className="media-info-name">
              <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                {content.bottomContent.name}
              </Typography>
              <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                {content.bottomContent.designation}
              </Typography>
            </div>
            <div className="media-info-number">
              <Typography variant={TypographyVariants.HEADING_MEDIUM_REGULAR}>
                {content.bottomContent.number}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </MediaInfoWrapper>
  )
}

export default MediaWithInfo
