import styled from 'styled-components'

export const AccordionItemContainer = styled.div`
  padding: var(--dls-size-16) 0px;

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--dls-size-16);
  }

  .title-text {
    margin-right: var(--dls-size-24);
    color: var(--dls-primary-text-colors);
    width: 90%;
  }

  .content-text {
    margin-top: 0;
    color: var(--dls-secondary-text-colors);
    max-height: 0;
    transition: max-height 0.3s ease-in-out, margin-top 0.3s ease-in-out;
    overflow: hidden;
    display: inline-block;
    width: 90%;
  }

  .icon-container {
    display: inline-block;
    animation: none;
    transform: rotate(0deg);
    transition: transform 0.3s, opacity 0.3s;
    opacity: 1;
  }

  .icon-container.animating {
    animation: spin 0.3s ease-in-out forwards;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0;
    }
  }

  &[data-isopen='true'] {
    .content-text {
      margin-top: var(--dls-size-16);
      max-height: 1000px; /* Using a large value to accommodate any large content, doesn't mess the UI */
    }
  }
`
