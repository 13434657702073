import {CLIENT_ENV} from 'src/constants/clientEnv'

const BRAND = CLIENT_ENV.NEXT_PUBLIC_BRAND

// TODO: AE - WHY ARE WE HARDCODING BRANDS?
export const isBrandLJ =
  BRAND === 'lj' || BRAND === 'lj-ae' || BRAND === 'lj-sa' || 'lj-co'
export const isBrandLJInternational = BRAND === 'lj-ae' || BRAND === 'lj-sa'

export const isBrandBW = BRAND === 'bw' || BRAND === 'bw-co'

export const isCoBrand = () => {
  return BRAND && BRAND.includes('-co')
}
