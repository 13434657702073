import {useState, useCallback} from 'react'

import {
  IGetAppointmentDetailsParams,
  IGetAppointmentDetailsResponse,
} from './useGetAppointmentDetails.interface'
import {useFetch} from '@mosaic-wellness/core-utils'
import {IGlobalResponse} from 'src/interfaces/globalResponse.interface'
import {API_END_POINTS} from 'src/constants/apiEndPoints'

function useGetAppointmentDetails() {
  const [scheduleToken, setScheduleToken] = useState('')
  const {isLoading, isError, data} = useFetch<
    IGlobalResponse<IGetAppointmentDetailsResponse>
  >({
    queryKey: 'get-appointment-details',
    url: API_END_POINTS.GET_APPOINTMENT_DETAILS,
    disabled: !scheduleToken,
    cacheTime: 0,
    queryOptions: {
      retry: 0,
    },
  })

  const getAppointmentDetails = useCallback(
    (params: IGetAppointmentDetailsParams) => {
      setScheduleToken(params?.scheduleToken)
    },
    []
  )

  return [
    {
      appointmentDetails: data?.data,
      isAppointmentDataLoading: isLoading,
      isError,
    },
    {getAppointmentDetails},
  ] as const
}

export {useGetAppointmentDetails}
