import {
  getStoreInstance,
  useAuth,
  useUserStore,
  HttpClient,
} from '@mosaic-wellness/redux-action-library'
import {useRouter} from 'next/router'
import {useCallback} from 'react'
import {findElementTop} from 'src/utils/findElementPosition'
import {useTruecallerLogin} from './useTruecallerLogin'
import {useExplicitATCTracking} from './tracking/useExplicitATCTracking'
import {useProductClickTracking} from './tracking/useProductClick'
import {useProductNavigation} from './product/useProductNavigation'
import {useUpdateItemToCart} from './updateCart/useUpdateItemToCart'
import {localStorageMethods} from 'src/utils'
import sessionStorageMethods from 'src/utils/sessionStorage/sessionStorageMethods'
import {useAuthorizedNav} from './useAuthorizedNav'
import toast from 'react-hot-toast'
import {noop} from 'lodash'
import {checkIsApp} from 'src/utils/checkIsApp'
import {useMiniProductPageBottomSheetStore} from './product/useMiniProductPageBottomSheet.store'
import {useBuyNow} from './product/useBuyNow/useBuyNow'

export enum CTA_ACTION {
  AUTH = 'AUTH',
  LINK = 'LINK',
  SPA_LINK = 'SPA_LINK',
  SCROLL = 'SCROLL',
  LOGOUT = 'LOGOUT',
  REPLACE = 'REPLACE',
  ADD_TO_CART = 'ADD_TO_CART',
  PRODUCT_NAVIGATION = 'PRODUCT_NAVIGATION',
  ROUTER_REPLACE = 'ROUTER_REPLACE',
  STORE_REF_AND_NAV = 'STORE_REF_AND_NAV',
  APP_NAV = 'APP_NAV',
  REPLACE_NAV = 'REPLACE_NAV',
  APP_AUTH_NAV = 'APP_AUTH_NAV',
  API_DRIVEN_ACTION = 'API_DRIVEN_ACTION',
  START_ON_BOARDING = 'START_ON_BOARDING',
  ENDPOINT = 'ENDPOINT',
  BUY_NOW = 'BUY_NOW',
  OPEN_MINI_PRODUCT_PAGE_BOTTOM_SHEET = 'OPEN_MINI_PRODUCT_PAGE_BOTTOM_SHEET',
  CLOSE_MINI_PRODUCT_PAGE_BOTTOM_SHEET = 'CLOSE_MINI_PRODUCT_PAGE_BOTTOM_SHEET',
}

export interface ApiDetails {
  url: string
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  params?: Record<string, any>
  body?: Record<string, any>
  onSuccessAction?: GenericCta
}

export interface GenericCta {
  action: CTA_ACTION
  label: string
  link: string
  newTab?: boolean
  product?: any
  source?: string
  variant?: 'primary' | 'secondary' | 'custom' | 'disabled'
  web_url?: string
  triggeredFrom?: string
  api?: ApiDetails
}

export const useGenericCta = () => {
  const {
    actions: {setShowLoginModal},
  } = useAuth()
  const router = useRouter()
  const {logoutUser} = useUserStore()
  // const {
  //   state: {isTruecallerEnabled, isPollingInProgress},
  //   actions: {triggerTruecallerLogin},
  // } = useTruecallerLogin({
  //   triggerNormalLoginCallback: () =>
  //     setShowLoginModal({
  //       isOpen: true,
  //       triggeredFrom: '',
  //     }),
  //   source: 'generic',
  // })
  const {trackProductCardClick} = useProductClickTracking()
  const {navigateToProduct} = useProductNavigation()
  const {trackAddToCart} = useExplicitATCTracking()
  const {addToCart} = useUpdateItemToCart()
  const axiosInstance = HttpClient.getAxiosInstance()
  const [, {handleBuyNow = noop}] = useBuyNow({})
  const isApp = checkIsApp()

  function clearCometChatKeys() {
    const storage = window.localStorage
    for (const key of Object.keys(storage)) {
      if (
        /^[a-zA-Z0-9]+:common_store\/.+$/.test(key) ||
        /^[a-zA-Z0-9]+:keys_store\/.+$/.test(key) ||
        /^[a-zA-Z0-9]+:message_listeners_list\/.+$/.test(key)
      ) {
        storage.removeItem(key)
      }
    }
  }

  const {handleAuthorizedNav} = useAuthorizedNav()
  const openMiniProductPageBottomSheet = useMiniProductPageBottomSheetStore(
    (store) => store.openMiniProductPageBottomSheet
  )
  const closeMiniProductPageBottomSheet = useMiniProductPageBottomSheetStore(
    (store) => store.closeMiniProductPageBottomSheet
  )
  const handleCta = useCallback(
    (cta) => {
      const {
        action,
        label = '',
        link = '#',
        newTab = false,
        product,
        source = '',
        offsetTop = 0,
        web_url = '',
        dataToPersist,
        triggeredFrom = '',
        api,
        needsAuth = false,
        actionData = {},
      } = cta || {}

      if (dataToPersist) {
        Object.keys(dataToPersist).forEach((key: any) => {
          sessionStorageMethods.set({
            keyName: key,
            value: dataToPersist[key],
          })
        })
      }

      switch (action) {
        case CTA_ACTION.ADD_TO_CART: {
          const {sku} = product || {}
          trackAddToCart(product, source)
          addToCart({sku})
          break
        }

        case CTA_ACTION.APP_NAV: {
          if (!link) return
          if (needsAuth) {
            const {isLoggedIn} = getStoreInstance().getState().user ?? {}

            if (!isLoggedIn) {
              handleAuthorizedNav({link}, `Generic Action - ${label}`)
              return
            }
          }
          router.push(link)
          break
        }

        case CTA_ACTION.REPLACE_NAV: {
          if (!link) return
          if (needsAuth) {
            handleAuthorizedNav({link}, `Generic Action - ${label}`)
          } else {
            router.push(link)
          }
          break
        }

        case CTA_ACTION.APP_AUTH_NAV: {
          if (!link) return

          router.push(link)
        }

        case CTA_ACTION.PRODUCT_NAVIGATION: {
          const {slugUrl = '', urlKey = ''} = product || {}
          trackProductCardClick({...product, source})
          navigateToProduct({slugUrl, urlKey})
          break
        }

        case CTA_ACTION.AUTH: {
          // if (isTruecallerEnabled) {
          //   triggerTruecallerLogin()
          // } else {
          setShowLoginModal({
            isOpen: true,
            triggeredFrom: triggeredFrom || label,
            redirectTo: link,
          })
          // }
          break
        }

        case CTA_ACTION.LINK: {
          if (newTab) {
            window.open(link, '_blank')
          } else {
            window.location.href = link
          }
          break
        }

        case CTA_ACTION.REPLACE: {
          window.location.replace(link)
          break
        }

        case CTA_ACTION.SPA_LINK: {
          router.push(link)
          break
        }

        case CTA_ACTION.SCROLL: {
          const node = document.querySelector(link)
          if (node) {
            // node.scrollIntoView({
            //   behavior: 'smooth',
            //   block: 'start',
            //   inline: 'start',
            // })

            window.scrollTo({
              left: 0,
              top: findElementTop(node) + offsetTop,
              behavior: 'smooth',
            })
          }

          break
        }

        case CTA_ACTION.APP_NAV:
        case CTA_ACTION.APP_AUTH_NAV: {
          if (!web_url) return

          window.location.href = web_url
          break
        }

        case CTA_ACTION.REPLACE_NAV: {
          if (!web_url) return

          window.location.href = web_url
          break
        }

        case CTA_ACTION.LOGOUT:
          logoutUser()
          clearCometChatKeys()
          break

        case CTA_ACTION.ROUTER_REPLACE: {
          router.replace(link)
          break
        }

        case CTA_ACTION.STORE_REF_AND_NAV: {
          localStorageMethods.set({
            keyName: 'urlPostAction',
            value: window.location.href,
          })
          if (newTab) {
            window.open(link, '_blank')
          } else {
            window.location.href = link
          }
          break
        }

        case CTA_ACTION.API_DRIVEN_ACTION: {
          if (!api) return

          const {url, method, params, body, onSuccessAction} = api
          axiosInstance({
            method,
            url,
            params,
            data: body,
          })
            .then(() => {
              if (onSuccessAction) {
                handleCta(onSuccessAction)
              }
            })
            .catch((e) => {
              toast.error(e?.response?.data?.message || 'Something went wrong')
            })
          break
        }

        case CTA_ACTION.START_ON_BOARDING: {
          if (!link) return
          if (needsAuth) {
            const {isLoggedIn} = getStoreInstance().getState().user ?? {}

            if (!isLoggedIn) {
              handleAuthorizedNav({link}, `Generic Action - ${label}`)
              return
            }
          }
          router.push(link)

          break
        }
        case CTA_ACTION.ENDPOINT: {
          const {
            endpointType = '',
            endpointUrl = '',
            endpointBody = {actionObj: {}},
            headers = {},
          } = actionData

          axiosInstance({
            method: endpointType,
            url: endpointUrl,
            data: endpointBody,
            headers,
          }).then((res) => {
            const events = res?.data?.data?.events
            if (Array.isArray(events)) {
              events?.forEach((event: any) => {
                handleCta(event)
              })
            }
          })

          break
        }

        case CTA_ACTION.BUY_NOW: {
          const {sku} = product || {}
          if (isApp) {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                actions: 'ADD_TO_CART',
                actionData: {
                  product,
                  shouldNavigateToScreen: true,
                  shouldTriggerEvent: false,
                  source,
                  isWebView: true,
                },
              })
            )
          } else {
            handleBuyNow(source, {sku}, false, false)
          }
          break
        }

        case CTA_ACTION.OPEN_MINI_PRODUCT_PAGE_BOTTOM_SHEET: {
          const {urlKey = '', source = '', cta = {}, ...rest} = actionData || {}
          if (urlKey) {
            openMiniProductPageBottomSheet({urlKey, source, ...rest}, cta)
          }
          break
        }

        case CTA_ACTION.CLOSE_MINI_PRODUCT_PAGE_BOTTOM_SHEET: {
          closeMiniProductPageBottomSheet()
          break
        }

        default:
          break
      }
    },
    [
      logoutUser,
      trackAddToCart,
      addToCart,
      router,
      handleAuthorizedNav,
      trackProductCardClick,
      navigateToProduct,
      // isTruecallerEnabled,
      // triggerTruecallerLogin,
      setShowLoginModal,
      axiosInstance,
      isApp,
      handleBuyNow,
      openMiniProductPageBottomSheet,
      closeMiniProductPageBottomSheet,
    ]
  )

  return {
    handleCta,
    isTruecallerLoginInProgress: false,
  }
}
