import React from 'react'
import {MediaWithPillsSliderWrapper} from './MediaWithPillsSlider.styles'
import {BaseWidget, GenericAction} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {
  CtaWithAnimation,
  GenericCarousel,
  useDeviceTypeContext,
} from '@web-components'
import {MediaWithPills} from './MediaWithPills'
import {useGenericActions} from '../../hooks'
import {TitleWithLabel} from '../TitleWithLabel'

const MediaWithPillsSlider = (props: BaseWidget) => {
  const {widgetData, type, layout, id} = props
  const {title = '', label = '', items = [], bottomCta = {}} = widgetData || {}
  const {handleGenericActions} = useGenericActions()
  const {isMobile = false} = useDeviceTypeContext()

  const handleCtaClick = (action: GenericAction[]) => {
    if (action) {
      handleGenericActions(action)
    }
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <MediaWithPillsSliderWrapper>
        <TitleWithLabel title={title} label={label} color="black" />
        <GenericCarousel
          slidesToShow={isMobile ? 1 : 3}
          cardData={items}
          Component={MediaWithPills}
          showDots={false}
        />
        <div className="bottom-cta">
          <CtaWithAnimation
            onClick={() => handleCtaClick(bottomCta.action)}
            variant="SECONDARY"
          >
            {bottomCta.label}
          </CtaWithAnimation>
        </div>
      </MediaWithPillsSliderWrapper>
    </BaseWidgetComponent>
  )
}

export default MediaWithPillsSlider
