import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({color}) => color};
  align-items: center;
  gap: var(--dls-size-8);

  @media (max-width: 768px) {
    padding: var(--dls-size-16);
    align-items: start;
  }
`
