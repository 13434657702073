import {LottiePlayer, OptimizedImage} from '../../atomic'
import {ImageOrAnimationBannerProps} from '@mosaic-wellness/fe-types'
import useImageOrAnimationBanner from './useImageOrAnimationBanner'

const ImageOrAnimationBanner = (props: ImageOrAnimationBannerProps) => {
  const {
    imageUrl = '',
    animationUrl = '',
    aspectRatio = 1,
    width = '100%',
    animationProps = {},
  } = props || {}
  const {containerRef, height} = useImageOrAnimationBanner(props)

  if (animationUrl) {
    return (
      <div ref={containerRef} style={{width, height}}>
        <LottiePlayer
          src={animationUrl}
          className="habit-banner"
          style={{width: '100%', height}}
          {...animationProps}
        />
      </div>
    )
  }
  return (
    <div ref={containerRef} style={{width, height}}>
      <OptimizedImage
        source={imageUrl}
        aspectWidth={'100%'}
        aspectHeight={'100%'}
        customClassName="habit-banner"
      />
    </div>
  )
}

export default ImageOrAnimationBanner
