import React, {useEffect, useRef, useState} from 'react'
import {MediaWithAnimationWrapper} from './MediaWithProgress.styles'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'

const MediaWithProgress = (props: any) => {
  const {widgetData = {}} = props
  const {
    cardTitle = '',
    cardDescription = '',
    mediaData = {},
    animationText,
  } = widgetData || {}
  const {media} = mediaData || {}
  const [filled, setFilled] = useState(0)

  const [isInView, setIsInView] = useState(false)
  const elementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true)
        } else {
          setIsInView(false)
        }
      },
      {threshold: 0.5}
    )

    if (elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (isInView) {
      const interval = setInterval(() => {
        setFilled((prev) => {
          if (prev >= 100) {
            clearInterval(interval)
            return 100
          }
          return prev + 5
        })
      }, 200)

      return () => clearInterval(interval)
    }
    setFilled(0)
  }, [isInView])

  return (
    <MediaWithAnimationWrapper ref={elementRef} filled={filled}>
      <div className="image-wrapper">
        <OptimizedImage
          source={media.source}
          altText={media.altText}
          customClassName="image"
        />
      </div>
      <div className="progress-wrapper">
        <div className="animation-container">
          <div className="animation-border"></div>
        </div>
        <Typography
          variant={TypographyVariants.TAG_REGULAR}
          customClassName="animation-text"
        >
          {animationText}
        </Typography>
      </div>
      <div className="meta-content">
        <Typography variant={TypographyVariants.HEADING_SMALL_REGULAR}>
          {cardTitle}
        </Typography>
        <Typography variant={TypographyVariants.BODY_BASE_LIGHT}>
          {cardDescription}
        </Typography>
      </div>
    </MediaWithAnimationWrapper>
  )
}

export default MediaWithProgress
