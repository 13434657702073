import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import isEmpty from 'lodash/isEmpty'

import {
  IFormState,
  IChatData,
  IRequestBody,
  IUseFormQuestionsReturnType,
  IUseFormQuestionsProps,
  ISelectedIds,
  IBookSlotData,
} from './useFormQuestions.interface'
import {getShouldHideNextButtonFlag} from './useFormQuestions.utils'
import {AxiosRequestConfig} from 'axios'
import {useUserStore} from '@mosaic-wellness/redux-action-library'
import {fetchResource, postResource} from '@mosaic-wellness/core-utils'
import {IGlobalResponse} from 'src/interfaces/globalResponse.interface'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {analytics} from 'src/analytics'
import {EVENT_MAP} from 'src/analytics/eventMap'

/**
 * Logic for powering the chat flow used in Form, DA, and Basic Details
 * @param props props passed to this custom hook
 * @param props.source what is the source for this usage, ex: da, form, updateprofile, order
 */
function useFormQuestions(
  props: IUseFormQuestionsProps = {}
): IUseFormQuestionsReturnType {
  const {
    source = 'form',
    rawDetails,
    version = '',
    reportVersion = '',
    fetchQuestionsVersion = '',
    consultRedirect = '',
  } = props

  const [formQuestionsResponse, setFormQuestionsResponse] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [chatData, setChatData] = useState<IChatData>([])
  const [formId, setFormId] = useState<number | null>(null)
  const [formComplete, setFormComplete] = useState<boolean>(false)
  const [selectedIds, setSelectedIds] = useState<ISelectedIds[]>([])
  const [skipAnimationAndResetIndex, setSkipAnimationAndResetIndex] = useState<
    number | null
  >(null)

  const {
    user: {email, phoneNumber, id, firstName, lastName},
  } = useUserStore()

  /* ref to store the current form state(question and answers) to send to BE on every fetch-question call */
  const formState = useRef<IFormState>({questions: []})

  /* ref to store request body that is to be sent on every fetch-question call */
  const requestBody = useRef<IRequestBody>({
    questionNumber: 0,
    selectedAnswers: {},
    source,
    version,
    reportVersion,
    consultRedirect,
  })

  const currentQuestion: any = useMemo(() => {
    if (chatData[chatData.length - 1]) {
      return chatData[chatData.length - 1]
    }

    return {}
  }, [chatData])

  const shouldHideNextButtonAndAutoSubmit = useMemo(() => {
    return getShouldHideNextButtonFlag(currentQuestion)
  }, [currentQuestion])

  const isCurrentQuestionAnswered = useMemo(() => {
    return currentQuestion?.answer?.length > 0
  }, [currentQuestion])

  const isNextButtonDisabled: boolean = useMemo(() => {
    return selectedIds.length === 0 && !formComplete
  }, [selectedIds, formComplete])

  const previousQuestion = useMemo(() => {
    if (isEmpty(chatData)) {
      return
    }
    return chatData?.[chatData?.length - 2]
  }, [chatData])

  const updateRequestBody = useCallback(
    (selectedIds: string[] | number[], bookSlotData = {}) => {
      let keyValue: null | number[] = null
      const currentQuestionAnswer =
        formState?.current.questions[formState?.current.questions.length - 1]
          .answer || ''

      /* user key will have multiple answers associated with it, so if the question is user, we need to append value to its array, hence storying its value in a variable */
      if (formQuestionsResponse.question === 'user') {
        keyValue =
          requestBody.current.selectedAnswers[formQuestionsResponse.question]
      }

      requestBody.current = {
        questionNumber: formQuestionsResponse?.questionNumber ?? 1,
        question: formQuestionsResponse?.question,
        answer: currentQuestionAnswer,
        selectedAnswers: {
          ...requestBody.current.selectedAnswers,
          [formQuestionsResponse?.question]: keyValue
            ? [...selectedIds, ...keyValue]
            : selectedIds,
        },
        formId: formId as number,
        source,
        formQuestions: formState?.current,
        rawDetails: requestBody.current?.rawDetails,
        ...(requestBody?.current?.bookSlotData && {
          bookSlotData: requestBody?.current?.bookSlotData,
        }),
        version,
        reportVersion,
        ...(formQuestionsResponse?.additionalInfo && {
          additionalInfo: formQuestionsResponse?.additionalInfo,
        }),
      }

      if (!isEmpty(bookSlotData)) {
        requestBody.current = {
          ...requestBody.current,
          bookSlotData: {
            ...bookSlotData,
            email,
            patientName: `${firstName} ${lastName}`,
            userId: id,
            phoneNumber,
            source: 'DA',
            mode: 'bw_call',
          },
        }
      }
    },
    [
      formQuestionsResponse,
      reportVersion,
      formId,
      source,
      email,
      firstName,
      lastName,
      id,
      phoneNumber,
      version,
    ]
  )

  const updateFormState = useCallback(
    (answer) => {
      const questionMessages = currentQuestion?.questionMessage?.messages
      let lastMessage = ''
      if (questionMessages) {
        lastMessage = questionMessages[questionMessages?.length - 1]
      }

      formState.current.questions = [
        ...formState.current.questions,
        {
          question: lastMessage || currentQuestion?.question,
          answer: answer.map((item: any) => item?.text || ''),
          questionKey: currentQuestion?.question,
        },
      ]
    },
    [currentQuestion]
  )

  const setAnswerToCurrentQuestion = useCallback(
    (answer: any) => {
      updateFormState(answer)

      setChatData((data: any) => {
        return data.map((instance: any, index: number) => {
          if (index === data.length - 1) {
            return {
              ...instance,
              answer,
            }
          }

          return instance
        })
      })
    },
    [updateFormState]
  )

  const resetPreviousAnswer = useCallback(() => {
    const keyToDelete = chatData[chatData.length - 2]?.question

    if (keyToDelete === 'user' && formQuestionsResponse.option !== 1) {
      requestBody.current.selectedAnswers[keyToDelete].shift()
    } else {
      delete requestBody.current.selectedAnswers[keyToDelete]
    }

    setFormQuestionsResponse(chatData[chatData.length - 2])
    formState.current.questions = formState.current.questions.slice(
      0,
      formState.current.questions.length - 1
    )

    setSelectedIds([])
    setChatData((prevData) => {
      const updatedData = prevData.map((item, index) => {
        if (index === prevData.length - 2) {
          delete item.answer
          return item
        }

        return item
      })

      return updatedData.filter((_, index) => index !== prevData.length - 1)
    })
  }, [chatData, formQuestionsResponse?.option])

  const resetFormState = useCallback(() => {
    setFormQuestionsResponse([])
    setChatData([])
    setFormId(null)
    setFormComplete(false)
    setSkipAnimationAndResetIndex(null)
    requestBody.current = {
      questionNumber: 0,
      selectedAnswers: {},
      source: source,
      rawDetails: rawDetails,
      version,
    }
  }, [rawDetails, source, version])

  const onOptionSelected = useCallback(
    (values) => {
      setSelectedIds(values)

      if (shouldHideNextButtonAndAutoSubmit) {
        setAnswerToCurrentQuestion(values)
      }
    },
    [setAnswerToCurrentQuestion, shouldHideNextButtonAndAutoSubmit]
  )

  /* Call to initiate the chat flow for any source */
  const getInitialFormQuestionData = useCallback(
    async (formId: number | undefined = undefined): Promise<void> => {
      resetFormState()

      try {
        setIsLoading(true)
        const data = await postResource<IGlobalResponse<any>>(
          API_END_POINTS.FORM_QUESTIONS(fetchQuestionsVersion),
          {
            ...requestBody.current,
            formId,
            reportVersion,
          }
        )

        if (data?.data?.data) {
          const apiData = data?.data?.data

          if (apiData?.skipAnimationAndResetIndex) {
            setSkipAnimationAndResetIndex(apiData?.skipAnimationAndResetIndex)
          }
          if (!isEmpty(apiData?.chatData) && Array.isArray(apiData?.chatData)) {
            setChatData([...apiData.chatData, {...apiData}])
          } else {
            setChatData([{...apiData}])
          }
          setFormQuestionsResponse(apiData)
          setFormId(apiData.finalFormId)
          setFormComplete(apiData?.submitForm)

          if (apiData?.selectedAnswers) {
            requestBody.current = {
              ...requestBody.current,
              selectedAnswers: apiData?.selectedAnswers,
            }
          }
        }
      } catch (err) {
        //do nothing
      } finally {
        setIsLoading(false)
      }
    },
    [fetchQuestionsVersion, reportVersion, resetFormState]
  )

  /* Call to continue the chat flow after initialization */
  const getFormQuestionData = useCallback(
    async ({signal}: {signal?: AbortSignal} = {}): Promise<void> => {
      try {
        setIsLoading(true)
        setFormComplete(false)

        const config: AxiosRequestConfig = {}
        if (signal) {
          config.signal = signal // Add signal only if it's defined
        }
        const data = await postResource<IGlobalResponse<any>>(
          API_END_POINTS.FORM_QUESTIONS(fetchQuestionsVersion),
          requestBody.current,
          config
        )

        if (data?.data?.data) {
          const apiData = data?.data?.data

          if (apiData?.resetForm) {
            resetFormState()
          }

          setFormId(apiData.finalFormId)
          setFormQuestionsResponse(apiData)
          setFormComplete(apiData?.submitForm)
          setChatData((prevData) => [...prevData, {...apiData}])

          if (apiData?.selectedAnswers) {
            requestBody.current = {
              ...requestBody.current,
              selectedAnswers: {
                ...requestBody.current.selectedAnswers,
                ...apiData.selectedAnswers,
              },
            }
          }
        }
      } catch (err) {
        //do nothing
      } finally {
        setIsLoading(false)
      }
    },
    [fetchQuestionsVersion, resetFormState]
  )

  /* Get last form Id of user if any */
  const getLastFormIdData = useCallback(async (): Promise<
    {formId: number; completed: boolean} | null | undefined
  > => {
    try {
      const data = await fetchResource<IGlobalResponse<any>>(
        API_END_POINTS.FETCH_LAST_FORM_ID,
        {
          params: {
            source,
            consultRedirect,
          },
        }
      )

      if (!isEmpty(data)) {
        analytics.push({lastFormIdData: data?.data})
        return {formId: data?.data?.formId, completed: data?.data?.completed}
      }
    } catch (err) {}
    return null
  }, [source, consultRedirect])

  /*Use this function if you want to check if a user has a formId associated with a form that is not completed, so that formId can be used to continue the form from where the user last left */
  const getInitialFormQuestionDataWithExistingFormId = useCallback(async () => {
    try {
      const data = await getLastFormIdData()

      if (data?.completed) {
        setFormComplete(true)
        return
      }

      getInitialFormQuestionData(data?.formId)
    } catch (err) {}
  }, [getInitialFormQuestionData, getLastFormIdData])

  /* To claim a form against a userId */
  const claimForm = useCallback(async () => {
    try {
      setIsLoading(true)
      await postResource(API_END_POINTS.CLAIM_FORM, {formId, type: source})
    } catch (err) {
      //do nothing
    } finally {
      setIsLoading(false)
    }
  }, [formId, source])

  /* To be called every time user selected answers a question and presses the next button */
  const onNextPressed = useCallback(
    async (params?: {bookSlotData?: IBookSlotData}) => {
      const {bookSlotData = {}} = params || {}
      if (!shouldHideNextButtonAndAutoSubmit) {
        setAnswerToCurrentQuestion(selectedIds)
      }

      updateRequestBody(
        selectedIds.map((item) => item?.option),
        bookSlotData
      )
      const data = {
        question: formQuestionsResponse?.question,
        answer: selectedIds.map((item) => item?.text).join(','),
      }
      analytics.trigger(EVENT_MAP.FORM_QUESTION_ANSWERED, data)
      setSelectedIds([])

      await getFormQuestionData()
    },
    [
      shouldHideNextButtonAndAutoSubmit,
      updateRequestBody,
      selectedIds,
      formQuestionsResponse?.question,
      getFormQuestionData,
      setAnswerToCurrentQuestion,
    ]
  )

  const setInitialChatData = useCallback((data) => {
    setChatData(data)
  }, [])

  /* Update ref when raw details available */
  useEffect(() => {
    requestBody.current = {...requestBody.current, rawDetails}
  }, [rawDetails])

  /* automatically submit when user press an option for a question that has type radio */
  useEffect(() => {
    if (selectedIds.length > 0 && shouldHideNextButtonAndAutoSubmit) {
      onNextPressed()
    }
  }, [onNextPressed, selectedIds.length, shouldHideNextButtonAndAutoSubmit])

  return [
    {
      chatData,
      isLoading,
      formComplete,
      shouldHideNextButtonAndAutoSubmit,
      currentQuestion,
      isCurrentQuestionAnswered,
      formQuestionsResponse,
      formId,
      selectedAnswers: requestBody.current.selectedAnswers,
      isNextButtonDisabled,
      selectedIds,
      previousQuestion,
      skipAnimationAndResetIndex,
    },
    {
      getInitialFormQuestionData,
      getFormQuestionData,
      updateRequestBody,
      setAnswerToCurrentQuestion,
      resetPreviousAnswer,
      resetFormState,
      claimForm,
      getInitialFormQuestionDataWithExistingFormId,
      getLastFormIdData,
      onOptionSelected,
      onNextPressed,
      setInitialChatData,
    },
  ]
}

export {useFormQuestions}
