import styled from 'styled-components'

export const StyledSupportTicketListingItem = styled.div<{
  isOngoing: boolean
  isBenched: boolean
}>`
  background-color: var(--dls-white-color);
  border: var(--dls-size-2) solid var(--dls-white-color);
  border-radius: var(--dls-size-16);
  overflow: hidden;

  .header {
    background: ${(props) =>
      props.isOngoing
        ? 'var(--dls-success-color-100)'
        : props.isBenched
        ? 'var(--dls-warning-color-100)'
        : 'var(--dls-backgound-color-100)'};
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: var(--dls-size-8);
    gap: var(--dls-size-2);
  }

  .createdOnWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--dls-size-4);
    width: 50%;
    flex-wrap: wrap;
  }

  .statusWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    gap: var(--dls-size-4);
  }

  .statusDot {
    min-width: var(--dls-size-8);
    min-height: var(--dls-size-8);
    border-radius: 50%;
    border: var(--dls-size-1) solid var(--dls-white-color);
    background: ${(props) =>
      props.isOngoing
        ? 'var(--dls-success-color-500)'
        : props.isBenched
        ? 'var(--dls-warning-color-500)'
        : 'var(--dls-secondary-text-colors)'};
  }

  .statusText {
    color: ${(props) =>
      props.isOngoing
        ? 'var(--dls-success-color-500)'
        : props.isBenched
        ? 'var(--dls-warning-color-500)'
        : 'var(--dls-secondary-text-colors)'};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--dls-size-16);
    gap: var(--dls-size-8);
  }

  .chatIcon {
    width: 24px;
    height: 24px;
  }

  .chatTitle {
    text-align: left;
    flex: 1;
  }

  .rightArrow {
    transform: rotate(90deg);
  }

  .ratingWrapper {
    margin: var(--dls-size-16);
    margin-top: 0;
    padding: var(--dls-size-12);
    border-radius: var(--dls-size-8);
    background: var(--dls-backgound-color-100);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ratingTitle {
      color: var(--dls-secondary-text-colors);
    }
  }
`
