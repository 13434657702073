const WISHLINK_ID = process.env.NEXT_PUBLIC_WISHLINK_ID
const WISHLINK_BASE_URL = 'https://api.wishlink.com/api/brandUserLanding'

const buildURL = (baseUrl: string, params: Record<string, any>) => {
  // Create URL object from the base URL
  const url = new URL(baseUrl)

  // Loop through params and only add non-empty values
  Object.entries(params).forEach(([key, value]) => {
    // Add parameter only if it's not null, undefined, or empty string
    if (value !== null && value !== undefined && value !== '') {
      url.searchParams.set(key, value)
    }
  })

  return url.toString()
}

export const reportWishlinkPixel = async (params: Record<string, any>) => {
  const paramsToBeSent = {
    platform: WISHLINK_ID,
    utm_source: params.utmSource ?? '',
    utm_medium: params.utmMedium ?? '',
    utm_campaign: params.utmCampaign ?? '',
    atgSessionId: params.atgSessionId ?? '',
    ...(params.fbclid && {fbclid: params.fbclid}),
    ...(params.gclid && {gclid: params.gclid}),
  }
  const finalURL = buildURL(WISHLINK_BASE_URL, paramsToBeSent)

  const img = document.createElement('img')
  img.src = finalURL
  img.id = 'wishlink-attribution'
  img.alt = 'wishlink-attribution'
  img.style.display = 'none'
  document.body.appendChild(img)
}
