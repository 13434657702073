import styled from 'styled-components'

export const RLProductSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: var(--dls-size-80);

  .bottom-cta {
    margin: var(--dls-size-16) auto;
  }
`

export const RLProductSliderWrapper = styled.div`
  > * {
    flex: 0 0 auto;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
