import React, {useCallback, useEffect, useRef} from 'react'
import {
  GenericModalCloseButton,
  GenericModalContent,
  GenericModalBackDrop,
} from './GenericModal.styles'
import {CloseIcon, useToggleBodyScroll} from '@web-components'
import {ModalProps} from './GenericModal.interface'

const GenericModal = (props: ModalProps) => {
  const {
    onClose,
    additionalContentClass = '',
    additionalModalClass = '',
    hideCloseButton = false,
    children,
    showModal,
  } = props

  const modalRef = useRef<HTMLDivElement>(null)

  const {disableBodyScroll, enableBodyScroll} = useToggleBodyScroll()

  const handleClick = useCallback(
    (clickEvent: React.MouseEvent<HTMLDivElement>) => {
      if (clickEvent.target === modalRef.current) {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    if (showModal) {
      disableBodyScroll()
    } else {
      enableBodyScroll()
    }
  }, [showModal, disableBodyScroll, enableBodyScroll])

  useEffect(() => {
    return () => {
      window.document.body.style.overflow = 'auto'
    }
  }, [])

  if (!showModal) return null

  return (
    <GenericModalBackDrop
      ref={modalRef}
      onClick={handleClick}
      className={additionalModalClass}
    >
      <GenericModalContent
        className={additionalContentClass}
        data-identifier="modal-content"
      >
        {!hideCloseButton && (
          <GenericModalCloseButton
            onClick={onClose}
            className="modal-close-button"
          >
            <CloseIcon
              fill="var(--dls-white-color)"
              stroke="var(--dls-black-color)"
            />
          </GenericModalCloseButton>
        )}
        {children}
      </GenericModalContent>
    </GenericModalBackDrop>
  )
}

export default GenericModal
