import styled from 'styled-components'

export const StyledSupportTicketsListing = styled.div`
  .ticketSeperator {
    height: var(--dls-size-16);
  }
  .footerCta {
    background-color: var(--dls-white-color);
    padding: var(--dls-size-8);
    margin-top: var(--dls-size-16);
    border-radius: var(--dls-size-8);
    text-align: center;
  }

  .footerCtaText {
    color: var(--dls-brand-text-colors);
  }
`
